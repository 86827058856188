import React, { useEffect, useState } from "react"
import "./serviceCard.css"
import Footer from "../footer/Footer";
import { Link, useNavigate} from "react-router-dom";
import axios from "axios";
import { error } from "jquery";
import { useSelector, useDispatch } from "react-redux";
import {getSellerIdFromBuyService} from "./../../Redux/actions/GetSellerID_UserInterfaceActionCreator";
const rating5 = "../../images/1star.png" 
const illustration2 = "../../images/AR.png";
const illustration5 = "../../images/profilepic.png";
const time = "../../images/time.png";

function ServiceLanding() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    const { serviceUrl, serviceId, obj } = useSelector(state => state.navigation_service_data)
    const [data, setData] = useState([]);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    useEffect(() => {
        const baseUrl = process.env.REACT_APP_BASE_URL_DEV;
        const fetchData = async (obj, _id, object) => {
            try {
                const response = await axios.get(`${baseUrl}/api/v1/services/${obj}/${_id}`);
                setData([response.data.data[object]]);
                console.log([response.data.data[object]])
            } catch (error) {
                // console.log("error", error);
            }
        };

        fetchData(serviceUrl, obj, serviceId);
    }, [serviceId])

    const ViewSellerProfile = async (e, id) => {
        e.preventDefault();
        if (id) {
            navigate(`/counselor-profile`, dispatch(getSellerIdFromBuyService(id)));
        }
        else {
            alert('No profile found');
            // console.log(error);
        }

    }
    return (

        <div>
            { data !== undefined &&  data.length !==0?
                data.map((item) => {
                    return (
                        <div key={item._id} className="service-landing">
                            <hr className="card-landing-line"></hr>
                            <div class="pagecontainer">
                                <h1 className="service-l-head">Title of Service</h1>

                                <div style={{
                                    marginTop: "40px"
                                }}>
                                    <div className="service-l-flex">
                                        <div className="service-profile-detail">
                                            <div className="service-profile-pic" style={{
                                                display: "flex"
                                            }}>       <div style={{
                                                flexBasis: "40%"
                                            }}>
                                                    <img src={illustration5} width="80%" alt="loading..."></img>
                                                </div>

                                                <div className="profile-flex-change">
                                                    <h5 className="landing-name">{item.title_Service !== undefined ? item.title_Service : '' }</h5>
                                                    <span className="cardlanding-rating">Ratings<img className="landing-pic" alt="loading..." style={{
                                                        marginLeft: "10px",
                                                    }} width="4%" src={rating5}></img><img className="landing-pic" width="4%" src={rating5} alt="loading..."></img><img className="landing-pic" width="4%" src={rating5} alt="loading..."></img><img className="landing-pic" width="4%" src={rating5} alt="loading..."></img><img className="landing-pic" width="4%" src={rating5} alt="loading..."></img></span><br></br>
                                                    <button className=" button-view" onClick={(e) => ViewSellerProfile(e, item.seller[0]._id)}>View profile </button><button className="font3">Chat</button>

                                                </div>


                                            </div >
                                            <div style={{
                                                marginTop: "80px"
                                            }} >
                                                <div className="cardlanding-content"  >
                                                    <div>
                                                        <div className="font margin">For degree</div>
                                                        <div className="font2">{item.forDegrees + '|'}</div>
                                                        <div className="font margin"> Disiplinies</div>
                                                        <div className="font2">{item.counselorDisciplineSpecialization}</div>
                                                        <div>
                                                            <div className="font margin"> Additional requirement</div>
                                                            <div className="font2" >{item.additionalRequirement}</div>
                                                        </div>

                                                    </div>

                                                    <div>

                                                        <div className="font margin">Duration</div><div className="font2" >{item.productDelivery}</div>
                                                        <div className="font margin">Sub-disiplinies</div><div className="font2" >{item.counselorsubDisciplineSpecialization}</div>
                                                        <div className="font margin">Number of session</div><div className="font2" >{item.numberOfSession}</div>
                                                    </div>

                                                </div>
                                                <div>

                                                </div>
                                            </div>


                                        </div>
                                        <ul className="includes-mobile includes-head" >
                                            Includes
                                            <ul>
                                                <li className="includes-content">
                                                    word count
                                                </li>
                                                <li className="includes-content">
                                                    word count
                                                </li>  <li className="includes-content">
                                                    word count
                                                </li>  <li className="includes-content">
                                                    word count
                                                </li>
                                            </ul>


                                            <div className="seller-payment-sec2">
                                    <div className="seller-payment-sec-content">
                                        <span className="font-pay-head">{item.title_Service}</span> <span className="font-pay-head" style={
                                            {
                                                float: "right"
                                            }
                                        }>{item.price}/-</span>
                                        <hr></hr>
                                        <h5 className="time2" style={{

                                        }}><img src={time} width="7%" alt="loading..."></img> <span style={{
                                            marginLeft: "6%"
                                        }}>{item.productDelivery} days ago</span></h5>
                                        <h6 className="card-flag" style={{

                                        }}><img src={illustration2} width="10%" alt="loading..."></img><span style={{
                                            position: "relative",
                                            left: "2%"
                                        }}>  {item.country}</span> </h6>
                                        <Link to="/PayNow"> <button className="landing-btn1">pay now</button></Link> <button className="landing-btn2">Contact Counselor</button>

                                    </div>
                                    <div className="includes">

                                    </div>
                                </div>
                                        </ul>
                                        <hr className="hr-mobile"></hr>
                                        <div className="seller-payment-sec">
                                            <div className="seller-payment-sec-content">
                                                <span className="font-pay-head">{item.title_Service}</span><span className="font-pay-head" style={
                                                    {
                                                        float: "right"
                                                    }
                                                }>{item.price}/-</span>
                                                <hr></hr>
                                                <h5 className="time2"><img src={time} width="7%" alt="loading..."> </img><span style={{
                                                    marginLeft: "6%"
                                                }}>{item.productDelivery} days delivery</span></h5>
                                                <h6 className="card-flag" style={{

                                                }}><img src={illustration2} width="10%" alt="loading..."></img><span style={{
                                                    position: "relative",
                                                    left: "2%"
                                                }}> {item.country}</span> </h6>
                                                <Link to="/PayNow">  <button className="landing-btn1">pay now</button></Link> <button className="landing-btn2">Contact Counselor</button>

                                            </div>
                                            <div className="includes">
                                                <ul className="includes-head">
                                                    Includes
                                                    <ul>
                                                        <li className="includes-content">
                                                            word count
                                                        </li>
                                                        <li className="includes-content">
                                                            word count
                                                        </li>  <li className="includes-content">
                                                            word count
                                                        </li>  <li className="includes-content">
                                                            word count
                                                        </li>
                                                    </ul>



                                                </ul>
                                            </div>
                                        </div>

                                    </div>

                                </div>
                                <div style={{

                                }}><h1 className=" service-about-head" style={{


                                }}>About the services</h1>
                                    <p className="font-des-content service-about">{item.description}
                                    </p>
                                </div>
                                <div className="service-coun-dis">
                                    <div className="font-des" style={{
                                        marginLeft: "0px",

                                    }}>
                                        Counselor Description
                                    </div><br></br>
                                    <span className="font-des-content">{item.seller[0].description}</span>

                                </div>
                             

                            </div>

                        </div>

                    )
                }) : null
            }
            <Footer />
        </div>
    )
}
export default ServiceLanding