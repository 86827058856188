import React, { useState } from "react";
import axios from "axios";
import './signup.css';
import { useNavigate, Link} from "react-router-dom";
import { useDispatch } from "react-redux";
import { getSellerIdFromAuth } from "../../Redux/actions/GetSellerIdFromAuthActionCreators";
import toast, {Toaster} from 'react-hot-toast';
const illus2 = new URL("../../images/image 10.webp", import.meta.url);

function Signup2() {
  const [show, setShow] = useState("case1")
  const [show3, setShow3] = useState(true)
  const [name, setfirstName] = useState("");

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirm_password, setCPassword] = useState("");
  const [isEmailVerified, setIsEmailVerified] = useState(false)
  const [token, setToken] = useState("")
  const [cookie, setCookie] = useState("")
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handlechange = (caseName) => {
    setShow(caseName)
  }
  const baseUrl = process.env.REACT_APP_BASE_URL_DEV;
  const handleSignUp = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`${baseUrl}/api/v1/sellers/signup`, {
        name: name,
        email: email,
        password: password,
        passwordConfirm: confirm_password,
        role : 'Seller',
        // isEmailVerified: isEmailVerified
      });
      if (response.data.status === "success") {
         dispatch(getSellerIdFromAuth(response.data.data.user._id, response.data.data.user.role, response.data.data.user.name, response.data.data.user.email, response.data.data.user.isEmailVerfied));
         setToken(response.data.token);
         toast.success("Congratulations! You have signedup successfully");
         navigate('/create-counselor-profile');
      }
    } catch (error) {
      // console.log(error)
      toast.error("Email already link to another account or something went wrong")
    }
  }
return (
    <div className="">
        <div className="">

          <div style={{
            display: "flex"
          }}>
            <div className="signup-pic">
              <button class="wrong" onClick={() => setShow3(false)} >X</button>
              <h6 className="sinup-pic-content" style={{
                color: "white",
                position: "relative",
                marginTop: "130px"

              }}>How good of you to be<br></br>
                at the right place at the right time!</h6>
              <img src={illus2} class="res-img" width="50%" alt = "loading..." ></img>

            </div>

            <div className="signup-forms">
            <button class="wrong2" onClick={() => setShow3(false)} >X</button>
              <div className="auth-div">
                <span className={`signup-btn2 ${show === "case1" ? "active" : ""}`} onClick={() => handlechange("case1")} >Sign up</span>



                <div class="Signup-heads"
                style={{
                  position:"relative",
                  top:"10px"
                }}>Welcome</div>

                <div>
                  <form class="row g-2 form-width" onSubmit={handleSignUp}>

                    <label for="exampleFormControlInput1" class="form-label">Name</label>
                    <input required  type="text" class="signup-info" onChange={(e) => {
                      setfirstName(e.target.value);
                    }} placeholder="Name" aria-label="First name" />
                    <label for="exampleFormControlInput1" class="form-label">Email</label>
                    <input required  type="email" class=" signup-info" onChange={(e) => {
                      setEmail(e.target.value);
                    }} placeholder="example@gmail.com" aria-label="First name" />

                    <label for="exampleFormControlInput1" class="form-label">Password</label>
                    <input required pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}" title="Must contain at least one number and one uppercase and lowercase letter, and at least 8 or more characters"  type="password" class=" signup-info" onChange={(e) => {
                      setPassword(e.target.value);
                    }} placeholder="*****" aria-label="First name" />
                    <label for="exampleFormControlInput1" class="form-label" >Confirm password</label>
                    <input required pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}" title="Must contain at least one number and one uppercase and lowercase letter, and at least 8 or more characters" type="password" class=" signup-info" placeholder="*****" aria-label="First name" onChange={(e) => {
                      setCPassword(e.target.value);
                    }} />
                    <div style={{
                      display: "flex"
                    }}>
                      <div>
                      
                      </div>
                      <div>
                         
                      </div>

                    </div>
                    <button className="sign-button">Sign up</button>
                    {/* <h6 style={{
                      margin:"auto",
                      width:"30px"
                    }}> or</h6> */}


                    {/* <button className="sign-goggle">Sign up with goole</button> */}
                    <h5 className="need" style={{
                      marginTop: "10px"
                    }}>
                      <Link to="/contact-us">Need help?</Link>

                    </h5>
                  </form>




                </div>
              </div>
            </div>
          </div>
        </div>
    <Toaster/>
    </div>

  )
}
export default Signup2