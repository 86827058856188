
import React, { useState } from "react";
import "../../styles/userLanding.css"
const illustration5 = new URL("../../images/image 20.webp", import.meta.url)
const rating5 = new URL("../../images/1star.webp", import.meta.url)
const edit = new URL("../../images/dash-edit.webp", import.meta.url)
const empty = new URL("../../images/empty.webp", import.meta.url)
const editDeleteIllus = new URL("../../images/deleteButton.webp", import.meta.url)
const noprofile = new URL("../../images/noProfilepic.webp", import.meta.url)
function DashCards({data}) {
  const [showdeletOption, setDelete] = useState(false);
  return (
    <>
      {(data !== undefined && data.length !== 0 )?
        data.map((item) => {
          return (
          
            <div key = {item._id} class="carousel-cell15" >

              <img src={edit} className="dash-edit" width="10px" alt="loading..."></img><img onClick={() => setDelete(!showdeletOption)} className="dash-edit2" src={empty} alt="loading..."></img>

              {showdeletOption &&
                <div onMouseLeave={() => setDelete(false)} className="deleteOptionMain" >
                  <div className="deleteOption">

                    <img width="20px" className="dash-delete-illus" src={editDeleteIllus} alt = "loading..."></img>
                    <div className="deleteOption-head">
                      You are about to delete your account!
                    </div>
                    <div className="deleteOption-head2">
                      You won’t be able to retrieve it later.
                    </div>
                    <div className="deleteCancel">
                      <button className="button-dash-delete">Delete</button> <button className="button-dash-Cancel">Cancel</button>
                    </div>
                  </div>

                </div>
              }
              <div className="service-card2">
                <img width="35%" src={illustration5} alt="loading..."></img>
                <hr style={{
                  position: "relative",
                  width: "90%",
                  top: "-10px",
                  left: "5%"


                }}></hr>
              </div>
              <span style={{
                    width: "15%",
                    borderRadius: "50%",
                    
                    padding: "10px 20px 10px 20px",
                   
                    position: "absolute",
                    left: "5%",
                    top: "108px",
                  }}> <img width="40px" src={noprofile}></img> </span>   <span className="cardName3">{(item.seller !== undefined ) ? item.seller[0].name : ''}</span><span className="card-country2" style={{
                background: "",
                padding: "2px",
                position: "relative",
                left: "30%",
                top: "-20px",

              }}>
                                <span style={{position:"relative",left:"20px"}}>Country</span><br></br>
                <img src={`${process.env.PUBLIC_URL}/countryFlags/${item.country}.webp`} class="seller-card-flag" alt="loading..." width="10%"></img>
              </span>
              <br></br>
              <div className="rating-digit2">
                4.3<img style={{
                  position: "relative",
                  top: "-1px",
                  left: "2px"
                }} width="10px" src={rating5} alt="loading..."></img>
              </div>

              <div className="service-head4">
              {(item.title_Service.length > 30) ? item.title_Service.slice(0, 30) : item.title_Service }
              </div>
              <div className="service-content4">
              {(item.description.length > 125) ? item.description.slice(0, 125)  + '...': item.description }
              </div>
              <div>

                <br></br>
                <span className="INR2 INR5">PRICE:</span>
                <span className="INR2 INR12" style={{

                }}>INR</span><span className="INR2 INR11" style={{

                }}>{item.pricing}/-</span>
              </div>

            </div>
            
          )
        }) : null}



    </>
  )

}
export default DashCards


