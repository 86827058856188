import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import ReactGA from "react-ga4"
import Footer from "../footer/Footer";

import UserLandingCards from "../user/userLandingCards";

import "../../styles/userLanding.css"

import ServiceComponent2 from "./service-component2";
import ServiceComponent from "./servicesComponent";

const no_profile = new URL("./images/21SDD 1.webp", import.meta.url)
const illus = new URL("./images/Ellipse 15.webp", import.meta.url)


function CounsellerLanding() {
  
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const location = useLocation();
  useEffect(() => {
    ReactGA.send(window.location.pathname + window.location.search);
  }, []);
  return (
    <div className="">
     

      <div className="user-banner">

        <div className="banner1">
          <span className="banner-heading" style={{
          }}>Looking for an
          </span><br></br><span className="abroad" style={{

          }}> Exceptional Counselor for yourself?</span><br></br>


          <span className="banner-get" style={{
            position: "relative",
            top: "10px"

          }}> We bring you multiple options to choose from. Start Now!</span> <br></br>

        </div>
        <div className="banner2">
          <img src={no_profile} className="user-illus" width="40%" alt="loading..."></img>

        </div>
      </div>
      <img src={illus} width="550px" className="teda-meda" alt="loading..."></img>
      <h5 style={{

        marginBottom: "20px",
        fontFamily: "'Gotham Book', sans-serif",
        fontStyle: "normal",
        fontWeight: "400",
        fontSize: "48px",
        position: "relative",
        marginTop:"200px",
        lineHeight: "46px",
        /* identical to box height */


        color: "#7D7EC7"
      }}>Explore  Counselors</h5>
      <hr style={{
        width: "50%",
        
        margin: "auto",

        background: "#7D7EC7",
        height: "5px",
        borderRadius: "5px"
      }}></hr>
      <div className="fourrow" >

        <UserLandingCards />
      
        

      </div>
      <Footer />
    </div>
  )
}
export default CounsellerLanding
