import React, { useEffect } from "react";
import Footer from "../footer/Footer";
import ServiceComponent from "../individualservice/servicesComponent";
import "../../styles/userLanding.css"
import SellerServiceCards from "../individualservice/seller-service-card";
import ServiceComponent2 from "../individualservice/service-component2";
const no_profile = new URL("../../images/21SDD 1.webp", import.meta.url)
const illus = new URL("../../images/Ellipse 15.webp", import.meta.url)

function ServiceAllService() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>

      <ServiceComponent />
      <ServiceComponent2 />
      <img src={illus} width="550px" className="teda-meda" alt="loading..."></img>
      <h5 className="service-pages-head">Counselor All Services</h5>
      <hr className="service-line"></hr>
      <img src={illus} width="550px" className="teda-meda" alt="loading..."></img>
      <div className="row row2">
        <SellerServiceCards />
      </div>
      <Footer />
    </div>
  )
}
export default ServiceAllService
