import React, { useEffect } from "react";
import Footer from "../footer/Footer";
import "../../styles/cookiePolicy.css"
import { Link } from "react-router-dom";

function CookiePolicy() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="privacy-head">
      <p class="cookie ">COOKIE POLICY
        <hr style={{
          margin:"auto"
        }}></hr>
      </p>
      <div class="outline">
        <h3 class="heads">Introduction</h3>
        <p class="innertext">Our marketplace, located at <Link to ="/" ><span>https://kounselo.com</span></Link> uses cookies and similar technologies to
          provide you with
          an
          optimal user experience and to personalize the content and advertisements we show you on our platform. In
          this
          policy, we describe what cookies are, what they are used for, and how you can control their use.</p>
        <h3 class="heads">What are Cookies?</h3>
        <p class="innertext">Cookies are small text files that are stored on your device when you visit a website. They
          are used to store
          information about your visit, such as your preferences and settings, to make your next visit easier and the
          website more useful to you.</p>
        <h3 class="heads">What Cookies do we use?</h3>
        <p class="innertext">We use several different types of cookies on our platform, including:</p>
        <p class="innertext"> <span class="chng">Essential Cookies: </span>These are necessary for the website to
          function properly and
          cannot
          be disabled. They allow you to navigate the platform and use its features, such as accessing secure areas of
          the
          website.</p>
        <p class="innertext"><span class="chng">Performance Cookies: </span>These cookies collect information about how
          you use our
          platform,
          such as which pages you visit and any error messages you receive. This information helps us to improve the
          performance of our platform and make it more user-friendly.</p>
        <p class="innertext"><span class="chng">Functionality Cookies: </span>These cookies remember your preferences
          and settings, such
          as
          your language preference or the size of text on the platform. This information is used to provide you with a
          more personalized experience.</p>
        <p class="innertext"><span class="chng">Advertising Cookies: </span>These cookies are used to display
          advertisements that are
          relevant
          to your interests and to track the effectiveness of our advertising campaigns.</p>
        <h3 class="heads">How to Control Cookies</h3>
        <p class="innertext">Most web browsers automatically accept cookies, but you can usually modify your browser
          setting to decline
          cookies if you prefer. This may prevent you from taking full advantage of the platform and its features.</p>
        <p class="innertext">If you want to delete or block cookies, refer to your browser's help section or visit
          www.aboutcookies.org
          for
          more information.</p>
        <h3 class="heads">Changes to this Policy</h3>
        <p class="innertext">We may update this policy from time to time to reflect changes to our platform and the way
          we use cookies.
          When
          we make changes, we will update the “Last Updated” date at the top of this page.</p>
        <h3 class="heads">Contact Information</h3>
        <p class="innertext">If you have any questions or concerns about our use of cookies, please contact us at
          info@kounselo.com</p>
        <h3 class="heads">Conclusion</h3>
        <p class="innertext">By using Kounselo, you consent to our use of cookies in accordance with this policy.</p>
      </div>
      <Footer />
    </div>
  )

}
export default CookiePolicy