import { useEffect, useState } from "react";
import ReactMarkdown from 'react-markdown'
import React from "react";

import ReactGA from "react-ga4"
import "../../styles/blog.css"
import { Link, useLocation } from "react-router-dom";

import { useNavigate } from "react-router-dom";
import axios from "axios"

import Footer from "../footer/Footer";
import '../../styles/blog.css'
import { useDispatch, useSelector } from "react-redux";
import { BlogCardsAPICall } from "../../Redux/actions/blogId";
import Blog from "./blog";

const no_profile = new URL("../../images/noProfilepic.webp", import.meta.url)
const baseUrl = process.env.REACT_APP_BASE_URL_DEV;


function BlogInsideView(props) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  


  const location = useLocation()

  
  const id = location.state
 


  const navigate = useNavigate();
  const dispatch = useDispatch();

  const baseUrl = process.env.REACT_APP_BASE_URL_DEV;
  // const [data, setData] = useState([])

  const data2 = useSelector(state => state.get_blog_id)
  console.log(data2)
  useEffect(() => {
    BlogCardsAPICall('Getblogs', dispatch);


  }, []);




  const [data, setData] = useState([])
  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {

    try {

      const response = await axios.get(`${baseUrl}/api/v1/blogs/getOneblog/${id}`);

      setData([response.data.data.blog]);

      console.log(response.data.data.blog)
    } catch (error) {
    }
  };

  const handleChange = (e) => {
    e.preventDefault();
    setData(data2)
  };

  return (
    <div>
    
      {data.length !== 0 ?

        data.map((item) => {
          console.log(item, "item")
          return (
            <div>
                <div key={item._id} className="blog-banner">
                <img src={item.photo !== undefined ? `https://kounselodata.s3.ap-south-1.amazonaws.com/${item.photo}` : no_profile}   width="100%" height="500px" alt="loading..."></img>

                </div>
            <div  className="blog-flex">
                 
              <div className="blog-inside-view">
              <h5>{item.heading}</h5>
              <h5 style={{
                  color: "#9C74F1"
                }}>{item.date}</h5>
                
          
                <p  style={{
                  color: "#727272",
                  width: "100%"
                }}>
                <ReactMarkdown>{item.description}</ReactMarkdown>
                </p>
              </div>



            </div>
            </div>
          )
        }) : <div></div>


      }


      <div>
        {/* <div className="blog-content">
          {
            data2.map((item) => {
              return (

                <div class="card col-3">
                  <img src={item.photo !== undefined ? `https://kounselodata.s3.ap-south-1.amazonaws.com/${item.photo}` : no_profile} width="90%" alt="loading..."></img>
                  <div class="card-body" style={{
                    textAlign: "left"
                  }}>
                    <h5 style={{
                      color: "#9C74F1"
                    }}>{item.date}</h5>
                    <h5 onClick={handleChange}>{item.heading}</h5>
                    <p>Related:</p>
                    <p style={{ color: "#727272" }}>  {(item.description.length > 125) ? item.description.slice(0, 125) + '...' : item.description}</p>
                  </div>

                </div>


              )
            })
          }
        </div> */}
        <div ><Link style={{
          textDecoration: "none",
       
        }} to="/all-blogs"> View All Blogs</Link></div>



      </div>
      <div style={{marginTop:"50px"}}>
      <Footer/>
      </div>
    </div>
  )
}
export default BlogInsideView