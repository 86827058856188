import React from "react";
import "../styles/order.css"
function ForgotPassword() {
    return (
        <>

        
            <div className="outline">
                <div id="upper">
                    Forgot Password?
                </div>
                <div id="lower">
                    <div id="hello"> Hello,</div>
                    <div id="text"> Please reset your password

                        <form action="unknown" class="form28">
                            <label for="new" class="np"> New Password </label><br></br>
                            <input type="password" class="pass" required  ></input>
                            <br></br>
                            <label for="confirm" class="cp"> Confirm Password </label><br></br>
                            <input type="password" class="pass" required></input>
                            <div class="box">

                            </div>
                            <div class="imp">
                                Your password should:
                                <ol id="items">
                                    <li>Have atleast 6 characters.</li>
                                    <li>Include atleast 1 upper case character.</li>
                                    <li>Include atleast 2 lower case characters.</li>
                                    <li>Include a number.</li>
                                </ol>
                            </div>
                            <div class="btn-container">
                                <button type="submit" class="btn">Save Changes</button>
                            </div>
                        </form>
                    </div>

                </div>


                {/*  */}
            </div>

        </>
    )
}
export default ForgotPassword