import React, { useState } from "react";
import axios from "axios";
import { Link, useLocation, useNavigate } from "react-router-dom";
import './signup.css'
import { useDispatch } from "react-redux";
import { getUserIdFromAuth } from "../../Redux/actions/GetUserIdActionCreator";
import toast, {Toaster} from "react-hot-toast";
const illus2 = new URL("../../images/image 10.webp", import.meta.url);

function Signup() {
  const [show, setShow] = useState("case1")
  const [show2, setShow2] = useState("user")
  const [show3, setShow3] = useState(true)

  const [name, setfirstName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");

  const [token, setToken] = useState("")
  const [cookie, setCookie] = useState("")
  const [isSuccess, setIsSuccess] = useState(false);
  const [isFailed, setIsFailed] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const baseUrl = process.env.REACT_APP_BASE_URL_DEV;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  

  //   if (responseStatus > 200 && responseStatus < 299) {
  //     setIsSuccess(true);
  //     setTimeout(() => {
  //       setIsSuccess(false);
  //       navigate("/login");
  //     }, 2000);
  //   } else {
  //     setIsFailed(true);
  //     setIsLoading(false);
  //     setTimeout(() => {
  //       setIsFailed(false);
  //     }, 2000);
  //     alert("registration failed");
  //  }

  const handleSignUp = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`${baseUrl}/api/v1/users/signup`, {
        name: name,
        email: email,
        password: password,
        passwordConfirm: passwordConfirm,
        role: 'User',
        headers: {
          Authorization: `Bearer ${token}`
        }

      });
      
      if(response.data.status === "success") {
        window.setTimeout(() => {
          navigate('/userLanding')
        }, 1500);
        setToken(response.data.token);
        dispatch(getUserIdFromAuth(response.data.data.user._id, response.data.data.user.role));
        toast.success("You signed up succesfully!");
        setShow3(false);
        navigate('/userLanding');
      
      }
      
    } catch (error) {
      // console.log(error)
      toast.error("Email already link to another account or something went wrong")
    }
  }

  return (
    <div className="signLogin">
        {show3 &&
        <div className="signup-flexs">

          <div style={{
            display: "flex"
          }}>
            <div className="signup-pic">
              <button class="wrong" onClick={() => setShow3(false)} >X</button>
              <h6 className="sinup-pic-content" style={{
                color: "white",
                position: "relative",
                marginTop: "130px"

              }}>All journeys begin with a Sign up</h6>
              <img src={illus2} class="res-img" width="50%" alt="loading..."></img>
            </div>

            <div className="signup-forms">
            <button class="wrong2" onClick={() => setShow3(false)} >X</button>
              <div className="auth-div">
                <span className="signup-btn2 " >Sign up</span>
             
                <div style={{
                  position:"relative",
                  top:"10px"

                }} class="Signup-heads">Welcome</div>

                <div>
                      <form class="row form-width g-2" onSubmit={handleSignUp}>

                        <label for="exampleFormControlInput1" class="form-label">Name</label>
                        <input required  type="text" class="signup-info" onChange={(e) => {
                          setfirstName(e.target.value);
                        }} placeholder="Name" aria-label="First name" />
                        <label for="exampleFormControlInput1" class="form-label">Email</label>
                        <input required  type="email" class=" signup-info" onChange={(e) => {
                          setEmail(e.target.value);
                        }} placeholder="example@gmail.com" aria-label="First name" />

                        <label for="exampleFormControlInput1" class="form-label">Password</label>
                        <input required  pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}" title="Must contain at least one number and one uppercase and lowercase letter, and at least 8 or more characters" type="password" class=" signup-info" onChange={(e) => {
                          setPassword(e.target.value);
                        }} placeholder="*****" aria-label="First name" />
                        <label for="exampleFormControlInput1" class="form-label" >Confirm password</label>
                        <input required pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}" title="Must contain at least one number and one uppercase and lowercase letter, and at least 8 or more characters" type="password" class=" signup-info" placeholder="*****" aria-label="First name" onChange={(e) => {
                          setPasswordConfirm(e.target.value);
                        }} />

                        <button type='submit' className="sign-button">Sign up</button>
                        {/* <button className="sign-goggle">Sign up with google</button> */}
                        <h5 className="need" style={{
                          marginTop: "35px"
                        }}>Not a Student?
                 <Link style={{color:" #7478C5",textDecoration:"none"}} to="/become-a-seller"> Become a counselor</Link>

                        </h5>
                      </form>

                </div>
              </div>
            </div>
          </div>
        </div>
}   
    <Toaster/>
    </div>

  )
}
export default Signup