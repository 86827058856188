import React, { useState } from "react";
import { Link } from "react-router-dom";
import "../../styles/userLanding.css"
function ServiceComponent2(){
    const[show,setShow]=useState(false)
    return(
        <div className="service-comp-mobile">
        <button onClick={()=>{
            setShow(!show)
        }} className="viewAllService"> Browse Services</button>


        { show &&
          <div onMouseLeave={()=>{
            setShow(false)
          }} class="service-component2">
         
          <ul className="service-ul2" >
                <li className="Service-li">
                   <Link className="servcie-comp-link" to="/statement-of-purpose"> SOP</Link>
                </li>
                <li className="Service-li">
                   <Link className="servcie-comp-link" to="/university-shortlisting"> University Shortlisting</Link>
                </li>
                <li className="Service-li">
                    <Link className="servcie-comp-link" to="/visa-services">Visa Assistance</Link>
                </li>
                <li className="Service-li">
                  <Link className="servcie-comp-link" to="/resume">  Resume Building</Link>
                </li>
                <li className="Service-li">
                 <Link className="servcie-comp-link" to="/essays" > Essay  Editing</Link> 
                </li>
                <li className="Service-li">
                  <Link className="servcie-comp-link" to="/scholarship-application">  Scholarship Assistance</Link>
                </li>
                <li className="Service-li">
                 <Link className="servcie-comp-link" to="/profile-evaluation">  Profile Evaluation</Link> 
                </li>
                <li className="Service-li">
                 <Link  className="servcie-comp-link" to="/college-applications"> Complete Application Support</Link>  
                </li>
                
            </ul>
          
        </div>
}
        </div>

    )
}
export default ServiceComponent2