import React, { useState } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import axios from "axios";
import toast, {Toaster} from 'react-hot-toast';

function Otp() {
    const [showOTP, setshowOTP] = useState(false);
    const [value, setValue] = useState("");
    const [otp, setOTP] = useState("");
    const baseUrl = process.env.REACT_APP_BASE_URL_DEV;


    const handleGetOTP = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post(`${baseUrl}/api/v1/users/getOTP`);
            if (response.data.status === 'success') {
                toast.success('OTP sent Successfully');
            }
        } catch (err) {
            // console.log(err);
            toast.error('Failed to generate OTP. Try Again')
        }
    }

    const handleVerifyOTP = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post(`${baseUrl}/api/v1/users/verifyOTP`);
            if (response.data.status === 'success') {
                toast.success('OTP verified Successfully');
                setshowOTP(false);
            }
        } catch (err) {
            // console.log(err);
            toast.error('Please Enter the correct OTP')
        }
    }

    return (

        <div className='otpmain'>
            {showOTP &&
                <div className='otp'>
                    <div className='otp-second'>

                        <h1 className='otp-head' >Phone Verification</h1>

                        <h2 className='otp-head2'>Enter Your Number to get OTP </h2>
                        <PhoneInput
                            country={"india"}
                            enableSearch={true}
                            value={value}
                            className='profile-info otp-width'
                            onChange={(value) => setValue(value)}
                        />

                        <button className='otp-button' onClick={handleGetOTP}>Get OTP</button><br></br>
                        <h2 className='otp-head2'>Enter OTP shared on your contact number</h2>
                        <input name='otp' placeholder='XXXX' className='profile-info otp-width' type='number' maxLength="4" value={otp} onChange={e => setOTP(e.target.value)} required></input><br></br>
                        <button className='otp-button otp-margin' onClick={handleVerifyOTP}>Verify OTP</button>
                    </div>
                </div>


            }
            <Toaster/>
        </div>
    )

}
export default Otp