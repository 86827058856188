import React from "react";
import "../../styles/userLanding.css"
import { Link } from "react-router-dom";
function ServiceComponent (){
    return(
        <div className="service-component">
          <svg xmlns="http://www.w3.org/2000/svg" id="servcie-icon" width="30" height="30" fill="currentColor" class="bi bi-menu-button-fill serviceCompIcon" viewBox="0 0 16 16">
  <path d="M1.5 0A1.5 1.5 0 0 0 0 1.5v2A1.5 1.5 0 0 0 1.5 5h8A1.5 1.5 0 0 0 11 3.5v-2A1.5 1.5 0 0 0 9.5 0h-8zm5.927 2.427A.25.25 0 0 1 7.604 2h.792a.25.25 0 0 1 .177.427l-.396.396a.25.25 0 0 1-.354 0l-.396-.396zM0 8a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V8zm1 3v2a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2H1zm14-1V8a1 1 0 0 0-1-1H2a1 1 0 0 0-1 1v2h14zM2 8.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zm0 4a.5.5 0 0 1 .5-.5h6a.5.5 0 0 1 0 1h-6a.5.5 0 0 1-.5-.5z"/>
</svg>
<div className="service-display" id="service-ul-comp">
            <ul className="Service-ul" >
                <li className="Service-li">
                   <Link className="servcie-comp-link" to="/statement-of-purpose"> SOP</Link>
                </li>
                <li className="Service-li">
                   <Link className="servcie-comp-link" to="/university-shortlisting"> University Shortlisting</Link>
                </li>
                <li className="Service-li">
                    <Link className="servcie-comp-link" to="/visa-services">Visa Assistance</Link>
                </li>
                <li className="Service-li">
                  <Link className="servcie-comp-link" to="/resume">  Resume Building</Link>
                </li>
                <li className="Service-li">
                 <Link className="servcie-comp-link" to="/essays" > Essay  Editing</Link> 
                </li>
                
                <li className="Service-li">
                  <Link className="servcie-comp-link" to="/scholarship-application">  Scholarship Assistance</Link>
                </li>
                <li className="Service-li">
                 <Link className="servcie-comp-link" to="/profile-evaluation">  Profile Evaluation</Link> 
                </li>
                <li className="Service-li">
                 <Link  className="servcie-comp-link" to="/college-applications"> Complete Application Support</Link>  
                </li>
                
            </ul>
          
               
                
         

            </div>
        </div>
    )
}
export default ServiceComponent