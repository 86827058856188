import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Footer from "../footer/Footer";
import axios from 'axios';
import { useDispatch, useSelector } from "react-redux";
import toast, {Toaster} from 'react-hot-toast';
import { removeCreateServiceData } from './../../Redux/actions/createServiceActionCreators';


function Resume() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [title, setTitle] = useState("");
  const [desc, setDesc] = useState("");
  const [pricing, setPricing] = useState("");
  const [delivery, setDelivery] = useState("");
  const [No_Session, set_Session] = useState("");
  const [Requirement, set_Requirement] = useState("");

  const data = useSelector((state) => state.create_service_data);
  const baseUrl = process.env.REACT_APP_BASE_URL_DEV;
  const navigate = useNavigate();
  const sellerRole = useSelector((state) => state.get_seller_profile_id.role);
  const userRole = useSelector((state) => state.get_user_id.role);
  const dispatch = useDispatch();

  useEffect(() => {
      if(!sellerRole && !userRole){
          navigate('/',{replace : true, 
              state : {
                  signIn : true,
                  type : "counselor"
              }          
          })
      }else if(userRole){
          toast.error('You are not allowed to open this url');
          navigate('/userLanding');
      }
  }, [navigate, sellerRole, userRole]);

  const createResume = {
    title_Service: title,
    description: desc,
    pricing: pricing,
    productDelivery: delivery,
    numberOfSession: No_Session,
    additionalRequirement: Requirement,
  };

  const handleCancel = (e) => {
    e.preventDefault();
    toast('This may delete your progress');
    navigate('/counselor-dashboard');
  };

  const handleSavePublish = async (e) => {
    e.preventDefault();
    try {
      let combinedData = { ...data, ...createResume, serviceStatus: 'Hosted' };
      const res = await axios.post(`${baseUrl}/api/v1/services/create-ResumeBuilding`, combinedData);
      if (res.data.status === 'success') {
        toast.success('Service Created Successfully');
        dispatch(removeCreateServiceData({}));
      }
    } catch (err) {
      toast.error('Internal Server Error. Try again Later');
      // console.log(err.res);
    }
    navigate('/counselor-dashboard');
  };

  const handleSave = async (e) => {
    e.preventDefault();
    try {
      const combinedData = { ...data, ...createResume, serviceStatus: 'Draft' };
      const res = await axios.post(`${baseUrl}/api/v1/services/create-ResumeBuilding`, combinedData);
      if (res.data.status === 'success') {
        toast.success('Service Drafted Successfully! You can host it anytime from your dashboard.');
        dispatch(removeCreateServiceData({}));
      }
    } catch (err) {
      toast.error('Internal Server Error. Try again Later');
      // console.log(err);
    }
    navigate('/counselor-dashboard');
  };

  return (

    <div>
      <div className="profile">
        <div className="head-forms">
          <label className="mainheading">Resume</label>
        </div>
        <form onSubmit={handleSavePublish}>
          <div className="pageContainer">
          <div className="overView-flex">
                    <div class="miniheading"> Create Your Servcie!</div>
                    <div class="miniheading2 "> Step 2/2</div>
                    </div>
            <hr className="line"></hr>


            <div className="form-flex">
              <div className="label2">
                <label>Title Of Service <span className="required">*</span></label>
              </div>
              <div className="form-input">
                <input
                  name="name"
                  type="text"
                  className="profile-info2"
                  minLength="5"
                  maxLength="31"
                  required
                  placeholder="Maximum 31 chars or 5 words"
                  id="package"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                />
                <br></br>
              </div>
            </div>

            <div className="form-flex">
              <div className="label2">
                <label>Description <span className="required">*</span></label>
              </div>
              <div className="form-input">
              <textarea name="description" class="profile-info2 " rows="8" maxLength="500" placeholder="Add details about your service! (100 words)" value={desc} onChange={e => setDesc(e.target.value)} required />
            </div>              </div>
          
    

            <div className="form-flex">
              <div className="label2">
                <label>Pricing <span className="required">*</span></label>
              </div>
              <div className="form-input">

              <input name="price" style={{paddingLeft:"30px"}}  type="number" min = {1} required class="profile-info2 pricing" placeholder="₹" id="price" value={pricing} onChange={e => setPricing(e.target.value)} /><br></br>

              </div>
            </div>
            <div className="form-flex">
              <div className="label2">
                <label>Duration <span className="required">*</span></label>
              </div>
              <div className="form-input">
                <input
                  name="delivery"
                  type="number"
                  required
                  className="profile-info2"
                  id="days"
                  value={delivery}
                  onChange={(e) => setDelivery(e.target.value)}
                  placeholder="Enter No. of Days"
                  min = {1}
                />
                <br></br>
              </div>
            </div>

            <hr className="line2"></hr>
            <div className="form-flex">
              <div className="label2">
                <label>Number Of Session<span className="required">*</span></label>
              </div>
              <div className="form-input">
                <input
                  name="no-of-session"
                  required
                  type="number"
                  className="profile-info2"
                  placeholder="Enter the number of sessions required"
                  id="no-of-session"
                  value={No_Session}
                  onChange={(e) => set_Session(e.target.value)}
                  min = {1}
                />
                <br></br>
              </div>
            </div>


            <div className="form-flex">
              <div className="label2">
                <label>Additional Requirement for client</label>
              </div>
              <div className="form-input">
                <input
                  name="requirement$"
                  type="text"
                  placeholder="Add open-ended question /query /requirement from the buyer"
                  id="additional-field"
                  className="profile-info2"
                  value={Requirement}
                  onChange={(e) => set_Requirement(e.target.value)}
                />
                <br></br>
              </div>
            </div>
            <div className="button-flex">
              <div className="button-cancel-flex">
                <button className="button-cancel" onClick={handleCancel}>CANCEL</button>
              </div>
              <div className="button-save-flex">
                <div>
                  <button className="button-save" onClick={handleSave}>SAVE</button>
                </div>
                <div>
                  <button type="submit" name="savePublish" className="button-savepublish">SAVE &amp; PUBLISH</button>
                </div>
              </div>
            </div>
            <div className="button-mobile-forms">
              <div className="button-cancel-flex">
                <button className="button-save" onClick={handleSave}>SAVE</button>
              </div>
              <div className="button-save-flex">
                <div>
                  <button type="submit" name="savePublish" className="button-savepublish">SAVE &amp; PUBLISH</button>
                </div>
                <div>
                  <button className="button-cancel" onClick={handleCancel}>CANCEL</button>
                </div>
              </div>
            </div>
          </div>
           </form>
    <Footer/>
        </div >
      <Toaster/>
      </div >
      
    )
}
export default Resume



