import React, { useState, useEffect } from "react"
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Footer from "../footer/Footer";
import axios from 'axios';
import toast, { Toaster } from 'react-hot-toast';
import { removeCreateServiceData } from './../../Redux/actions/createServiceActionCreators';


function Sop() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [title, setTitle] = useState("");
  const [desc, setDesc] = useState("");
  const [pricing, setPricing] = useState();
  const [delivery, setDelivery] = useState();
  const [No_Session, set_Session] = useState();
  const [Requirement, set_Requirement] = useState("");
  const [No_Univ, setNo_Univ] = useState("");
  const [wordCount, setWordCount] = useState();

  const data = useSelector((state) => state.create_service_data)
  const sellerRole = useSelector((state) => state.get_seller_profile_id.role)
  const userRole = useSelector((state) => state.get_user_id.role)
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!sellerRole && !userRole) {
      navigate('/', {
        replace: true,
        state: {
          signIn: true,
          type: "counselor"
        }
      });
    } else if (userRole) {
      toast.error('You are not allowed to open this url');
      navigate('/userLanding');
    }
  }, [navigate, sellerRole, userRole]);

  const createSop = {
    title_Service: title,
    description: desc,
    pricing: pricing,
    productDelivery: delivery,
    numberOfSession: No_Session,
    additionalRequirement: Requirement,
    wordCount: wordCount,
    noOfUniversity: No_Univ,
  }

  const baseUrl = process.env.REACT_APP_BASE_URL_DEV;

  const handleCancel = (e) => {
    e.preventDefault();
    toast('This may delete your progress');
    navigate('/counselor-dashboard');
  }

  const handleSavePublish = async (e) => {
    e.preventDefault();
    try {
      let combinedData = { ...data, ...createSop, serviceStatus: 'Hosted' };
      const res = await axios.post(`${baseUrl}/api/v1/services/create-Sop`, combinedData);
      if (res.data.status === 'success') {
        toast.success('Service Created Succesfully');
        dispatch(removeCreateServiceData({}));
      }
    } catch (err) {
      // console.log(err);
      toast.error('Internal Server Error. Try again Later');
    }
    navigate('/counselor-dashboard');
  }

  const handleSave = async (e) => {
    e.preventDefault();
    try {
      const combinedData = { ...data, ...createSop, serviceStatus: 'Draft' };
      const res = await axios.post(`${baseUrl}/api/v1/services/create-Sop`, combinedData);
      if (res.data.status === "success") {
        toast.success('Service Drafted Succesfully! You can host it anytime from your dashboard.');
        dispatch(removeCreateServiceData({}));
      }
    } catch (err) {
      // console.log(err);
      toast.error('Internal Server Error. Please Try again Later');
    }
    navigate('/counselor-dashboard');
  };

  return (
    <div>
      <div class="profile">
        <div className="head-forms">
          <label className="mainheading">SOP</label>
        </div>
        <form onSubmit={handleSavePublish}>
          <div class="pageContainer">
            <div className="overView-flex">
              <div class="miniheading"> Create Your Servcie!</div>
              <div class="miniheading2 "> Step 2/2</div>
            </div>
            <hr class="line"></hr>
            <div className="form-flex">
              <div class="label2">
                <label >Title Of Service <span className="required">*</span></label>
              </div>
              <div className="form-input">

                <input name="title" type="text" required minLength="5" maxLength="31" class="profile-info2 " placeholder="Maximum 31 chars and minimum 6 words" id="package" value={title} onChange={e => setTitle(e.target.value)} /><br></br>

              </div>
            </div>

            <div className="form-flex">
              <div class="label2">
                <label >Description <span className="required">*</span></label>
              </div>
              <div className="form-input">
                <textarea name="description" required maxLength="500" class="profile-info2 " rows="8" placeholder="Add details about your service! (100 words)" value={desc} onChange={e => setDesc(e.target.value)} />

              </div>
            </div>

            <div className="form-flex">
              <div class="label2">
                <label >Pricing  <span className="required">*</span></label>
              </div>
              <div className="form-input">

                <input name="pricing" style={{paddingLeft:"30px"}}  required type="number" min={1} class="profile-info2 pricing" placeholder="₹" id="package" value={pricing} onChange={e => setPricing(e.target.value)} /><br></br>

              </div>
            </div>
            <div className="form-flex">
              <div class="label2">
                <label >Delivery Duration <span className="required">*</span></label>
              </div>
              <div className="form-input">

                <input name="delivery" type="number" min={1} required class="profile-info2 " placeholder="No. of Days" value={delivery} onChange={e => setDelivery(e.target.value)} id="package" /><br></br>

              </div>
            </div>

            <hr class="line2"></hr>
            <div className="form-flex">
              <div class="label2">
                <label >Word count <span className="required">*</span></label>
              </div>
              <div className="form-input">

                <input name="WordCount" type="number" min={1} required class="profile-info2 " placeholder="Word count" id="package" value={wordCount} onChange={e => setWordCount(e.target.value)} /><br></br>

              </div>
            </div>
            <div className="form-flex">
              <div class="label2">
                <label >Number of Session  <span className="required">*</span></label>
              </div>
              <div className="form-input">

                <input name="session" type="number" min={1} required class="profile-info2 " placeholder="Number Of Session" id="package" value={No_Session} onChange={e => set_Session(e.target.value)} /><br></br>

              </div>
            </div>

            <div className="form-flex">
              <div class="label2">
                <label >Number Of University <span className="required">*</span></label>
              </div>
              <div className="form-input">
                <input name="university" type="number" min={1} required class="profile-info2 " placeholder="Number Of university" id="package" value={No_Univ} onChange={e => setNo_Univ(e.target.value)} /><br></br>
              </div>
            </div>

            <div className="form-flex">
              <div class="label2">
                <label >Additional Requirement from client</label>
              </div>
              <div className="form-input">
                <input name="requirement$" type="text" placeholder="Add open-ended question /query /requirement from the buyer" id="additional-field" class="profile-info2" value={Requirement} onChange={
                  e => set_Requirement(e.target.value)} /><br></br>
              </div>

            </div>
            <div className="button-flex">
              <div className="button-cancel-flex" >

                <button Class="button-cancel" onClick={handleCancel}>CANCEL</button>
              </div>
              <div className="button-save-flex">
                <div>
                  <button Class="button-save" onClick={handleSave}>SAVE</button>
                </div>
                <div>
                  <button type="submit" name="savePublish" Class="button-savepublish" >SAVE & PUBLISH</button>
                </div>
              </div>
            </div>
            <div className="button-mobile-forms">
              <div className="button-cancel-flex" >
                <button Class="button-save" onClick={handleSave}>SAVE</button>

              </div>
              <div className="button-save-flex">
                <div>
                  <button type="submit" name="savePublish" Class="button-savepublish" >SAVE & PUBLISH</button>
                </div>
                <div>

                  <button Class="button-cancel" onClick={handleCancel}>CANCEL</button>

                </div>
              </div>
            </div>
          </div>
        </form>
        <Footer />
      </div>
      <Toaster />
    </div>
  )
}
export default Sop




