import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { APICall } from "./../../Redux/actions/serviceCardActionCreator";
const rating5 = new URL("../../images/1star.webp", import.meta.url)
const illustration5 = new URL("../../images/image 20.webp", import.meta.url)
const noprofile = new URL("../../images/noProfilepic.webp", import.meta.url)

function UserLandingService() {

  const data = useSelector(state => state.fetched_service_data);
  const dispatch = useDispatch();

  useEffect(() => {
    const baseUrl = process.env.REACT_APP_BASE_URL_DEV;
    const AllServices = `${baseUrl}/api/v1/services/Get-All-Hosted-Services`;
    APICall(AllServices, "AllHostedServices", dispatch);
  }, [])

  return (
    <>
      {
        data.length !== 0 ?
          data.map((item) => {
            return (
              <div key={item._id} class="service-card3" style={{
                marginLeft: "50px",
                marginRight: "60px"
              }} >
                <div className="service-card2">
                  <img width="30%" className="service-illus" src={illustration5} alt="loading..."></img>

                </div>
                <span style={{
                    width: "15%",
                    borderRadius: "50%",
                    height: "px",
                    padding: "10px 20px 10px 20px",
                   
                    position: "absolute",
                    left: "1.5%",
                    top: "97px",
                  }}> <img width="40px" src={noprofile}></img> </span> <span className="cardName2">{(item.seller !== undefined) ? item.seller[0].name : ''}</span><span className="card-country" style={{
                  background: "",

                  padding: "2px",

                  position: "relative",
                  left: "30%",
                  top: "9px",

                }}>
                  Country<br></br>
                  <img src={`${process.env.PUBLIC_URL}/countryFlags/${item.country}.webp`} class="seller-card-flag" style={{

                  }} width="10%" alt="loading..."></img>
                </span>
                <br></br>
                <div className="rating-digit">
                  4.3<img style={{
                    position: "relative",
                    top: "-1px",
                    left: "2px"
                  }} width="3%" src={rating5} alt="loading..."></img>
                </div>
                <hr style={{
                  position: "relative",
                  width: "90%",
                  top: "-10px",
                  margin: "10px auto",
                  color: "black",
                  fontWeight: "bolder"
                }}></hr>
                <div className="service-head">
                  <Link style={{
                    textDecoration: "none",
                    color: "black"
                  }} to="/serviceCardLanding">
                    {(item.title_Service.length > 30) ? item.title_Service.slice(0, 30) : item.title_Service}
                  </Link>
                </div>
                <div className="service-content"
                >
                  {(item.description.length > 125) ? item.description.slice(0, 125) + '...' : item.description}


                </div>
                <div>

                  <br></br>
                  <div style={{

                  }}>
                    <span className="INR2 INR5">price:</span>
                    <span className="INR2 INR3">INR</span><span className="INR2 INR4">{item.pricing}/-</span>
                  </div>
                </div>

              </div>
            )
          }) : null
      }
    </>

  )
}
export default UserLandingService