

import React, { useState } from "react"

import { Link } from 'react-router-dom';
import KonseloBookLink from './kounseloLead';
const logo2 = new URL("../../images/logo-final.webp", import.meta.url);
const facebook = new URL("../../images/Facebook.webp", import.meta.url);
const twitter = new URL("../../images/twitter.webp", import.meta.url);
const insta = new URL("../../images/Instagram.webp", import.meta.url);
const linkedIN = new URL("../../images/LinkedIn.webp", import.meta.url);
const Printerest = new URL("../../images/Pinterest.webp", import.meta.url);

function Footer() {
  const[show,setShow]=useState(false)
  return (

    <div  className="container5">
      {
        show &&
        <div>
< KonseloBookLink/>
</div>
}

      <div className="footer2">
        <div className="about">
          <span style={{
            background: "white",
            padding: "8px",
            borderRadius: "50%"
          }}> <img src={logo2} width="5%" alt = "loading..."></img></span> <Link to="/" className='footer-brand'> KOUNSELO</Link>
          <p className='foot-para' style={{

          }}>We make it easy for you to choose a counselor for your study abroad journey by bringing multiple consultants under one roof.
            Our aim is to enable every aspirant to get the best service and guidance and to help them find their most suited study abroad counselor. </p>
          <h5 className='find-us-on'> FIND US ON</h5>
          <div className="footer-link-main">
          <Link classname="footlink" to="https://www.facebook.com/Kounselo" target="_blank"><img src={facebook} className="social-media" alt=""></img></Link>
          <Link classname="footlink" to="https://twitter.com/kounseloindia?s=11&t=l_7ZXQszpxBTPvBPrtej9g" target="_blank"><img src={twitter} className="social-media" alt=""></img></Link>
          <Link classname="footlink" to="https://www.instagram.com/kounselo/" target="_blank"><img src={insta} className="social-media" alt=""></img></Link>
          <Link classname="footlink" to="https://www.linkedin.com/company/kounselo/people/?viewAsMember=true" target="_blank"><img src={linkedIN} className="social-media" alt=""></img></Link>
          <Link classname="footlink" to="https://in.pinterest.com/Kounselo/" target="_blank"><img src={Printerest} className="social-media" alt=""></img></Link>
          </div>
        </div>
        {/* <div className="service">
          <h4 className="foot-head">SERVICES</h4>
          <Link className='foot-link' to="/statement-of-purpose" > <h5 className="foot-content">SOP</h5></Link>
          <Link className='foot-link' to="/university-shortlisting">  <h5 className="foot-content">University Shortlisting</h5></Link>
          <Link className='foot-link' to="/profile-evaluation"> <h5 className="foot-content">Profile Evaluation</h5></Link>
          <Link className='foot-link' to="/resume">  <h5 className="foot-content">Resume Building</h5></Link>
          <Link className='foot-link' to="/visa-services"> <h5 className="foot-content">Visa Assistance</h5></Link>
          <Link className='foot-link' to="/scholarship-application">  <h5 className="foot-content">Scholorship Assistance</h5></Link>
          <Link className='foot-link' to="/college-applications">  <h5 className="foot-content">Complete Application Support </h5></Link>
          <Link className='foot-link' to="/essays">  <h5 className="foot-content">Essay Editing </h5></Link>
        </div>
        <div className="support">
          <h4 className="foot-head">EXPLORE</h4>
          <Link className='foot-link' to="/all-top-counselor"> <h5 className="foot-content">COUNSELORS</h5></Link>
          <Link className='foot-link' to="/all-service"> <h5 className="foot-content">SERVICES</h5></Link>

        </div> */}
  
        <div className="social">
          <h4 className="foot-head">RESOURCES</h4>
          <Link className='foot-link' to="">  <h5 onClick={()=>setShow(!show)} className="foot-content">Free IELTS E-Book</h5></Link>
          <Link className='foot-link' to="/all-blogs"> <h5 className="foot-content">BLOG</h5></Link>


        </div>
        <div className="crypto">
          <h4 className="foot-head"> COMPANY</h4>
          <Link className='foot-link' to="/about-us">  <h5 className="foot-content">ABOUT US</h5></Link>
          <Link className='foot-link' to="/contact-us">  <h5 className="foot-content">CONTACT US</h5></Link>
          <Link className='foot-link' to="/privacy-policy">  <h5 className="foot-content">PRIVACY & POLICY</h5></Link>
          <Link className='foot-link' to="/term-condition">  <h5 className="foot-content">TERMS & CONDITION </h5></Link>
          <Link className='foot-link' to="/cookie-policy">  <h5 className="foot-content">COOKIE POLICY </h5></Link>
          <Link className='foot-link' to="/refund-policy">  <h5 className="foot-content">REFUND POLICY </h5></Link>

        </div>

      </div>
      <h5 class="copywrite" style={{

      }}>Privacy Policy | Copyright @ Kounselo Education Facilities Pvt. Ltd. 2023. All Rights Reserved.</h5>
    </div>
  )
}
export default Footer;