import React, { useEffect } from "react";
import Footer from "../footer/Footer";
import "../../styles/terms.css"
function Term(){
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
    return(

        <div className="terms privacy-head">
                <div>
    <p class="cookie">TERMS AND Conditions
        <hr style={{
            margin:"auto"
        }}></hr>
    </p>
    <div class="outline">
        <h3 class="heads">Introduction</h3>
        <p class="innertext">These terms and conditions (“Terms”) govern using Kounselo Education Services. You agree to
            be bound by these Terms by accessing or using the Marketplace. If you disagree with these Terms, do not use
            the Marketplace.</p>
        <h3 class="heads">Services:</h3>
        <p class="innertext">The Marketplace provides a platform for users to discover and purchase educational products
            and services. Kounselo Education Services does not endorse or guarantee any products or services on the
            Marketplace.</p>
        <h3 class="heads">User Accounts:</h3>
        <p class="innertext">To use the Marketplace, you must create a user account. You are responsible for maintaining
            the confidentiality of your account and password, and for restricting access to your computer. You agree to
            accept responsibility for all activities that occur under your account.</p>
        <h3 class="heads">User Content:</h3>
        <p class="innertext">The Marketplace may allow you to submit, upload, publish, or otherwise make available
            content, including but not limited to text, photographs, and videos (“User Content”). You retain all rights
            in your User Content and are responsible for ensuring that your User Content does not infringe on any
            third-party rights.</p>
        <h3 class="heads">Proprietary Rights:</h3>
        <p class="innertext">The Marketplace and its content, including but not limited to software, text, graphics,
            logos, button icons, images, audio clips, and data compilations, are the property of Kounselo Education
            Services or its licensors and are protected by Indian and international copyright laws.</p>
        <h3 class="heads">Disclaimers:</h3>
        <p class="innertext">The Marketplace and its content are provided on an “as is” and “as available” basis without
            any representation or warranty, express or implied, of any kind. Kounselo Education Services makes no
            representations or warranties of any kind, express or implied, regarding the operation of the Marketplace or
            the information, content, materials, or products included on the Marketplace.</p>
        <h3 class="heads">Limitation of Liability:</h3>
        <p class="innertext">In no event will Kounselo Education Services be liable for any indirect, incidental,
            special, or consequential damages, including but not limited to, loss of profits, data, or use, arising out
            of the use or inability to use the Marketplace, whether based on warranty, contract, tort, or any other
            legal theory, and whether or not Kounselo Education Services is advised of the possibility of such damages.
        </p>
        <h3 class="heads">Indemnification:</h3>
        <p class="innertext">You agree to indemnify and hold Kounselo Education Services and its affiliates, directors,
            officers,
            employees, agents, and partners harmless from any claims, liabilities, damages, losses, and expenses,
            including but not limited to, reasonable attorney’s fees, arising out of or in any way connected with your
            use of the Marketplace.</p>
        <h3 class="heads">Changes to the Terms:</h3>
        <p class="innertext">Kounselo Education Services reserves the right to modify these Terms without prior notice.
            Your continued use
            of the Marketplace following any such modification constitutes your agreement to be bound by the modified
            Terms.</p>
        <h3 class="heads">Dispute Resolution:</h3>
        <p class="innertext">Any dispute arising out of or in connection with these Terms shall be resolved in
            accordance with the laws of
            India and shall be subject to the jurisdiction of the courts in Gwalior.</p>
        <h3 class="heads">Contact Information:</h3>
        <p class="innertext">If you have any questions about these Terms, please contact Kounselo Education Services at
            info@kounselo.com.
        </p>
        <div class="accept">
      
        </div>
    </div>

    </div>
    <Footer/>
        </div>
    )
}
export default Term