import React from "react";

import "../../styles/terms.css"
const illustration = new URL("../../images/blade.webp", import.meta.url)
const illustration5 = new URL("../../images/image 20.webp", import.meta.url)
const attach = new URL("../../images/attach.webp", import.meta.url)
const send = new URL("../../images/send.webp", import.meta.url)
const location = new URL("../../images/timelinelocation.webp", import.meta.url)
const specs = new URL("../../images/specs.webp", import.meta.url)
const approved = new URL("../../images/approved.webp", import.meta.url)
const extension = new URL("../../images/time-extention.webp", import.meta.url)
function TimeLine() {
    return (
        <div className="time-line-main" >

            <div className="time-line-content1">
                <div className="timeline-time">
                    3 march 2023
                </div>
                <div className="time-line-content">

                    <div style={{
                        display: "flex"
                    }}>
                        <div className="time-pic-main">
                            <img src={illustration} className="time-pic" alt = "loading..."></img>
                        </div>
                        <div className="time-data">
                            <span style={
                                {
                                    fontWeight: "500"
                                }
                            }> Monica Talpade </span> <span>has a requirement from your end. Please submit the same</span>
                            <div className="time-data2">
                                <div className="time-box-data">
                                    Monica Talpade: ipsum lorem ipsum lorem ipsum  Lorem ipsum lorem ipsum lorem ipsum  Lorem ipsum lorem ipsum lorem ipsum Lorem ipsum lorem
                                </div>
                                <button className="time-btn1">submit requirement</button>
                            </div>
                        </div>
                       
                    </div>

                    <hr style={{
                        marginLeft:"20px",
                        width:"98%"

                    }}></hr>
                    <div className="time-line-content2">
                        <div style={{
                            display: "flex"
                        }}>
                            <div className="time-pic-main2">
                                <img src={location} className="time-pic2" alt = "loading..."></img>
                            </div>
                            <div className="time-data">
                                <span style={
                                    {
                                        fontWeight: "500"
                                    }
                                }> Delivery received! </span>

                            </div>
                        </div>


                    </div>
                    <hr style={{
                        marginLeft:"20px",
                        width:"98%"

                    }}></hr>

                    <div className="time-line-content2">
                        <div style={{
                            display: "flex"
                        }}>
                            <div className="time-pic-main4">
                                <img src={specs} className="time-pic3" alt = "loading..."></img>
                            </div>
                            <div className="time-data">
                                <span style={
                                    {
                                        fontWeight: "500"
                                    }
                                }> Review content </span>
                                <div>
                                    <div className="time-box-data">
                                        Monica Talpade: ipsum lorem ipsum lorem ipsum  Lorem ipsum lorem ipsum lorem ipsum  Lorem ipsum lorem ipsum lorem ipsum Lorem ipsum lorem
                                    </div>
                                    <h5 className="attachment">2 Attachment</h5>
                                    <div className="time-data-flex">

                                        <div className="time-data3">

                                        </div>
                                        <div className="time-data3" style={{
                                            marginLeft: "50px"
                                        }}>

                                        </div>

                                    </div>
                                    <button className="time-btn1">
                                        Accept
                                    </button>
                                    <button className="time-btn2">
                                        Ask for revision
                                    </button><br></br>
                                    <p className="submit-review">Submit a review</p>
                                </div>
                            </div>

                        </div>
                        <hr style={{
                        marginLeft:"20px",
                        width:"98%"

                    }}></hr>
                        <div className="time-line-chat">
                            <div className="time-pic-main">
                                <img src={approved} width="50px" className="time-pic" alt = "loading..."></img>
                            </div>
                            <div className="time-data">
                                <span style={
                                    {
                                        fontWeight: "500"
                                    }
                                }> Monica Talpade </span> <span> has approved your cancellation request</span>
                                <div className="time-data4">
                                    <div className="time-box-data">
                                        Monica Talpade: ipsum lorem ipsum lorem ipsum  Lorem ipsum lorem ipsum lorem ipsum  Lorem ipsum lorem ipsum lorem ipsum Lorem ipsum lorem
                                    </div>

                                </div>
                                <button className="time-btn1">Chat with them</button>
                            </div>
                        </div>
                    </div>
                    <hr style={{
                        marginLeft:"20px",
                        width:"98%"

                    }}></hr>
                    <div style={{
                        display: "flex",
                        marginTop: "70px",
                        marginBottom: "70px"

                    }}>
                        <div className="time-pic-main3">
                            <img src={extension} className="time-pic" alt = "loading..."></img>
                        </div>
                        <div className="time-data">


                            <span style={
                                {
                                    fontWeight: "500"
                                }
                            }> Delivery timeline extension</span>
                            <div >
                                Request from Monica Talpade for extension of delivery date by
                                <div>time</div>
                            </div>
                            <button className="time-btn1">Accept</button> <button className="time-btn2">Decline</button>
                        </div>


                    </div>
                    <input type="text" className="time-input"></input><button className="attach"><img width="27px" src={attach} alt = "loading..."></img>
                    </button><button className="send"><img width="27px" src={send} alt = "loading..."></img></button>
                </div>


            </div>


            <div className="time-side1">
                <div className="time-side">
                    <h6 className="order-details">Order details</h6>
                    <div style={{
                        display: "flex"
                    }}>
                        <div>
                            <img src={illustration5} width="90px" alt = "loading..."></img>
                        </div>
                        <div style={{
                            marginLeft: "10px"
                        }}>
                            <div className="time-title">
                                title of service
                            </div>
                            <button className="time-btn3">Status</button>

                        </div>

                    </div>
                    <div style={{
                        display: "flex",
                        justifyContent: "space-between",
                        marginTop: "20px"
                    }}>
                        <div>
                            <h6>
                                service category
                            </h6>
                            <h6>
                                Duration
                            </h6>
                            <h6>
                                Price
                            </h6>
                            <h6>
                                Order Id
                            </h6>
                        </div>
                        <div>
                            <h6>
                                Content
                            </h6>
                            <h6>
                                Content
                            </h6><h6>
                                Content
                            </h6><h6>
                                Content
                            </h6>
                        </div>
                    </div>

                </div>
                <button className="time-btn1">Cancel order</button>
            </div>


        </div>
    )
}
export default TimeLine