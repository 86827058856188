import React from "react";
import "../../styles/popularProfile.css"
import Flickity from 'react-flickity-component'
import "../../styles/userLanding.css"
import SellerService from "./personalProfilecard";

const flickityOptions = {
    initialIndex: 0,
    wrapAround: true,
    freeScroll: true,
    pageDots: false,
    autoPlay:"1000s",
     }
     
function SellerServiceSlider(){
    
    return(
        <div>
         <div class="container-fluid">      
            
        <Flickity

      className={'carousel17'} // default ''
      elementType={'div'} // default 'div'
      options={flickityOptions} // takes flickity options {}
      disableImagesLoaded={true} // default false
      reloadOnUpdate={true} // default false
      static={false} // default false
    > 
<SellerService/>
    </Flickity>
    </div>
        </div>
    )
}
export default SellerServiceSlider