import React, { useState,useEffect } from "react";
import "../../styles/popularProfile.css"
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import "../../styles/userLanding.css"
import Flickity from 'react-flickity-component'
import { SellerCardsAPICall } from "../../Redux/actions/GetAllSellerCardsActionCreator";
import { useDispatch, useSelector } from "react-redux";
import { getSellerIdFromSellerCard } from "./../../Redux/actions/GetSellerID_UserInterfaceActionCreator";
import toast, { Toaster } from 'react-hot-toast';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
const rating5 = new URL("../../images/1star.webp", import.meta.url)
const noprofile = new URL("../../images/noProfilepic.webp", import.meta.url)

function PopularProfileMobile() {
  const flickityOptions = {
    initialIndex: 1,
    pageDots: false,

  }
  const baseUrl = process.env.REACT_APP_BASE_URL_DEV;

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [data, setData] = useState([]);
  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await axios.get(`${baseUrl}/api/v1/sellers/getAllSellerCards`);
      setData(response.data.data.getAllSeller.reverse());
      
    } catch (error) {
    }
  };

  const ViewSellerProfile = async (e, id) => {
    e.preventDefault();
    try {
      if (id)
        navigate('/counselor-profile', dispatch(getSellerIdFromSellerCard(id)));
    } catch (err) {
      toast.error('No such profile found');
      // console.log(err);
    }
  };

  const ChatWithMe = async (e, id) => {
    e.preventDefault();
    if (id) navigate('/chat');
  }


  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
      slidesToSlide: 1 // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 3,
      slidesToSlide: 1 // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 600, min: 0 },
      items: 1,
      slidesToSlide: 1 // optional, default to 1.
    }
  };

  return (
    <div style={{

    }} class="slider9 container-fluid">
      <div className="Slider-heading">
        <span className="popular-seller-heading" >Popular Counselors</span><br></br><span className="popular-seller-heading2">Check out some of the top counselors on our platform</span><br></br>
        <span className="popular-seller-view-more"><Link to="/education-counselors" style={{
          textDecoration: "none",
          color: "#7D7DB2"
        }}>View All</Link></span>

      </div>

      <Carousel

        focusOnSelect={true}

        minimumTouchDrag={80}
        swipeable={true}
        removeArrowOnDeviceType={["tablet"]}
        draggable={true}
        showDots={false}
        responsive={responsive}
        ssr={true} // means to render carousel on server-side.
        infinite={true}
  
        autoPlaySpeed={4000}
        autoPlay={true}
        keyBoardControl={true}

        transitionDuration={500}
        containerClass="carousel"
      >
         {data.length !== 0 ?
            data.map((item) => {
              return (
                <div key={item._id} >
                  <div class="carousel-cell " >
                    <div className='service-card-main'>
                      <div className="service-card">

                        <img width="130px" height="130px" style={{ borderRadius: "50%" }} src={item.photo !== undefined ? `https://kounselodata.s3.ap-south-1.amazonaws.com/${item.photo}` : noprofile} alt="loading..."></img>

                      </div>
                    </div>
                    <div></div>
                    <span className="cardName">{item.name} </span>
                    <br></br>
                    <div className="" style={{
                     
                      position:"relative",top:"50px",fontSize:"14px"
                    }}>
                     
                    </div><br></br>
                    <div>

                    </div>
                    <div style={{
                      textAlign: "center"
                    }}>

                      <div>
                        <div style={{margin: "70px auto 0px auto",width: "95%" ,height:"70px", textAlign: "center" }}>
                          {(item.personalDescription.length > 5) ? item.personalDescription.slice(0, 80) + "..." : item.personalDescription}

                        </div>



                        <div className="service-S-from">
                          Services from
                        </div><br></br>
                        <span className="INR9" style={{
                          color: "black"
                        }}>INR</span><span className="INR9 INR7" style={{
                          fontWeight: "bold"
                        }} >{item.initialPricing}/-</span>
                      </div>
                      <div style={{ width: "100%", textAlign: "center" }}>
                        <Link to={item.TopmateLink}> <button className="view-btn" >VIEW PROFILE</button></Link>

                      </div>
                      {/* <button style={{
                        position: "relative",
                        left: "30px",
                        top: "10px",
                        border: "none",
                        color: "white",
                        fontSize: "10px",
                        padding: "4px",
                        width: "40%", background: "linear-gradient(90deg, #7176C4 0.15%, #7D7EC7 99.78%)",
                        fontWeight: "bold"
                      }} >BOOK A CALL</button> */}

                    </div>
                  </div>
                </div>
              )
            }) : <div></div>
          }

      </Carousel>
    </div>

  )
}
export default PopularProfileMobile