import React, { useState, useEffect } from "react";
import Flickity from 'react-flickity-component'
import Footer from "../footer/Footer";
import "../../styles/seller.css";
import "../user/userDash.css";
import SellerServiceSlider from "./sellerpersonalSlider";
import SellerService from "./personalProfilecard";
import { useSelector } from "react-redux";
import axios from "axios";
import { Link } from "react-router-dom";

const no_profile = new URL("../../images/noProfilepic.webp", import.meta.url)
const rating1 = new URL("../../images/Group 33614.webp", import.meta.url)
const rating2 = new URL("../../images/Group 33613.webp", import.meta.url)
const rating3 = new URL("../../images/Group 33612.webp", import.meta.url)
const rating4 = new URL("../../images/Group 33611.webp", import.meta.url)
const rating5 = new URL("../../images/1star.webp", import.meta.url)
const illus = new URL("../../images/girl-front-color.webp", import.meta.url)
const world = new URL("../../images/world.webp", import.meta.url)
const ping = new URL("../../images/ping.webp", import.meta.url)
const shield = new URL("../../images/Shield.webp", import.meta.url)
const disipline = new URL("../../images/disipline.webp", import.meta.url)
const scholar = new URL("../../images/scholar.webp", import.meta.url)
const edit = new URL("../../images/edit1.webp", import.meta.url)
const dropdown = new URL("../../images/dropdownHome.webp", import.meta.url)
const graduate = new URL("../../images/graduate.webp", import.meta.url)
const stream = new URL("../../images/stream.webp", import.meta.url)
const drop2 = new URL("../../images/dropdownHome.webp", import.meta.url)

const flickityOptions = {
  initialIndex: 2,
  pageDots: false,

}

function SellerPersonal() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [isActive, setIsActive] = useState(false);
  const [isActive2, setIsActive2] = useState(false)
  const [isActive3, setIsActive3] = useState(false);
  const [isActive5, setIsActive5] = useState(false)
  const [isActive6, setIsActive6] = useState(false)
  const [isActive7, setIsActive7] = useState(false)
  const [isActive9, setIsActive9] = useState(false)
  const [isActive10, setIsActive10] = useState(false)
  const [isActive11, setIsActive11] = useState(false)
  const [isActive12, setIsActive12] = useState(false)
  const [islanguages, setIsLanguages] = useState(false)
  const [data, setData] = useState([]);
  const sellerId = useSelector(state => state.get_seller_profileView_id);


  useEffect(() => {
    const baseUrl = process.env.REACT_APP_BASE_URL_DEV;
    const getSellerProfile = async () => {
      try {
        const response = await axios.get(`${baseUrl}/api/v1/sellers/getSellerProfile/${sellerId}`);
        console.log([response.data.data.seller]);
        setData([response.data.data.seller])
      }
      catch (err) {
        // console.log(err);
      }
    }
    getSellerProfile();
  }, [sellerId]);

  // seller mobile screen
  return (
    <>
      {data.length !== 0 ?
        data.map((item) => {
          console.log(item, "item");
          return (
            // mobile
            <div key={item._id} className="seller-main">
              <div className="seller-personal-mobile" >

                <img src={item.photo !== undefined ? `https://kounselodata.s3.ap-south-1.amazonaws.com/${item.photo}` : no_profile} style={{ borderRadius: "50%" }} width="130px" height="130px" className="user-personal-image" alt="loading..."></img>
                <div className="edit-photo2" id="edit-photo">
                  {/* <button className="my-cross" id="my-pic-cross">x</button> */}

                  <img width="100%" height="auto" style={{ borderRadius: "50%" }} src={item.photo !== undefined ? `https://kounselodata.s3.ap-south-1.amazonaws.com/${item.photo}` : no_profile} alt="loading..."></img>
                  <img src={edit} className="edit-pic-icon" width="15px" alt="loading..."></img>


                  <br></br>
                  {/* <button className="my-save" style={{ marginTop: "40px" }}>
                    SAVE
                  </button>
                  <button className="my-cancil">
                    CANCEL
                  </button> */}
                </div>
                <br></br>
                <h4 className="seller-name" style={{ display: "inline-block" }}>{item.name} </h4>
                {/* <button className="profile-chat">LETS CHAT</button> */}

                <div className="seller-dis-full2">
                  <div style={{
                    textAlign:"center",
                    fontSize:"20px",
                    marginBottom:"10px"
                    
                  }}>DESCRIPTION </div> <div style={{ margin: "auto", width: "90%",fontSize:"15px" }}>
                    {item.personalDescription !== undefined && item.personalDescription !== null ? item.personalDescription : ''}</div>
                </div>
                <div className="seller-countries-spes2">Countries of Specilization</div>
                <div className="seller-overflow2" >
                

                    {item.sellerCountrySpecialization !== null ? item.sellerCountrySpecialization.map((items) => {
                      return (
                        <div key={items} style={{
                          width: "150px", margin:"30px auto"
                        }}>
                          <div className="flag-div">
                            <div className="seller-flag2">
                              <img className="profile-image3" src={`${process.env.PUBLIC_URL}/countryFlags/${items}.webp`} alt="loading..."></img>
                            </div>
                            <div >
                              <h5 style={{ margin: "10px auto" }}>{items}</h5>
                            </div>
                          </div>
                        </div>
                      )
                    }) : null
                    }
                  
                </div>

                <div className="sellerdrop">
                  <div className="seller-drop-content">
                    <span>General <img src={dropdown} width="10px" onClick={() => setIsActive(!isActive)} alt='loading...'></img></span>
                    {isActive &&
                      <div className="seller-discription4">


                        <div className="profile-flex">
                          <div className="profile-image">
                            <img src={shield} alt='loading...' width="15px"></img>
                          </div>
                          <div>
                            <span className="seller-rating2">Ratings</span>
                          </div>
                        </div>
                        <div>

                          <span className="seller-star-rate"><img src={rating3} width="40px" alt='loading...'></img></span>
                        </div>
                        <div className="profile-flex">
                          <div className="profile-image">
                            <img src={world} alt='loading...'></img>
                          </div>

                          <div>
                            <span className="seller-rating2">Languages <img onClick={() => setIsLanguages(!islanguages)} width="10px" src={drop2} alt='loading...'></img></span>
                          </div>

                        </div>
                        {islanguages && item.sellerLanguageKnown !== null ?
                          item.sellerLanguageKnown.map((items) => {
                            return (
                              <div key={items} className="drop-flex">
                                <div className="sellerpersonal-info-drop" >
                                  {items}
                                </div>
                              </div>
                            )
                          }) : null

                        }
                      </div>
                    }
                  </div>

                  <div className="seller-drop-content">
                    <span >Personal Info <img width="10px" src={dropdown} onClick={() => setIsActive2(!isActive2)} alt='loading...'></img></span>
                    {isActive2 &&
                      <div className="seller-discription4">

                        <div className="profile-flex">
                          <div className="profile-image">
                            <img src={graduate} alt='loading...' width="10px"></img>
                          </div>
                          <div>
                            <span className="seller-rating2">University <img onClick={() => setIsActive9(!isActive9)} width="10px" src={drop2} alt='loading...'></img></span>
                          </div>
                        </div>

                        {isActive9 &&
                          <div className="drop-flex">
                            <div className="sellerpersonal-info-drop" >
                              {(item.sellerUniversity !== undefined && item.sellerUniversity !== null) ? item.sellerUniversity : ''}
                            </div>
                          </div>
                        }
                        <div className="profile-flex">
                          <div className="profile-image">
                            <img src={stream} alt='loading...' width="14px"></img>
                          </div>
                          <div><span className="seller-rating2">Stream <img onClick={() => setIsActive7(!isActive7)} width="10px" src={drop2} alt='loading...'></img></span>
                          </div>
                        </div>

                        {isActive7 &&
                          <div className="drop-flex">
                            <div className="sellerpersonal-info-drop" >
                              {item.sellerStream !== undefined && item.sellerStream !== null ? item.sellerStream : ''}
                            </div>
                          </div>

                        }


                      </div>
                    }
                  </div>
                  <div className="seller-drop-content">
                    <span >Expert in <img width="10px" src={dropdown} onClick={() => setIsActive5(!isActive5)} alt='loading...'></img></span>
                    {isActive5 && <div className="seller-discription4">
                      <div className="profile-flex">
                        <div className="profile-image" >
                          <img src={scholar} style={{
                            marginBottom: "2px"
                          }} width="15px" alt='loading...'></img>
                        </div>

                        <div>
                          <span onClick={() => setIsActive6(!isActive6)} className="seller-rating2">Degree <img width="10px" src={drop2} alt='loading...'></img></span>
                        </div>
                      </div>
                      {isActive6 && item.sellerDegrees !== null ?
                        item.sellerDegrees.map((items) => {
                          return (
                            <div class="drop-flex">
                              <div key={items} className="">
                                <div className="sellerpersonal-info-drop" >
                                  {items}
                                </div>
                              </div>
                            </div>

                          )
                        }) : null

                      }
                      <div className="profile-flex">
                        <div className="profile-image">
                          <img src={disipline} className="discipline-illus" width="5px" alt='loading...'></img>

                        </div>
                        <div>
                          <span className="seller-rating2">
                            Disciplines <img onClick={() => setIsActive10(!isActive10)} width="10px" src={drop2} alt='loading...'></img></span>
                        </div>
                      </div>

                      {isActive10 && item.sellerDisciplineSpecialization !== null ?
                        item.sellerDisciplineSpecialization.map((items) => {
                          return (
                            <div key={items} className="drop-flex">
                              <div className="sellerpersonal-info-drop" >
                                {items}
                              </div>
                            </div>

                          )
                        }) : null
                      }
                     
                    </div>
                    }
                  </div>


                

                  {/* <div className="seller-drop-content">
                    <span >Rating<img style={{ marginLeft: "4px" }} width="10px" src={dropdown} onClick={() => setIsActive4(!isActive4)} alt='loading...'></img>
                    </span>
                    {isActive4 &&
                      <div className="rating-mobile">
                        <span className="reviews-head">Ratings</span><br></br>
                        <div className="">
                          <div className="rating-star">{item.reviews.count5 !== undefined && item.reviews.count5 !== null ? item.reviews.count5 : 0} <img className="rating-star-1" src={rating4} style={{
                            position: "relative",
                            top: "-3px"

                          }} width="100px" alt='loading...'></img></div>
                          <div className="rating-star">{item.reviews.count4 !== undefined && item.reviews.count4 !== null ? item.reviews.count4 : 0}<img className="rating-star-1" style={{
                            position: "relative",
                            top: "-3px"

                          }} src={rating3} alt='loading...'></img></div>
                          <div className="rating-star">{item.reviews.count3 !== undefined && item.reviews.count3 !== null ? item.reviews.count3 : 0}  <img className="rating-star-1" style={{
                            position: "relative",
                            top: "-3px"

                          }} src={rating2} alt='loading...'></img></div>
                          <div className="rating-star">{item.reviews.count2 !== undefined && item.reviews.count2 !== null ? item.reviews.count2 : 0}<img className="rating-star-1" style={{
                            position: "relative",
                            top: "-3px"

                          }} src={rating1} alt='loading...'></img></div>
                          <div className="rating-star">{item.reviews.count1 !== undefined && item.reviews.count1 !== null ? item.reviews.count1 : 0} <img className="rating-star-1" style={{
                            position: "relative",
                            top: "-3px",
                          }} src={rating5} alt='loading...'></img></div>

                        </div>
                      </div>
                    }
                  </div> */}
                </div>
                <h3 style={{
                  marginTop: "20px"
                }}>Services</h3>
                <SellerService />
                <Footer />
              </div>


              {/* seller pc screen */}
              <div className="sellerr" >
                <div className="sellerr-info">
                  <img src={item.photo !== undefined ? `https://kounselodata.s3.ap-south-1.amazonaws.com/${item.photo}` : no_profile} style={{ borderRadius: "50%" }} width="130px" height="130px" className="seller-personal-image" alt="loading..."></img>
                  <h4 className="seller-l-name">{item.name !== undefined ? item.name : ''}</h4>

                  <div className="seller-discription3">

                    <span className="seller-general">General</span><br></br>


                    <div className="profile-flex">
                      <div className="profile-image">
                        <img src={shield} alt="loading..." width="15px"></img>
                      </div>
                      <div>
                        <span className="seller-rating2">Ratings</span>
                      </div>
                    </div>
                    <div>
                      <span className="seller-star-rate"><img src={rating3} width="40px" alt="loading..."></img></span>
                    </div>
                    <div className="profile-flex">
                      <div className="profile-image">
                        <img src={graduate} width="15px" alt="loading..."></img>
                      </div>
                      <div>
                        <span className="seller-rating2">Known Languages <img onClick={() => setIsLanguages(!islanguages)} width="10px" src={drop2} alt="loading..."></img></span>
                      </div>

                    </div>
                    {islanguages && item.sellerLanguageKnown !== null ?
                      item.sellerLanguageKnown.map((items) => {
                        return (
                          <div key={items} className="drop-flex">
                            <div className="sellerpersonal-info-drop" >
                              {items}
                            </div>
                          </div>

                        )
                      }) : null

                    }
                  </div>

                  <div className="seller-discription3">

                    <span className="seller-general">Personal Info</span><br></br>

                    <div className="profile-flex">
                      <div className="profile-image">
                        <img src={graduate} width="15px" alt="loading..."></img>
                      </div>
                      <div>
                        <span className="seller-rating2">University <img onClick={() => setIsActive9(!isActive9)} width="10px" src={drop2} alt="loading..."></img></span>
                      </div>
                    </div>

                    {isActive9 &&
                      <div className="drop-flex">
                        <div className="sellerpersonal-info-drop" >
                          {item.sellerUniversity !== undefined && item.sellerUniversity !== null ? item.sellerUniversity : ''}
                        </div>
                      </div>
                    }
                    <div className="profile-flex">
                      <div className="profile-image">
                        <img src={stream} width="15px" alt="loading..."></img>
                      </div>
                      <div><span className="seller-rating2">Stream <img onClick={() => setIsActive7(!isActive7)} width="10px" src={drop2} alt="loading..."></img></span>
                      </div>
                    </div>

                    {isActive7 &&
                      <div className="drop-flex">
                        <div className="sellerpersonal-info-drop" >
                          {item.sellerStream !== undefined && item.sellerStream !== null ? item.sellerStream : ''}
                        </div>
                      </div>
                    }
                  </div>
                  <div className="seller-discription3">

                    <span className="seller-general">Professional info</span><br></br>

                    <div className="profile-flex">
                      <div className="profile-image" >
                        <img src={scholar} width="15px" alt="loading..."></img>
                      </div>

                      <div>
                        <span onClick={() => setIsActive6(!isActive6)} className="seller-rating2">Degree <img width="10px" src={drop2} alt="loading..."></img></span>
                      </div>
                    </div>
                    {isActive6 && item.sellerDegrees !== null ?
                      item.sellerDegrees.map((items) => {
                        return (
                          <div key={items} className="drop-flex">
                            <div className="sellerpersonal-info-drop" >
                              {items}
                            </div>
                          </div>
                        )
                      }) : null
                    }
                    <div className="profile-flex">
                      <div className="profile-image">
                        <img src={disipline} width="5px" alt="loading..."></img>

                      </div>
                      <div>
                        <span className="seller-rating2">

                          Disciplines <img onClick={() => setIsActive10(!isActive10)} width="10px" src={drop2} alt="loading..."></img></span>
                      </div>
                    </div>
                    {isActive10 && item.sellerDisciplineSpecialization !== null ?
                      item.sellerDisciplineSpecialization.map((items) => {
                        return (
                          <div key={items} className="drop-flex">
                            <div className="sellerpersonal-info-drop" >
                              {items}
                            </div>
                          </div>

                        )
                      }) : null
                    }

                    {/* <div className="profile-flex">
                        <div className="profile-image">
                          <img src={disipline} width="5px" alt="loading..."></img>
                        </div>
                        <div>
                          <span className="seller-rating2">Sub discipline <img width="10px" onClick={() => setIsActive11(!isActive11)} src={drop2} alt="loading..."></img></span>
                        </div>
                      </div> */}

                    {/* {isActive11 &&
                        <div className="drop-flex">
                          <div className="sellerpersonal-info-drop" >
                            {item.sellersubDisciplineSpecialization}
                          </div>
                        </div>
                      } */}
                  </div>
                </div>

                <div className="seller-service-pic">

                  <div className="seller-overflow"  >
                    <h3 className="seller-countries-spes" style={{
                   
                    }}>Countries of Specilization</h3>

                    <div class="country-flag">

                      {item.sellerCountrySpecialization !== null ? item.sellerCountrySpecialization.map((items) => {
                        return (
                          <div key={items} style={{ width: "150px" }}>
                            <div key={items} className="flag-div">
                              <div className="seller-flag2">
                                <img className="profile-image3" src={`${process.env.PUBLIC_URL}/countryFlags/${items}.webp`} alt="loading..."></img>
                              </div>
                              <div >
                                <h5 style={{ margin: "10px auto" }}>{items}</h5>
                              </div>

                            </div>
                          </div>
                        )
                      }) : null
                      }

                    </div>
                  </div>

                  <div className="seller-dis-full">
                    <h3>About {item.name}</h3>
                 <p style={{fontSize:"20px"}}>{item.personalDescription !== undefined && item.personalDescription !== null ? item.personalDescription : ''}</p>   
                  </div>

                  <div className="seller-pic-sec-1" >

                    <h3 className="service-head-profile" >Services</h3>

                    {/* <Link to="/seller-all-services"> <button className="seller-personal-viewAll">View All</button> </Link> */}


                   
                    <div style={{ display: "flex", flexWrap: "wrap", width: "100%", marginTop: "40px" }}>



                      <SellerService />
                    </div>




                    {/* <div className="rating-10" id="#reviews">
                        <span className="seller-reviews-heading">Reviews</span><br></br>
                        {(item.reviews !== undefined && item.reviews !== null) ?
                          item.reviews.map((items) => {
                            return (
                              <>
                                <img src={items.user.photo !== undefined ? item.user.photo : illus} width="30px" alt="loading..."></img>
                                <span className="name-profile" >{items.user !== undefined || items.user !== null ? items.user : ''}</span>
                                <img src={rating3} width="40px" alt="loading..."></img><br></br>
                                <span className="seller-review-mobile" >{items.reviews !== undefined || item.reviews !== null ? items.reviews : ''}</span><br></br>
                              </>
                            )
                          }) : (
                            <div className="seller-reviews-heading" style={{ marginTop: "10px" }}>No Reviews</div>
                          )
                        }
                      </div> */}


                  </div>


                </div>

              </div>
              <div class="fot-hide" >
                <Footer />
              </div>

            </div>
          )
        }) : null
      }
    </>
  )
}
export default SellerPersonal;