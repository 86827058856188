import React, { useState } from "react";
import axios from 'axios';
import toast, { Toaster } from 'react-hot-toast';
import ReactMarkdown from 'react-markdown'

const illustration5 = new URL("../../images/error404.webp", import.meta.url)

function Konselo() {

    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [contact, setContact] = useState("");

    const baseUrl = process.env.REACT_APP_BASE_URL_DEV;

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
          const response = await axios.post(`${baseUrl}/api/v1/inputs/student`, {
            name: name,
            email: email,
            contactno:contact
        
            // isEmailVerified: isEmailVerified
          });
          if (response.data.status === "success") {
            console.log(response)
            toast.success("Thanks for submitting form we will contact you soon");
           
          }
        } catch (error) {
          // console.log(error)
          toast.error("Email already link to another account or something went wrong")
        }
      }

    return (
        <div >
            <div className="kounselo kounselo-mobile">

                <span className="span1"> Can't decide the right <span style={{
                    color: "#6262A0"
                }}>counselor ?</span></span><br></br>
                <span className="span2">Drop your details and we will help you out</span>
                <div className="">
                    <img src={illustration5} width="90%" className="" alt="loading..."></img>
                </div>
                <div className="kounselo-form-display2">

                <form onSubmit={handleSubmit}>
                                <input maxLength="15" name="name" type="text" className="kounselo-form name" placeholder="Name*" value={name} onChange={e => setName(e.target.value)} required></input><br></br>

                                <input name="email" type="email" className="kounselo-form email" placeholder="Email*" value={email} onChange={e => setEmail(e.target.value)} required></input><br></br>

                                <input title="Must Contain 10 Digit"  type="text" className="kounselo-form tel" placeholder="Phone Number*" value={contact} onChange={e => setContact(e.target.value)} required></input><br></br>


                                <input type="submit" className="submit" ></input>
                    </form>
                  
                </div>
               
            </div>
            <div className="kounselo kounselo-pc" style={{
                position: "relative",
                top: "-300px"
            }} >
                <span className="span1"> Can't decide the right <span style={{
                    color: "#6262A0"
                }}>counselor ?</span></span><br></br>
                <span className="span2">Drop your details and we will help you out</span>
                <div className="forms-2">
                    <div className="kounselo-form-display">
                        <div data-aos="fade-right" className="kounselo-form-display1">
                            <img src={illustration5} width="400px" className="form-illus" alt="loading..."></img>
                        </div>
                        <div data-aos="fade-left" className="kounselo-form-display2">





                            <form onSubmit={handleSubmit}>
                                <input maxLength="15" name="name" type="text" className="kounselo-form name" placeholder="Name*" value={name} onChange={e => setName(e.target.value)} required></input><br></br>

                                <input name="email" type="email" className="kounselo-form email" placeholder="Email*" value={email} onChange={e => setEmail(e.target.value)} required></input><br></br>

                                <input title="Must Contain 10 Digit"  type="text" className="kounselo-form tel" placeholder="Phone Number*" value={contact} onChange={e => setContact(e.target.value)} required></input><br></br>


                                <input type="submit" className="submit" ></input>
                            </form>
                        </div>
                    </div>
                </div>

            </div>
            <Toaster />
        </div>
    )
}
export default Konselo