import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Footer from "../footer/Footer";
import { useDispatch, useSelector } from 'react-redux';
import { saveCreateServiceData } from './../../Redux/actions/createServiceActionCreators';
import toast, { Toaster } from 'react-hot-toast';


function Service() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [data, setData] = useState({});

    const sellerId = useSelector((state) => state.get_seller_profile_id.seller_id)
    const sellerRole = useSelector((state) => state.get_seller_profile_id.role)
    const userRole = useSelector((state) => state.get_user_id.role)

    useEffect(() => {
        if (!sellerRole && !userRole) {
            navigate('/', {
                replace: true,
                state: {
                    signIn: true,
                    type: "counselor"
                }

            })
        } else if (userRole) {
            toast.error('You are not allowed to open this url');
            navigate('/userLanding');
        }
    }, [navigate, sellerRole, userRole]);

    
    const handleNext = async (e) => {
        e.preventDefault();
        dispatch(saveCreateServiceData({
            serviceCategory: data.category,
            country: data.country,
            forDegrees: data.Degree,
            counselorDisciplineSpecialization: data.Discipline,
            counselorsubDisciplineSpecialization: data.SubDiscipline,
            seller: sellerId
        }));

        if (data.category === 'sopService') {
            navigate('/create-service-sop');
        } else if (data.category === 'profileEvaluation') {
            navigate('/create-service-profile-evaluation');
        } else if (data.category === 'resumeBuilding') {
            navigate('/create-service-resume');
        } else if (data.category === 'essayEditing') {
            navigate('/create-service-essay-editing');
        } else if (data.category === 'scholarshipAssistance') {
            navigate('/create-service-scholarship-assistance');
        } else if (data.category === 'visaAssistance') {
            navigate('/create-service-visa');
        } else if (data.category === 'universityShortlisting') {
            navigate('/create-service-university-shortlisting');
        } else if (data.category === 'completeApplicationSupport') {
            navigate('/create-service-comp-app-support');
        }
    }
    const cancel = (e) => {
        e.preventDefault();
        toast('This will delete your progress');
        navigate('/counselor-dashboard')
    }

    return (
        <div>
            <div class="profile">
                <span class="mainheading createService"> Create Your Service!</span>
                <div class="pageContainer">
                    <div className="overView-flex">
                        <div class="miniheading"> Overview</div>
                        <div class="miniheading2 "> Step 1/2</div>
                    </div>
                    <hr class="line"></hr>

                    <div className="form-flex">
                        <div class="label2">
                            <label >Service category</label>
                        </div>
                        <div className="form-input">

                            <select name="serviceCategory" required placeholder="Service Category" className="profile-info3" value={data.category} onChange={e => setData({ ...data, category: e.target.value })}>
                                <option value="" disabled selected>Select a Category</option>
                                <option value="sopService">SOP</option>
                                <option value="profileEvaluation">Profile Evaluation</option>
                                <option value="resumeBuilding">Resume Building</option>
                                <option value="essayEditing">Essay Editing</option>
                                <option value="scholarshipAssistance">Scholorship Assistance</option>
                                <option value="visaAssistance">Visa</option>
                                <option value="universityShortlisting">University Shortlisting</option>
                                <option value="completeApplicationSupport">Complete Application Support</option>
                            </select><br></br>
                        </div>
                    </div>
                    <div className="form-flex">
                        <div class="label2">
                            <label >Country applicable</label>
                        </div>
                        <div className="form-input">

                            <select name="countryApplicable" required className="profile-info3" value={data.country} onChange={e => setData({ ...data, country: e.target.value })}>

                                <option value="" disabled selected>Select a Country</option>
                                <option value="Austria">Austria</option>
                                <option value="Australia">Australia</option>
                                <option value="Belarus">Belarus</option>
                                <option value="Belgium">Belgium</option>
                                <option value="Belize">Belize</option>
                                <option value="Bosnia & Herzegovina">Bosnia & Herzegovina</option>
                                <option value="Bulgaria">Bulgaria</option>
                                <option value="Canada">Canada</option>
                                <option value="China">China</option>
                                <option value="Cyprus">Cyprus</option>
                                <option value="Denmark">Denmark</option>
                                <option value="Europe">Europe</option>
                                <option value="France">France</option>
                                <option value="Finland">Finland</option>
                                <option value="Germany">Germany</option>
                                <option value="Georgia">Georgia</option>
                                <option value="Greece">Greece</option>
                                <option value="Hong Kong">Hong Kong</option>
                                <option value="Italy">Italy</option>
                                <option value="Ireland">Ireland</option>
                                <option value="Japan">Japan</option>
                                <option value="Kazakhstan">Kazakhstan</option>
                                <option value="Kyrgyzstan">Kyrgyzstan</option>
                                <option value="Latvia">Latvia</option>
                                <option value="Luxembourg">Luxembourg</option>
                                <option value="Malaysia">Malaysia</option>
                                <option value="Maritius">Maritius</option>
                                <option value="Netherlands">Netherlands</option>
                                <option value="New Zealand">New Zealand</option>
                                <option value="Norway">Norway</option>
                                <option value="Philippines">Philippines</option>
                                <option value="Poland">Poland</option>
                                <option value="Portugal">Portugal</option>
                                <option value="Russia">Russia</option>
                                <option value="Singapore">Singapore</option>
                                <option value="Spain">Spain</option>
                                <option value="Solvenia">Solvenia</option>
                                <option value="South Africa">South Africa</option>
                                <option value="SouthKorea">South Korea</option>
                                <option value="Switzerland">Switzerland</option>
                                <option value="Thailand">Thailand</option>
                                <option value="Turkey">Turkey</option>
                                <option value="Ukraine">Ukraine</option>
                                <option value="UAE">UAE</option>
                                <option value="UK">United Kingdom</option>
                                <option value="USA">USA</option>

                            </select><br></br>
                        </div>
                    </div>
                    <div className="form-flex">
                        <div class="label2">
                            <label >For Degrees</label>
                        </div>
                        <div className="form-input">

                            <select name="forDegrees" required className="profile-info3" value={data.Degree} onChange={e => setData({ ...data, Degree: e.target.value })}>

                                <option value="" disabled selected>Select a Degree</option>
                                <option value="Bachelor">Bachelor</option>
                                <option value="Master">Master</option>
                                <option value="MBA">MBA</option>
                                <option value="PhD">PhD</option>
                                <option value="PostDoc">Postdoc</option>
                            </select><br></br>
                        </div>
                    </div>
                    <div className="form-flex">
                        <div class="label2">
                            <label >For Discipline</label>
                        </div>
                        <div className="form-input">

                            <select name="counselorDisciplineSpecialization" required className="profile-info3" value={data.Discipline} onChange={e => setData({ ...data, Discipline: e.target.value })}>

                                <option value="" disabled selected>Select a Discipline</option>
                                <option value="Applied_Sciences_Profession">Applied Sciences & Profession</option>
                                <option value="Art">Art</option>
                                <option value="Bussiness">Bussiness</option>
                                <option value="Engineering_Technology">Engineering Technology</option>
                                <option value="Economics">Economics</option>
                                <option value="Environmental_Sciences">Environmental_Sciences</option>
                                <option value="Humanities">Humanities</option>
                                <option value="Internship_English">Internship + English</option>
                                <option value="Law">Law</option>
                                <option value="Life_Sciences_and_health">Life Sciences and Medical Health</option>
                                <option value="Management">Management</option>
                                <option value="Natural_Sciences">Natural Sciences</option>
                                <option value="AgricultureProduction">Agriculture Production</option>
                                <option value="AgricultureSports">Agriculture Sports</option>
                            </select><br></br>
                        </div>
                    </div>
                    <div className="form-flex">
                        <div class="label2">
                            <label >Sub-Disciplines</label>
                        </div>
                        <div name="counselorsubDisciplineSpecialization" className="form-input" value={data.SubDiscipline} onChange={e => setData({ ...data, SubDiscipline: e.target.value })}>
                            <select required className="profile-info3">
                                <option value="" disabled selected>Select a Subdiscipline</option>
                                {data.Discipline === 'Applied_Sciences_Profession' && (
                                    <>
                                        <option value="Journalism and Mass Communications" >Journalism and Mass Communications</option>
                                        <option value="Fashion, Textiles and Luxury goods" >Fashion, Textiles and Luxury goods</option>
                                        <option value="Social Work" >Social Work</option>
                                        <option value="Information Science" >Information Science</option>
                                        <option value="Agriculture" >Agriculture</option>
                                        <option value="Forestry, Animal &  Related Sciences" >Forestry, Animal &  Related Sciences</option>
                                        <option value="Family and Consumer Science" >Family and Consumer Science</option>
                                        <option value="Military Science" >Military Science</option>
                                    </>
                                )}

                                {data.Discipline === 'Art' && (
                                    <>
                                        <option value="Art & Art History" >Art & Art History</option>
                                        <option value="Design" >Design</option>
                                        <option value="Animation" >Animation</option>
                                    </>
                                )}

                                {data.Discipline === 'Bussiness' && (
                                    <>
                                        <option value="Public Administration" >Public Administration</option>
                                        <option value="Business  Administration" >Business  Administration</option>
                                        <option value="Business & Technology" >Business & Technology</option>
                                        <option value="Entrepreneurship" >Entrepreneurship</option>
                                    </>
                                )}

                                {data.Discipline === 'Economics' && (
                                    <>
                                        <option value="Accounting" >Accounting</option>
                                        <option value="Econometrics" >Econometrics</option>
                                        <option value="Finance" >Finance</option>
                                    </>
                                )}

                                {data.Discipline === 'Engineering_Technology' && (
                                    <>
                                        <option value="Engineering Physics" >Engineering Physics</option>
                                        <option value="Mechanical Engineering" >Mechanical Engineering</option>
                                        <option value="Chemical Engineering" >Chemical Engineering</option>
                                        <option value="Electrical Engineering" >Electrical Engineering</option>
                                        <option value="Engineering & Business" >Engineering & Business</option>
                                        <option value="Materials Engineering" >Materials Engineering</option>
                                        <option value="Bio & Biomedical Engineering" >Bio & Biomedical Engineering</option>
                                        <option value="Energy Engineering" >Energy Engineering</option>
                                        <option value="Environmental Engineering" >Environmental Engineering</option>
                                        <option value="Aerospace, Aeronautical & Marine Engineering" >Aerospace, Aeronautical & Marine Engineering</option>
                                        <option value="Civil Engineering, Architecture & Construction" >Civil Engineering, Architecture & Construction</option>
                                        <option value="Electronics & Embedded Technology" >Electronics & Embedded Technology</option>
                                    </>
                                )}

                                {data.Discipline === 'Environmental_Sciences' && (
                                    <>
                                        <option value="Environmental Biotechnology" >Environmental Biotechnology</option>
                                        <option value="Environmental Impacts & Human Health" >Environmental Impacts & Human Health</option>
                                        <option value="Hydrology & Water Management" >Hydrology & Water Management</option>
                                        <option value="Ecology, Biodiversity & Conservation" >Ecology, Biodiversity & Conservation</option>
                                        <option value="Environmental Economics" >Environmental Economics</option>
                                        <option value="Geo-information" >Geo-information</option>
                                        <option value="Sustainable Management, Policy & Governance" >Sustainable Management, Policy & Governance</option>
                                        <option value="Climate Studies & Meteorology" >Climate Studies & Meteorology</option>
                                        <option value="Environmental Chemistry & Toxicology" >Environmental Chemistry & Toxicology</option>
                                        <option value="Soil Science & Soil Ecology" >Soil Science & Soil Ecology</option>
                                    </>
                                )}

                                {data.Discipline === 'Humanities' && (
                                    <>
                                        <option value="Film and Theatre Studies" >Film and Theatre Studies</option>
                                        <option value="Philosophy" >Philosophy</option>
                                        <option value="Music" >Music</option>
                                        <option value="Language, Literature and Cultural Studies" >Language, Literature and Cultural Studies</option>
                                        <option value="Religious Studies and Theology" >Religious Studies and Theology</option>
                                    </>
                                )}

                                {data.Discipline === 'Internship_English' && (
                                    <>
                                        <option value="Internship + English" >Internship + English</option>
                                    </>
                                )}

                                {data.Discipline === 'Law' && (
                                    <>
                                        <option value="Criminal Law and Criminology" >Criminal Law and Criminology</option>
                                        <option value="Public Law" >Public Law</option>
                                        <option value="Civil & Private Law" >Civil & Private Law</option>
                                        <option value="International Law" >International Law</option>
                                        <option value="European Law" >European Law</option>
                                    </>
                                )}

                                {data.Discipline === 'Life_Sciences_and_health' && (
                                    <>
                                        <option value="Life Sciences" >Life Sciences</option>
                                        <option value="Medicine & Health" >Medicine & Health</option>
                                        <option value="Physiotherapy" >Physiotherapy</option>
                                        <option value="Human Medicine" >Human Medicine</option>
                                        <option value="Pharmacy" >Pharmacy</option>
                                        <option value="Veterinary Medicine" >Veterinary Medicine</option>
                                        <option value="Dentistry" >Dentistry</option>
                                        <option value="Nutrition and Dietetics" >Nutrition and Dietetics</option>
                                        <option value="Public Health" >Public Health</option>
                                        <option value="Biomedicine" >Biomedicine</option>
                                        <option value="Nursing" >Nursing</option>
                                    </>
                                )}

                                {data.Discipline === 'Management' && (
                                    <>
                                        <option value="Hospitality and Tourism  Management" >Hospitality and Tourism  Management</option>
                                        <option value="Marketing studies" >Marketing studies</option>
                                        <option value="Supply Chain Management & Logistics" >Supply Chain Management & Logistics</option>
                                        <option value="Management & Organisation" >Management & Organisation</option>
                                        <option value="Human Resource Management" >Human Resource Management</option>
                                        <option value="Project Management" >Project Management</option>
                                    </>
                                )}

                                {data.Discipline === 'Natural_Sciences' && (
                                    <>
                                        <option value="Geographyt" >Geography</option>
                                        <option value="Astronomy" >Astronomy</option>
                                        <option value="Chemistry" >Chemistry</option>
                                        <option value="Mathematics" >Mathematics</option>
                                        <option value="Biology" >Biology</option>
                                        <option value="Earth Sciences" >Earth Sciences</option>
                                        <option value="Physics" >Physics</option>
                                    </>
                                )}

                                {data.Discipline === 'Social_Sciences' && (
                                    <>
                                        <option value="Education" >Education</option>
                                        <option value="Psychology" >Psychology</option>
                                        <option value="Communications" >Communications</option>
                                        <option value="Gender Studies" >Gender Studies</option>
                                        <option value="Political Science & International Relations" >Political Science & International Relations</option>
                                        <option value="Anthropology" >Anthropology</option>
                                        <option value="Ethnic Studies" >Ethnic Studies</option>
                                        <option value="History" >History</option>
                                        <option value="Behavioral Science" >Behavioral Science</option>
                                        <option value="Sociology" >Sociology</option>
                                        <option value="Nursing" >Development & Social Policy and Planning</option>
                                    </>
                                )}

                            </select><br></br>
                        </div>
                    </div>
                    <div className="button-flex">
                        <div className="button-cancel-flex" >

                            <button Class="button-cancel" onClick={cancel}>CANCEL</button>
                        </div>
                        <div className="button-save-flex">
                            <div>
                                <button Class="button-savepublish" onClick={handleNext} > NEXT</button>
                            </div>
                        </div>
                    </div>
                    <div className="button-mobile-forms">
                        <button Class="button-savepublish" onClick={handleNext} > NEXT</button>
                        <button Class="button-cancel" onClick={cancel}>CANCEL</button>
                    </div>
                </div>

            </div>
            <Footer />
            <Toaster />
        </div>
    )
}


export default Service;



