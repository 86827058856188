import React, { useState, useEffect } from "react";
import axios from 'axios';
import { useSelector } from "react-redux";
import "../../styles/popularProfile.css"
import Flickity from 'react-flickity-component'
import "../../styles/userLanding.css"
import DashCards2 from "./dash-card2";

const flickityOptions = {
    initialIndex: 1,
    wrapAround: true,
    freeScroll: true,
    pageDots: true,
    autoPlay:"1000s",
    pauseAutoPlayOnHover: false
}
function DashSlider4() {
    const [data, setData] = useState('');
    const sellerId = useSelector((state) => state.get_seller_profile_id.seller_id);

    useEffect(() => {
        const baseUrl = process.env.REACT_APP_BASE_URL_DEV;
        const SellerOngoingOrders = async () => {
            try {
                const response = await axios.get(`${baseUrl}/api/v1/orders`)
                // api incomplete
                setData(response.data.data);
            }catch (err) {
                // console.log(err);
            }
        }
        SellerOngoingOrders();
    }, [sellerId])

    return (
        <div>

            <div class="container-fluid">
                {/* <div className="view-all3">
                    view all
                </div> */}



                <Flickity

                    className={'carousel8'} // default ''
                    elementType={'div'} // default 'div'
                    options={flickityOptions} // takes flickity options {}
                    disableImagesLoaded={true} // default false
                    reloadOnUpdate={true} // default false
                    static={true} // default false
                >
                    <DashCards2 data={data} />
                </Flickity>
            </div>
        </div>
    )
}
export default DashSlider4