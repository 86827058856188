import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Footer from "../footer/Footer";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import toast, { Toaster } from 'react-hot-toast';
import { removeCreateServiceData } from './../../Redux/actions/createServiceActionCreators';

function CompleteAppSupport() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [title, setTitle] = useState("");
  const [desc, setDesc] = useState("");
  const [pricing, setPricing] = useState("");
  const [delivery, setDelivery] = useState("");
  const [No_Session, set_Session] = useState("");
  const [Requirement, set_Requirement] = useState("");
  const [isProfileEvaluation, setIsProfileEvaluation] = useState(false);
  const [isEssayEditing, setIsEssayEditing] = useState(false);
  const [isLOR, setIsLOR] = useState(false);
  const [isInterviewPrep, setIsInterviewPrep] = useState(false);
  const [isVisa, setIsVisa] = useState(false);
  const [isScholorship, setIsScholorship] = useState(false);
  const [isprogramShortlist, setIsprogramShortlist] = useState(false);
  const [isUnivShort, setIsUnivShort] = useState(false);

  const data = useSelector((state) => state.create_service_data)
  const sellerRole = useSelector((state) => state.get_seller_profile_id.role)
  const userRole = useSelector((state) => state.get_user_id.role)
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!sellerRole && !userRole) {
      navigate('/', {
        replace: true,
        state: {
          signIn: true,
          type: "counselor"
        }

      })
    } else if (userRole) {
      toast.error('You are not allowed to open this url');
      navigate('/userLanding');
    }
  }, [navigate, sellerRole, userRole])


  const createCompAppSupport = {
    title_Service: title,
    description: desc,
    pricing: pricing,
    productDelivery: delivery,
    numberOfSession: No_Session,
    additionalRequirement: Requirement,
    universityShortlisting: isUnivShort,
    programShortlisting: isprogramShortlist,
    essayEditing: isEssayEditing,
    profileEvaluation: isProfileEvaluation,
    LOR: isLOR,
    interviewPreparation: isInterviewPrep,
    visaAssistance: isVisa,
    scholarshipAssisstance: isScholorship
  }

  const baseUrl = process.env.REACT_APP_BASE_URL_DEV;

  const handleCancel = (e) => {
    e.preventDefault();
    toast('This may delete your progress');
    navigate('/counselor-dashboard');
  }

  const handleSavePublish = async (e) => {
    e.preventDefault();
    try {
      const combinedData = { ...data, ...createCompAppSupport, serviceStatus: 'Hosted' };
      const res = await axios.post(`${baseUrl}/api/v1/services/create-CASupport`, combinedData);
      if (res.data.status === 'success') {
        toast.success('Service Created Succesfully');
        dispatch(removeCreateServiceData({}));
      }
    } catch (err) {
      // console.log(err);
      toast.error('Internal Server Error. Try again Later');

    }
    navigate('/counselor-dashboard');
  }

  const handleSave = async (e) => {
    e.preventDefault();
    try {
      const combinedData = { ...data, ...createCompAppSupport, serviceStatus: 'Draft' };
      const res = await axios.post(`${baseUrl}/api/v1/services/create-CASupport`, combinedData);
      if (res.data.status === "success") {
        toast.success('Service Drafted Succesfully! You can host it anytime from your dashboard.');
        dispatch(removeCreateServiceData({}));
      }
    } catch (err) {
      // console.log(err);
      toast.error('Internal Server Error. Please Try again Later');

    }
    navigate('/counselor-dashboard');
  };

  return (

    <div>
      <div class="profile">
        <div className="head-forms">
          <label className="mainheading">Complete Application Support</label>
        </div>
        <form onSubmit={handleSavePublish}>
          <div class="pageContainer">
          <div className="overView-flex">
                    <div class="miniheading"> Create Your Servcie!</div>
                    <div class="miniheading2 "> Step 2/2</div>
                    </div>
            <hr class="line"></hr>
            <div className="form-flex">
              <div class="label2">
                <label >Title Of Service<span className="required">*</span></label>
              </div>
              <div className="form-input">
                <input name="title" type="text" class="profile-info2 " minLength="5" maxLength="31" placeholder="Maximum 31 chars or 5 words" id="package" value={title} onChange={e => setTitle(e.target.value)} required /><br></br>
              </div>
            </div>

            <div className="form-flex">
              <div class="label2">
                <label >Description<span className="required">*</span></label>
              </div>
              <div className="form-input">
                <textarea name="desc" type="text" class="profile-info2" maxLength="500" placeholder="Add details about your service! (100 words)" id="description" value={desc} onChange={e => setDesc(e.target.value)} /><br></br>
              </div>
            </div>

            <div className="form-flex">
              <div class="label2">
                <label >Pricing<span className="required">*</span></label>
              </div>
              <div className="form-input">
                <input name="pricing" style={{paddingLeft:"30px"}}  type="number" min={1} class="profile-info2 pricing " placeholder="" id="package" value={pricing} onChange={e => setPricing(e.target.value)} required /><br></br>
              </div>
            </div>
            <div className="form-flex">
              <div class="label2">
                <label >Duration<span className="required">*</span></label>
              </div>
              <div className="form-input">
                <input name="delivery" type="number" min={1} class="profile-info2 " placeholder="No. of Days" value={delivery} onChange={e => setDelivery(e.target.value)} id="package" required /><br></br>
              </div>
            </div>


            <hr class="line2"></hr>
            <div className="form-flex">
              <div class="label2">
                <label >Number Of Session<span className="required">*</span></label>
              </div>
              <div className="form-input">
                <input name="session" type="number" min={1} class="profile-info2 " placeholder="Number Of Session" id="package" value={No_Session} onChange={e => set_Session(e.target.value)} required /><br></br>
              </div>
            </div>
            <div className="form-flex2">
              <div class="label3">
                <label >University Shortlisting</label>
              </div>
              <div className="form-input">
                <input type="checkbox" class="check-box " placeholder="$" name="package" id="package" value={isUnivShort} onChange={() => setIsUnivShort(true)} /><br></br>
              </div>
            </div>

            <div className="form-flex2">
              <div class="label3">
                <label >Program Shortlisting</label>
              </div>
              <div className="form-input">
                <input type="checkbox" class="check-box " placeholder="$" name="package" id="package" value={isprogramShortlist} onChange={() => setIsprogramShortlist(true)} /><br></br>
              </div>
            </div>

            <div className="form-flex2">
              <div class="label3">
                <label >Profile Evaluation</label>
              </div>
              <div className="form-input">
                <input type="checkbox" class="check-box " placeholder="$" name="package" id="package" value={isProfileEvaluation} onChange={() => setIsProfileEvaluation(true)} /><br></br>
              </div>
            </div>
            <div className="form-flex2">
              <div class="label3">
                <label >Essay Editing</label>
              </div>
              <div className="form-input">
                <input type="checkbox" class="check-box " placeholder="$" name="package" id="package" value={isEssayEditing} onChange={() => setIsEssayEditing(true)} /><br></br>
              </div>
            </div>

            <div className="form-flex2">
              <div class="label3">
                <label >LOR</label>
              </div>
              <div className="form-input">
                <input type="checkbox" class="check-box " placeholder="$" name="package" id="package" value={isLOR} onChange={() => setIsLOR(true)} /><br></br>
              </div>
            </div>

            <div className="form-flex2">
              <div class="label3">
                <label >Interview Preparation</label>
              </div>
              <div className="form-input">
                <input type="checkbox" class="check-box " placeholder="$" name="package" id="package" value={isInterviewPrep} onChange={() => setIsInterviewPrep(true)} /><br></br>
              </div>
            </div>

            <div className="form-flex2">
              <div class="label3">
                <label >Visa</label>
              </div>
              <div className="form-input">
                <input type="checkbox" class="check-box " placeholder="$" name="package" id="package" value={isVisa} onChange={() => setIsVisa(true)} /><br></br>
              </div>
            </div>

            <div className="form-flex2">
              <div class="label3">
                <label >Scholarship</label>
              </div>
              <div className="form-input">
                <input type="checkbox" class="check-box " placeholder="$" name="package" id="package" value={isScholorship} onChange={() => setIsScholorship(true)} /><br></br>
              </div>
            </div>

            <div className="form-flex">
              <div class="label2">
                <label >Additional Requirement for client</label>
              </div>
              <div className="form-input">
                <input name="requirement" type="text" class="profile-info2" id="package" placeholder="Add open-ended question /query /requirement from the buyer" value={Requirement} onChange={
                  e => set_Requirement(e.target.value)} /><br></br>
              </div>

            </div>
            <div className="button-flex">
              <div className="button-cancel-flex" >

                <button Class="button-cancel" onClick={handleCancel}>CANCEL</button>
              </div>
              <div className="button-save-flex">
                <div>
                  <button Class="button-save" onClick={handleSave}>SAVE</button>
                </div>
                <div>
                  <button type="submit" name="savePublish" Class="button-savepublish" >SAVE & PUBLISH</button>
                </div>
              </div>
            </div>
            <div className="button-mobile-forms">
              <div className="button-cancel-flex" >
                <button Class="button-save" onClick={handleSave}>SAVE</button>

              </div>
              <div className="button-save-flex">
                <div>
                  <button type="submit" name="savePublish" Class="button-savepublish" >SAVE & PUBLISH</button>
                </div>
                <div>

                  <button Class="button-cancel" onClick={handleCancel}>CANCEL</button>

                </div>
              </div>
            </div>
          </div>
        </form>
        <Footer />
      </div>
      <Toaster/>
    </div>

  )
}
export default CompleteAppSupport;