import React, { useEffect, useState } from "react";
import axios from "axios";
import Flickity from 'react-flickity-component'
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { SellerCardsAPICall } from "../../Redux/actions/GetAllSellerCardsActionCreator";
import { getSellerIdFromSellerCard } from "../../Redux/actions/GetSellerID_UserInterfaceActionCreator";
const rating5 = new URL("../../images/1star.webp", import.meta.url)
const noprofile = new URL("../../images/noProfilepic.webp", import.meta.url)

function UserLandingCards() {
  const flickityOptions = {
    initialIndex: 1,

    pageDots: false,

  }
  const baseUrl = process.env.REACT_APP_BASE_URL_DEV;
  const [data, setData] = useState([]);
  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await axios.get(`${baseUrl}/api/v1/sellers/getAllSellerCards`);
      setData(response.data.data.getAllSeller.reverse());

    } catch (error) {
    }
  };

  const navigate = useNavigate();
  const dispatch = useDispatch()
  useEffect(() => {
    SellerCardsAPICall('getAllSeller', dispatch);
  }, []);

  const ViewSellerProfile = async (e, id) => {
    e.preventDefault();
    try {
      if (id) navigate('/counselor-profile', dispatch(getSellerIdFromSellerCard(id)));
    } catch (err) {
      alert('No such profile found');
      // console.log(err);
    }
  };

  const ChatWithMe = async (e, id) => {
    e.preventDefault();
    navigate('/chat');
  }

  return (
    <>
      {
        data.length !== 0 ?
          data.map((item) => {
            return (


              <div key={item._id} class="carousel-cell-top " >
                  <div className='service-card-main'>
                      <div className="service-card">

                        <img width="130px" height="130px" style={{ borderRadius: "50%" }} src={item.photo !== undefined ? `https://kounselodata.s3.ap-south-1.amazonaws.com/${item.photo}` : noprofile} alt="loading..."></img>

                      </div>
                    </div>


                <span className="cardName">{item.name} </span>
                <br></br>
                <div className="" style={{
                  marginTop: "10px",
                  fontSize: "14px"

                }}>
            
                </div><br></br>
                <div>

                </div>
                <div style={{
                  textAlign: "center"
                }}>
                 <div style={{margin: "70px auto 0px auto",width: "95%",height:"70px", textAlign: "center" }}>
                          {(item.personalDescription.length > 5) ? item.personalDescription.slice(0, 80) + "..." : item.personalDescription}

                        </div>
                  <div>


                    <div className="service-S-from">
                      Services from
                    </div><br></br>
                    <span className="INR9" style={{
                      color: "black"
                    }}>INR</span><span className="INR9 INR7" style={{
                      fontWeight: "bold"
                    }} >{item.initialPricing}/-</span>
                  </div>

                  <div style={{ width: "100%", textAlign: "center" }}>
                    <Link to={item.TopmateLink}> <button className="view-btn" >VIEW PROFILE</button></Link>

                  </div>

                  {/* <button style={{
                  position: "relative",
                  left: "30px",
                  top: "10px",
                  border: "none",
                  color: "white",
                  fontSize: "10px",
                  padding: "4px",
                  width: "40%", background: "linear-gradient(90deg, #7176C4 0.15%, #7D7EC7 99.78%)",
                  fontWeight: "bold"
                }} onClick={e => ChatWithMe(e, item._id)}>CHAT</button>  */}
                </div>
              </div>
            )
          }) : <div></div>
      }
    </>
  )
}
export default UserLandingCards


