import React, {useState, useEffect } from "react"
import { useNavigate} from "react-router-dom"
import Footer from "../footer/Footer";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import toast, {Toaster} from 'react-hot-toast';
import { removeCreateServiceData } from './../../Redux/actions/createServiceActionCreators';

function ProfileEval(){
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [title, setTitle] = useState("");
  const [desc, setDesc] = useState("");
  const [pricing, setPricing] = useState("");
  const [delivery, setDelivery] = useState("");
  const [No_Session, set_Session] = useState("");
  const [Requirement, set_Requirement] = useState("");
  const data = useSelector((state) => state.create_service_data)
  const sellerRole = useSelector((state) => state.get_seller_profile_id.role)
  const userRole = useSelector((state) => state.get_user_id.role)
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
      if(!sellerRole && !userRole){
          navigate('/',{replace : true, 
              state : {
                  signIn : true,
                  type : "counselor"
              }
          
          })
      }else if(userRole){
          toast.error('You are not allowed to open this url');
          navigate('/userLanding');
      }
  }, [navigate, sellerRole, userRole])

  const createProfileEvaluation = {
    title_Service: title,
    description: desc,
    pricing: pricing,
    productDelivery: delivery,
    numberOfSession: No_Session,
    additionalRequirement: Requirement,
  };

  const baseUrl = process.env.REACT_APP_BASE_URL_DEV;
  
  const handleCancel = (e) => {
    e.preventDefault();
    toast('This may delete your progress');
    navigate('/counselor-dashboard');
  }

  const handleSavePublish = async (e) => {
    e.preventDefault();
    try {
      let combinedData = { ...data, ...createProfileEvaluation, serviceStatus:'Hosted'};
      const res = await axios.post(`${baseUrl}/api/v1/services/create-ProfileEvaluation`, combinedData);
      if(res.data.status === 'success'){
        toast.success('Service Created Succesfully');
        dispatch(removeCreateServiceData({}));
    } 
    }catch (err) {
      toast.error('Internal Server Error. Try again Later');
      // console.log(err);
    }
    navigate('/counselor-dashboard');
  }

  const handleSave = async (e) => {
    e.preventDefault();
    try {
      const combinedData = { ...data, ...createProfileEvaluation, serviceStatus:'Draft'};
      const res = await axios.post(`${baseUrl}/api/v1/services/create-ProfileEvaluation`, combinedData);
      if(res.data.status === 'success'){
        toast.success('Service Drafted Succesfully! You can host it anytime from your dashboard');
        dispatch(removeCreateServiceData({}));
    }
    } catch (err) {
      toast.error('Internal Server Error. Try again Later');
      // console.log(err);
    }
    navigate('/counselor-dashboard');
  };

  return(
        <div>
        <div class="profile">
          <div className="head-forms">
            <label className="mainheading">Profile Evaluation</label>
          </div>
          <form onSubmit={handleSavePublish}>
          <div class="pageContainer">
          <div className="overView-flex">
                    <div class="miniheading"> Create Your Servcie!</div>
                    <div class="miniheading2 "> Step 2/2</div>
                    </div>
            <hr class="line"></hr>
            <div className="form-flex">
              <div class="label2">
                <label >Title Of Service    <span className="required">*</span></label>
              </div>
              <div className="form-input">
              <input name='name' type="text" required  minLength="5" maxLength="31" class="profile-info2" placeholder="Maximum 31 chars or 5 words" id="package" value={title} onChange={e => setTitle(e.target.value)} /><br></br>
              </div>
            </div>
    
            <div className="form-flex">
              <div class="label2">
                <label >Description  <span className="required">*</span></label>
              </div>
              <div className="form-input">
              <textarea name="desc" type="text" required  maxLength="500" class="profile-info2" placeholder="Add details about your service! (100 words)" id="description" value={desc} onChange={e => setDesc(e.target.value)} /><br></br>
              </div>
            </div>
    
            <div className="form-flex">
              <div class="label2">
                <label >Pricing  <span className="required">*</span></label>
              </div>
              <div className="form-input">
              <input name="price" style={{paddingLeft:"30px"}}  type="number" min = {1} class="profile-info2 pricing" required placeholder="" id="price" value={pricing} onChange={e => setPricing(e.target.value)} /><br></br>
              </div>
            </div>
            <div className="form-flex">
              <div class="label2">
                <label >Duration  <span className="required">*</span></label>
              </div>
              <div className="form-input">
              <input name="delivery" type="text" required placeholder = "No. of Days" class="profile-info2" id="days" value={delivery} onChange={e => setDelivery(e.target.value)} /><br></br>
              </div>
            </div>
    
    
            <hr class="line2"></hr>
            <div className="form-flex">
              <div class="label2">
                <label >Number Of Session <span className="required">*</span></label>
              </div>
              <div className="form-input">

              <input name="no-of-session" type="number" min = {1} required class="profile-info2" placeholder="Enter the number of sessions required" id="no-of-session" value={No_Session} onChange={e => set_Session(e.target.value)}/><br></br>

              </div>
            </div>
    
        
            <div className="form-flex">
              <div class="label2">
                <label >Additional Requirement for client</label>
              </div>
              <div className="form-input">
              <input name="requirement$" type="text" placeholder = "Add open-ended question /query /requirement from the buyer" id="additional-field" class="profile-info2" value = {Requirement} onChange = {
            e => set_Requirement(e.target.value)} /><br></br> 
              </div>
    
            </div>
            <div className="button-flex">
        <div className="button-cancel-flex" >

          <button Class="button-cancel" onClick = {handleCancel}>CANCEL</button>
        </div>
        <div className="button-save-flex">
      <div>
          <button Class="button-save" onClick = {handleSave}>SAVE</button>
          </div>
        <div>
        <button type="submit" name = "savePublish"Class="button-savepublish" >SAVE & PUBLISH</button>
          </div>
        </div>
      </div>
      <div className="button-mobile-forms">
        <div className="button-cancel-flex" >
        <button Class="button-save" onClick = {handleSave}>SAVE</button>

        </div>
        <div className="button-save-flex">
      <div>
      <button type="submit" name = "savePublish"Class="button-savepublish" >SAVE & PUBLISH</button>
          </div>
        <div>
          
        <button Class="button-cancel" onClick = {handleCancel}>CANCEL</button>

          </div>
        </div>
      </div>
          </div>
          </form>
           <Footer/>
        </div>
      <Toaster/>
      </div>
    )
}
export default ProfileEval