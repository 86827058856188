import React from "react";
import "../../styles/popularProfile.css"
import Flickity from 'react-flickity-component'
import "../../styles/userLanding.css"
import PopularSellerCard from "../home/popular-seller-card";
import { Link } from "react-router-dom";

const flickityOptions = {
    initialIndex: 1,
    wrapAround: true,
    freeScroll: true,
    pageDots: true,
    autoPlay:"1000s",   
    pauseAutoPlayOnHover: false
     }
function UserLandingSlider(){
    
    return(
        <div>
              
              <div class="">
               <div className="view-all2">
               <span className="view-text"><Link style={{
                textDecoration:"none",
                color:"#8D8FD1"
               }} to ="/all-top-counselor">View All </Link></span> 
               </div>
          
               
        <Flickity

      className={'carousel3 slider6'} // default ''
      elementType={'div'} // default 'div'
      options={flickityOptions} // takes flickity options {}
      disableImagesLoaded={true} // default false
      reloadOnUpdate={true} // default false
      static={false} // default false
    > 
     <PopularSellerCard/>
    
  
      
    </Flickity>
    </div>
        </div>
    )
}
export default UserLandingSlider