import React, { useEffect,useState } from "react";
import Footer from "../footer/Footer";

const order = new URL("../../images/Other 12.webp", import.meta.url);
const photo = new URL("../../images/Group336265.webp", import.meta.url);

function OrderCounselor() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    
    const [showOrder,setOrder]=useState("Active")
    return (
        <div>
            
            <div class="maindiv1">
               
                <div class="outerback-1">
                    
                    {/* <img src={down} alt="anything you like" class="downpic" style={{
                        position: "absolute",
                        right: "1300px",
                        top: "1000px",
                    }}></img> */}
                    <div class="text-order">
                       <div class="my-order-head"> MANAGE ORDERS</div> <div><img src={order} alt="anything you like" class="orderpic" width="70px"></img></div>
                    </div>
                    <div class="switchbtn">
                        <div>
                        
                        <button onClick={()=>setOrder("Active")} class={`butt ${showOrder === "Active" ? "active2" : ""}`}>Active</button>
                        </div>
                        <div>
                        <button onClick={()=>setOrder("Completed")}  class={`butt ${showOrder === "Completed" ? "active2" : ""}`}>Completed</button>
                        </div>
                        <div>
                        
                        <button onClick={()=>setOrder("Cancled")}  class={`butt ${showOrder === "Cancled" ? "active2" : ""}`}>Cancelled</button>
                        </div>
                    </div>
                </div>
                { showOrder === "Active" &&(

                <div class="warpper">
                    <table id="mytable" cellSpacing="60px" cellPadding="40px" bgcolor="white" class="t-style" style={{
                        margin: "0 auto ",
                        position: "relative",
                        top: "-250px",
                    }}>
                        <thead>
                            <tr>
                                <th></th>
                                <th></th>
                                <th>Counselor</th>
                                <th>Services</th>
                                <th>Order on</th>
                                <th>Due on</th>
                                <th>Amount</th>
                                <th>Status</th>
                                <th className="wide-cell">Order id</th>
                                <th>Invoice</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>1.</td>
                                <td><img src={photo} alt="anything you like"></img></td>
                                <td className="wide-cell">Vansh Kathuria</td>
                                <td className="wide-cell">Sop writting</td>
                                <td className="wide-cell">2 nov 2022</td>
                                <td className="wide-cell">2 jan 2023</td>
                                <td>4999/-</td>
                                <td>pending</td>
                                <td>657089</td>
                                <td><button class="button">Cancel order</button></td>
                            </tr>
                            <tr>
                                <td>2.</td>
                                <td><img src={photo} alt="anything you like"></img></td>
                                <td className="wide-cell">Vansh Kathuria</td>
                                <td className="wide-cell">Sop writting</td>
                                <td className="wide-cell">2 nov 2022</td>
                                <td className="wide-cell">2 jan 2023</td>
                                <td>4999/-</td>
                                <td>pending</td>
                                <td>657089</td>
                                <td><button class="button">Cancel order</button></td>
                            </tr>
                            <tr>
                                <td>3.</td>
                                <td><img src={photo} alt="anything you like"></img></td>
                                <td className="wide-cell">Vansh Kathuria</td>
                                <td className="wide-cell">Sop writting</td>
                                <td className="wide-cell">2 nov 2022</td>
                                <td className="wide-cell">2 jan 2023</td>
                                <td>4999/-</td>
                                <td>pending</td>
                                <td>657089</td>
                                <td><button class="button">Cancel order</button></td>
                            </tr>
                            <tr>
                                <td>4.</td>
                                <td><img src={photo} alt="anything you like"></img></td>
                                <td className="wide-cell">Vansh Kathuria</td>
                                <td className="wide-cell">Sop writting</td>
                                <td className="wide-cell">2 nov 2022</td>
                                <td className="wide-cell">2 jan 2023</td>
                                <td>4999/-</td>
                                <td>pending</td>
                                <td>657089</td>
                                <td><button class="button">Cancel order</button></td>
                            </tr>
                            <tr>
                                <td>5.</td>
                                <td><img src={photo} alt="anything you like"></img></td>
                                <td className="wide-cell">Vansh Kathuria</td>
                                <td className="wide-cell">Sop writting</td>
                                <td className="wide-cell">2 nov 2022</td>
                                <td className="wide-cell">2 jan 2023</td>
                                <td>4999/-</td>
                                <td>pending</td>
                                <td>657089</td>
                                <td><button class="button">Cancel order</button></td>
                            </tr>
                            <tr>
                                <td>6.</td>
                                <td><img src={photo} alt="anything you like"></img></td>
                                <td className="wide-cell">Vansh Kathuria</td>
                                <td className="wide-cell">Sop writting</td>
                                <td className="wide-cell">2 nov 2022</td>
                                <td className="wide-cell">2 jan 2023</td>
                                <td>4999/-</td>
                                <td>pending</td>
                                <td>657089</td>
                                <td><button class="button">Cancel order</button></td>
                            </tr>
                            <tr>
                                <td>7.</td>
                                <td><img src={photo} alt="anything you like"></img></td>
                                <td className="wide-cell">Vansh Kathuria</td>
                                <td className="wide-cell">Sop writting</td>
                                <td className="wide-cell">2 nov 2022</td>
                                <td className="wide-cell">2 jan 2023</td>
                                <td>4999/-</td>
                                <td>pending</td>
                                <td>657089</td>
                                <td><button class="button">Cancel order</button></td>
                            </tr>
                            <tr id="r8">
                                <td>8.</td>
                                <td><img src={photo} alt="anything you like"></img></td>
                                <td className="wide-cell">Vansh Kathuria</td>
                                <td className="wide-cell">Sop writting</td>
                                <td className="wide-cell">2 nov 2022</td>
                                <td className="wide-cell">2 jan 2023</td>
                                <td>4999/-</td>
                                <td>pending</td>
                                <td>657089</td>
                                <td><button class="button">Cancel order</button></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
)}
{ showOrder === "Completed" &&(
                <div class="warpper">
                    <table id="mytable" cellSpacing="60px" cellPadding="40px" bgcolor="white" class="t-style" style={{
                        margin: "0 auto ",
                        position: "relative",
                        top: "-250px",
                    }}>
                        <thead>
                            <tr>
                                <th></th>
                                <th></th>
                                <th>Counselor</th>
                                <th>Services</th>
                                <th>Order on</th>
                                <th>Due on</th>
                                <th>Amount</th>
                                <th>Status</th>
                                <th className="wide-cell">Order id</th>
                                <th>Invoice</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>1.</td>
                                <td><img src={photo} alt="anything you like"></img></td>
                                <td className="wide-cell">Vansh Kathuria</td>
                                <td className="wide-cell">Sop writting</td>
                                <td className="wide-cell">2 nov 2022</td>
                                <td className="wide-cell">2 jan 2023</td>
                                <td>4999/-</td>
                                <td>pending</td>
                                <td>657089</td>
                                <td><button class="button">Completed</button></td>
                            </tr>
                            <tr>
                                <td>2.</td>
                                <td><img src={photo} alt="anything you like"></img></td>
                                <td className="wide-cell">Vansh Kathuria</td>
                                <td className="wide-cell">Sop writting</td>
                                <td className="wide-cell">2 nov 2022</td>
                                <td className="wide-cell">2 jan 2023</td>
                                <td>4999/-</td>
                                <td>pending</td>
                                <td>657089</td>
                                <td><button class="button">Completed</button></td>
                            </tr>
                            <tr>
                                <td>3.</td>
                                <td><img src={photo} alt="anything you like"></img></td>
                                <td className="wide-cell">Vansh Kathuria</td>
                                <td className="wide-cell">Sop writting</td>
                                <td className="wide-cell">2 nov 2022</td>
                                <td className="wide-cell">2 jan 2023</td>
                                <td>4999/-</td>
                                <td>pending</td>
                                <td>657089</td>
                                <td><button class="button">Completed</button></td>
                            </tr>
                            <tr>
                                <td>4.</td>
                                <td><img src={photo} alt="anything you like"></img></td>
                                <td className="wide-cell">Vansh Kathuria</td>
                                <td className="wide-cell">Sop writting</td>
                                <td className="wide-cell">2 nov 2022</td>
                                <td className="wide-cell">2 jan 2023</td>
                                <td>4999/-</td>
                                <td>pending</td>
                                <td>657089</td>
                                <td><button class="button">Completed</button></td>
                            </tr>
                            <tr>
                                <td>5.</td>
                                <td><img src={photo} alt="anything you like"></img></td>
                                <td className="wide-cell">Vansh Kathuria</td>
                                <td className="wide-cell">Sop writting</td>
                                <td className="wide-cell">2 nov 2022</td>
                                <td className="wide-cell">2 jan 2023</td>
                                <td>4999/-</td>
                                <td>pending</td>
                                <td>657089</td>
                                <td><button class="button">Completed</button></td>
                            </tr>
                            <tr>
                                <td>6.</td>
                                <td><img src={photo} alt="anything you like"></img></td>
                                <td className="wide-cell">Vansh Kathuria</td>
                                <td className="wide-cell">Sop writting</td>
                                <td className="wide-cell">2 nov 2022</td>
                                <td className="wide-cell">2 jan 2023</td>
                                <td>4999/-</td>
                                <td>pending</td>
                                <td>657089</td>
                                <td><button class="button">Cancel order</button></td>
                            </tr>
                            <tr>
                                <td>7.</td>
                                <td><img src={photo} alt="anything you like"></img></td>
                                <td className="wide-cell">Vansh Kathuria</td>
                                <td className="wide-cell">Sop writting</td>
                                <td className="wide-cell">2 nov 2022</td>
                                <td className="wide-cell">2 jan 2023</td>
                                <td>4999/-</td>
                                <td>pending</td>
                                <td>657089</td>
                                <td><button class="button">Completed</button></td>
                            </tr>
                            <tr id="r8">
                                <td>8.</td>
                                <td><img src={photo} alt="anything you like"></img></td>
                                <td className="wide-cell">Vansh Kathuria</td>
                                <td className="wide-cell">Sop writting</td>
                                <td className="wide-cell">2 nov 2022</td>
                                <td className="wide-cell">2 jan 2023</td>
                                <td>4999/-</td>
                                <td>pending</td>
                                <td>657089</td>
                                <td><button class="button">Completed</button></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
)}

{showOrder ==="Cancled" &&(
                <div class="warpper">
                    <table id="mytable" cellSpacing="60px" cellPadding="40px" bgcolor="white" class="t-style" style={{
                        margin: "0 auto ",
                        position: "relative",
                        top: "-250px",
                    }}>
                        <thead>
                            <tr>
                                <th></th>
                                <th></th>
                                <th>Counselor</th>
                                <th>Services</th>
                                <th>Order on</th>
                                <th>Due on</th>
                                <th>Amount</th>
                                <th>Status</th>
                                <th className="wide-cell">Order id</th>
                                <th>Invoice</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>1.</td>
                                <td><img src={photo} alt="anything you like"></img></td>
                                <td className="wide-cell">Vansh Kathuria</td>
                                <td className="wide-cell">Sop writting</td>
                                <td className="wide-cell">2 nov 2022</td>
                                <td className="wide-cell">2 jan 2023</td>
                                <td>4999/-</td>
                                <td>pending</td>
                                <td>657089</td>
                                <td><button class="button">Completed</button></td>
                            </tr>
                            <tr>
                                <td>2.</td>
                                <td><img src={photo} alt="anything you like"></img></td>
                                <td className="wide-cell">Vansh Kathuria</td>
                                <td className="wide-cell">Sop writting</td>
                                <td className="wide-cell">2 nov 2022</td>
                                <td className="wide-cell">2 jan 2023</td>
                                <td>4999/-</td>
                                <td>pending</td>
                                <td>657089</td>
                                <td><button class="button">Completed</button></td>
                            </tr>
                            <tr>
                                <td>3.</td>
                                <td><img src={photo} alt="anything you like"></img></td>
                                <td className="wide-cell">Vansh Kathuria</td>
                                <td className="wide-cell">Sop writting</td>
                                <td className="wide-cell">2 nov 2022</td>
                                <td className="wide-cell">2 jan 2023</td>
                                <td>4999/-</td>
                                <td>pending</td>
                                <td>657089</td>
                                <td><button class="button">Completed</button></td>
                            </tr>
                            <tr>
                                <td>4.</td>
                                <td><img src={photo} alt="anything you like"></img></td>
                                <td className="wide-cell">Vansh Kathuria</td>
                                <td className="wide-cell">Sop writting</td>
                                <td className="wide-cell">2 nov 2022</td>
                                <td className="wide-cell">2 jan 2023</td>
                                <td>4999/-</td>
                                <td>pending</td>
                                <td>657089</td>
                                <td><button class="button">Completed</button></td>
                            </tr>
                            <tr>
                                <td>5.</td>
                                <td><img src={photo} alt="anything you like"></img></td>
                                <td className="wide-cell">Vansh Kathuria</td>
                                <td className="wide-cell">Sop writting</td>
                                <td className="wide-cell">2 nov 2022</td>
                                <td className="wide-cell">2 jan 2023</td>
                                <td>4999/-</td>
                                <td>pending</td>
                                <td>657089</td>
                                <td><button class="button">Completed</button></td>
                            </tr>
                            <tr>
                                <td>6.</td>
                                <td><img src={photo} alt="anything you like"></img></td>
                                <td className="wide-cell">Vansh Kathuria</td>
                                <td className="wide-cell">Sop writting</td>
                                <td className="wide-cell">2 nov 2022</td>
                                <td className="wide-cell">2 jan 2023</td>
                                <td>4999/-</td>
                                <td>pending</td>
                                <td>657089</td>
                                <td><button class="button">Cancel order</button></td>
                            </tr>
                            <tr>
                                <td>7.</td>
                                <td><img src={photo} alt="anything you like"></img></td>
                                <td className="wide-cell">Vansh Kathuria</td>
                                <td className="wide-cell">Sop writting</td>
                                <td className="wide-cell">2 nov 2022</td>
                                <td className="wide-cell">2 jan 2023</td>
                                <td>4999/-</td>
                                <td>pending</td>
                                <td>657089</td>
                                <td><button class="button">Completed</button></td>
                            </tr>
                            <tr id="r8">
                                <td>8.</td>
                                <td><img src={photo} alt="anything you like"></img></td>
                                <td className="wide-cell">Vansh Kathuria</td>
                                <td className="wide-cell">Sop writting</td>
                                <td className="wide-cell">2 nov 2022</td>
                                <td className="wide-cell">2 jan 2023</td>
                                <td>4999/-</td>
                                <td>pending</td>
                                <td>657089</td>
                                <td><button class="button">Completed</button></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
)}
                <Footer />
            </div>
        </div>
    )
}
export default OrderCounselor
