import React, { useEffect } from "react";
import Footer from "../footer/Footer";
import ServiceComponent from "./servicesComponent";
import "../../styles/userLanding.css"
import ServiceCard2 from "./cardcomp2";
import ServiceComponent2 from "./service-component2";
const no_profile = new URL("./images/21SDD 1.webp", import.meta.url)

function AllServices() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (

    <>
      <div className="">


        <div>

          <ServiceComponent />
          <ServiceComponent2 />

          <div className="user-banner">

            <div className="banner1">

              <span className="banner-heading2" style={{
                position: "relative",
                top: "30px"
              }}>Let's get started by
              </span><br></br><span className="abroad" style={{
                position: "relative",
                top: "20px"
              }}>browsing through services <span className="service-color">you need</span></span><br></br>


              <span className="banner-get"> </span> <br></br>
              <span className="banner-top-c" ></span> <span className="banner-today"></span>
            </div>
            <div className="banner2">
              <img src={no_profile} className="user-illus" width="40%" alt="loading..."></img>

            </div>

          </div>

        </div>
        <h5 className="explore-all-services" style={{
          /* identical to box height */
          color: "#7D7EC7"
        }}>Explore Top Services</h5>
        <hr className="explore-line"></hr>
        <div className="fourrow2">

          <ServiceCard2 />

        </div>
      </div>
      <div>
        <Footer />
      </div>
    </>

  )
}
export default AllServices
