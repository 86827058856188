import React, { useEffect } from "react";
import '../../styles/order.css';
import Footer from "../footer/Footer";
const order = new URL("../../images/Other 12.webp", import.meta.url);
const Invoice = new URL("../../images/download.webp", import.meta.url);
const photo = new URL("../../images/Group336265.webp", import.meta.url);

function Order() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    
    return (
        <div>
            <div class="maindiv">
                <div class="outerback">
                  
                <div class="text-order">
                       <div class="my-order-head"> MANAGE ORDERS</div> <div><img src={order} alt="anything you like" class="orderpic" width="70px"></img></div>
                    </div>
                </div>
                <div class="warpper">
                    <table id="mytable" cellSpacing="60px" cellPadding="40px" bgcolor="white" class="t-style" style={{
                        margin: "0 auto ",
                        position: "relative",
                        top: "-250px",
                    }}>
                        <thead>
                            <tr>
                                <th></th>
                                <th></th>
                                <th>Counselor</th>
                                <th>Services</th>
                                <th>Order on</th>
                                <th>Due on</th>
                                <th>Amount</th>
                                <th>Status</th>
                                <th className="wide-cell">Order id</th>
                                <th>Invoice</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>1.</td>
                                <td><img src={photo} alt="anything you like"></img></td>
                                <td className="wide-cell">Vansh kutaria</td>
                                <td className="wide-cell">Sop writting</td>
                                <td className="wide-cell">2 nov 2022</td>
                                <td className="wide-cell">2 jan 2023</td>
                                <td>4999/-</td>
                                <td>pending</td>
                                <td>657089</td>
                                <td><img src={Invoice} alt="anything you like"></img></td>
                            </tr>
                            <tr>
                                <td>2.</td>
                                <td><img src={photo} alt="anything you like"></img></td>
                                <td className="wide-cell">Vansh kutaria</td>
                                <td className="wide-cell">Sop writting</td>
                                <td className="wide-cell">2 nov 2022</td>
                                <td className="wide-cell">2 jan 2023</td>
                                <td>4999/-</td>
                                <td>pending</td>
                                <td>657089</td>
                                <td><img src={Invoice} alt="anything you like"></img></td>
                            </tr>
                            <tr>
                                <td>3.</td>
                                <td><img src={photo} alt="anything you like"></img></td>
                                <td className="wide-cell">Vansh kutaria</td>
                                <td className="wide-cell">Sop writting</td>
                                <td className="wide-cell">2 nov 2022</td>
                                <td className="wide-cell">2 jan 2023</td>
                                <td>4999/-</td>
                                <td>pending</td>
                                <td>657089</td>
                                <td><img src={Invoice} alt="anything you like"></img></td>
                            </tr>
                            <tr>
                                <td>4.</td>
                                <td><img src={photo} alt="anything you like"></img></td>
                                <td className="wide-cell">Vansh kutaria</td>
                                <td className="wide-cell">Sop writting</td>
                                <td className="wide-cell">2 nov 2022</td>
                                <td className="wide-cell">2 jan 2023</td>
                                <td>4999/-</td>
                                <td>pending</td>
                                <td>657089</td>
                                <td><img src={Invoice} alt="anything you like"></img></td>
                            </tr>
                            <tr>
                                <td>5.</td>
                                <td><img src={photo} alt="anything you like"></img></td>
                                <td className="wide-cell">Vansh kutaria</td>
                                <td className="wide-cell">Sop writting</td>
                                <td className="wide-cell">2 nov 2022</td>
                                <td className="wide-cell">2 jan 2023</td>
                                <td>4999/-</td>
                                <td>pending</td>
                                <td>657089</td>
                                <td><img src={Invoice} alt="anything you like"></img></td>
                            </tr>
                            <tr>
                                <td>6.</td>
                                <td><img src={photo} alt="anything you like"></img></td>
                                <td className="wide-cell">Vansh kutaria</td>
                                <td className="wide-cell">Sop writting</td>
                                <td className="wide-cell">2 nov 2022</td>
                                <td className="wide-cell">2 jan 2023</td>
                                <td>4999/-</td>
                                <td>pending</td>
                                <td>657089</td>
                                <td><img src={Invoice} alt="anything you like"></img></td>
                            </tr>
                            <tr>
                                <td>7.</td>
                                <td><img src={photo} alt="anything you like"></img></td>
                                <td className="wide-cell">Vansh kutaria</td>
                                <td className="wide-cell">Sop writting</td>
                                <td className="wide-cell">2 nov 2022</td>
                                <td className="wide-cell">2 jan 2023</td>
                                <td>4999/-</td>
                                <td>pending</td>
                                <td>657089</td>
                                <td><img src={Invoice} alt="anything you like"></img></td>
                            </tr>
                            <tr id="r8">
                                <td>8.</td>
                                <td><img src={photo} alt="anything you like"></img></td>
                                <td className="wide-cell">Vansh kutaria</td>
                                <td className="wide-cell">Sop writting</td>
                                <td className="wide-cell">2 nov 2022</td>
                                <td className="wide-cell">2 jan 2023</td>
                                <td>4999/-</td>
                                <td>pending</td>
                                <td>657089</td>
                                <td><img src={Invoice} alt="anything you like"></img></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <Footer />
        </div >
    )
}
export default Order
