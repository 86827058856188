import React, { useEffect } from "react";
import Footer from "../footer/Footer";
import "../../styles/terms.css"

function Privacy() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="terms privacy-head">
      <div>
        <p class="cookie">Privacy AND Policy
          <hr style={{
            margin: "auto"
          }}></hr>
        </p>
        <div class="outline">
          <p class="innertext">This privacy policy sets out how Kounselo Education Services uses and protects any information that you give Kounselo Education Services when you use this website. This Privacy Policy outlines the types of information we collect, how we use and share this information, and the measures we take to protect the security of personal data.

            Kounselo Education Services is committed to ensuring that your privacy is protected. Should we ask you to provide certain information by which you can be identified when using this website, and then you can be assured that it will only be used in accordance with this privacy statement
            Kounselo Education Services may change this policy from time to time by updating this page. You should check this page from time to time to ensure that you are happy with any changes.</p>
          <h3 class="heads">Information Collection</h3>
          <p class="innertext">We collect personal information from you when you register for an account on our website, place an order for our products or services, or use our website in any other way. The information we collect may include your name, email address, mailing address, phone number, payment information, and other contact information. We may also collect information about your use of our website and services, such as the pages you visit and the products you purchase.

            In addition to personal information, we may also collect non-personal information, such as browser type and operating system, to improve our website and services. We use cookies and other technologies to track your use of our website, including your browsing history, search history, and purchase history. You can choose to disable cookies in your browser settings, but this may impact your ability to use our website and services.</p>
          <h3 class="heads">Use of Information</h3>
          <p class="innertext">We use the personal information we collect for the following purposes:<br></br>

            To provide, maintain, and improve our products and services<br></br>
            To send you marketing communications and promotional offers<br></br>
            To respond to your inquiries and support requests<br></br>
            To process payments and fulfill orders<br></br>
            To resolve disputes and enforce our policies<br></br>
            To comply with legal obligations, such as reporting to law enforcement or regulatory agencies<br></br>
            We may also use non-personal information for research and analysis purposes, to improve our website and services, and for other business purposes.</p>
          <h3 class="heads">Sharing of Information</h3>
          <p class="innertext">We do not sell, rent, or share personal information with third parties, except as described in this Privacy Policy. We may share personal information with service providers who perform services on our behalf, such as payment processing and order fulfillment. These service providers are bound by confidentiality agreements and are not permitted to use the information for any other purpose.

            We may also disclose personal information in response to legal or regulatory requests, or to protect the rights, property, or safety of Kounselo, our users, or others.
          </p>
          <h3 class="heads">User Content:</h3>
          <p class="innertext">We take the security of personal information very seriously and have implemented reasonable measures to protect it from unauthorized access, disclosure, alteration, or destruction. These measures include encryption, firewalls, and access controls, as well as regular monitoring and audits of our systems and processes.<br></br>
            <br></br>
            However, no data transmission over the internet can be guaranteed to be 100% secure, and we cannot guarantee the security of your personal information. You are responsible for protecting the security of your password and other login credentials, and for taking appropriate measures to secure your devices and networks against unauthorized access.
            You may choose to restrict the collection or use of your personal information in the following ways:
            <ul>
              <li>
                whenever you are asked to fill in a form on the website, look for the box that you can click to indicate that you do not want the information to be used by anybody for direct marketing purposes</li>
              <li>
                if you have previously agreed to us using your personal information for direct marketing purposes

                you may change your mind at any time by writing to or emailing us at info@kounselo.com
              </li>
            </ul>
            We will not sell, distribute or lease your personal information to third parties unless we have your permission or are required by law to do so. We may use your personal information to send you promotional information about third parties which we think you may find interesting if you tell us that you wish this to happen.
            If you believe that any information we are holding on you is incorrect or incomplete, please write to or email us as soon as possible, at the above address. We will promptly correct any information found to be incorrect.</p>
          <h3 class="heads">Children’s Privacy</h3>
          <p class="innertext">Our products and services are not intended for children under the age of 13, and we do not knowingly collect personal information from children under 13. If we become aware that we have collected personal information from a child under 13, we will delete it as soon as possible.</p>
          <h3 class="heads">Changes to this Privacy Policy</h3>
          <p class="innertext">

            We may update this Privacy Policy from time to time to reflect changes in our practices or laws. If we make any material changes to this Privacy Policy, we will notify you by email or by posting a notice on our website before the changes take effect.

          </p>
          <h3 class="heads">Contact Us</h3>
          <p class="innertext">If you have any questions or concerns about our Privacy Policy, please contact us at info@kounselo.com. We will respond to your inquiry as soon as possible.</p>


        </div>

      </div>
      <Footer />
    </div>
  )
}
export default Privacy