import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from 'axios';
import fileDownload from 'js-file-download';
import toast, { Toaster } from 'react-hot-toast';

const illustration5 = new URL("../../images/error404.webp", import.meta.url);
const pdf = new URL("../../images/kounselo_ebook.pdf", import.meta.url);

function KonseloBookLink() {
    const [show, setShow] = useState(true)
    const [showilts, setIlts] = useState(true)
    const navigate = useNavigate();
    const baseUrl = process.env.REACT_APP_BASE_URL_DEV;
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [contact, setContact] = useState();

    const handleSubmitIelst = async (e) => {
        const fileUrl = pdf;

        // Create a temporary anchor element
        const link = document.createElement('a');
        link.href = fileUrl;
        link.target = '_blank'; // Open in a new tab, if needed
        link.download = 'kounselo.pdf'; // Replace 'filename.ext' with the desired filename for the downloaded file

        // Append the anchor element to the DOM
        document.body.appendChild(link);

        // Trigger a click event on the anchor element to start the download
        link.click();

        // Clean up: Remove the anchor element from the DOM after the download is initiated
        document.body.removeChild(link);

        try {
            const response = axios.post(`${baseUrl}/api/v1/inputs/ielst/ebook`, {
                name: name,
                email: email,
                contactno: contact,
            });
            if (response.data.status === "success") {
                toast.success("Your Free Ielst Book starts downloading...")
            }
        } catch (err) {
            // console.log(err);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault()
        try {
            const response = axios.post(`${baseUrl}/api/v1/inputs/student`, {
                name: name,
                email: email,
                contactno: contact,
            });
            if (response.data.status === "success") {
                toast.success('Thanks for sharing your details.We will contact you soon!')
            }
        } catch (err) {
            // console.log(err);
        }
    };

    return (
        <div className="kounselo2-main" >

            <div className="kounselo2  kounselo-mobile" style={{
                display: "none"
            }}>

                <span className="span1">  <span style={{
                    color: "#6262A0"
                }}></span></span><br></br>
                <span className="span2"></span>
                <div className="">

                    <img src={illustration5} width="90%" className="" alt="loading..."></img>
                </div>
                <div className="kounselo-form-display2">
                    <form onSubmit={handleSubmit}>
                        <input name="name" type="text" className="kounselo-form name" placeholder="Name*" value={name} onChange={e => setName(e.target.value)} required></input><br></br>

                        <input name="email" type="email" className="kounselo-form email" placeholder="Email*" value={email} onChange={e => setEmail(e.target.value)} required></input><br></br>

                        <input name="contact" type="tel" className="kounselo-form tel" placeholder="Phone Number*" value={contact} onChange={e => setContact(e.target.value)} required></input><br></br>


                        <input type="submit" className="submit" value='Submit'></input>
                    </form>
                </div>
            </div>
            <div className="kounselo2 " style={{
                position: "relative",
                top: "-300px"
            }} >
                <span className="span1"><span style={{
                    color: "#6262A0"
                }}></span></span><br></br>

                <div className="forms-2">
                    <div className="">
                        {showilts &&
                            <div className=" box-shadow kounselo-form-display5">
                                <button onClick={() => setIlts(false)} className="ilts-cross">x</button>
                                <h4 className="span2" style={{
                                    position: "relative",
                                    top: "20px"
                                }}>Download Your Free E-book form here</h4>

                                <div className="e-book" style={{


                                }}>

                                    <div className="kounselo-form-display1">


                                        <img src={illustration5} width="100%" className="form-illus" alt="loading..."></img>
                                    </div>
                                    <div className="kounselo-form-display2">
                                        <form onSubmit={handleSubmitIelst}>
                                            <input required name="name" type="text" className="kounselo-form name3" placeholder="Name*" value={name} onChange={e => setName(e.target.value)}></input><br></br>

                                            <input required name="email" type="email" className="kounselo-form email3" placeholder="Email*" value={email} onChange={e => setEmail(e.target.value)}></input><br></br>


                                            <input name="contact" pattern="[0-9]{10}" title="Must Contain 10 Digits" type="tel" className="kounselo-form tel" placeholder="Phone Number*" value={contact} onChange={e => setContact(e.target.value)} required></input><br></br>

                                            <button type="submit" className="submit submit3" value='Submit '>  Download E-book   </button>




                                        </form>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>

                </div>

            </div>

        </div>
    )
}
export default KonseloBookLink