import React, {useEffect} from "react";
import Flickity from 'react-flickity-component'
import { useNavigate } from "react-router-dom";
import { SellerCardsAPICall } from "../../Redux/actions/GetAllSellerCardsActionCreator";
import { useDispatch, useSelector } from "react-redux";
import { getSellerIdFromSellerCard } from "./../../Redux/actions/GetSellerID_UserInterfaceActionCreator";
import toast, { Toaster } from 'react-hot-toast';
const rating5 = new URL("../../images/1star.webp", import.meta.url)
const noprofile = new URL("../../images/noProfilepic.webp", import.meta.url)


function PopularSellerCard() {
  const flickityOptions = {
    initialIndex: 1,
    pageDots: false,
  
  }
  const data = useSelector(state => state.get_Seller_Cards)
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
   SellerCardsAPICall('getAllSeller', dispatch);  
  }, []);

  const ViewSellerProfile = async (e, id) => {
    e.preventDefault();
    try{
      if (id)
      navigate('/counselor-profile', dispatch(getSellerIdFromSellerCard(id)));
    }catch(err){
      toast.error('No such profile found');
      // console.log(err);
    }
  };

  const ChatWithMe = async(e, id) => {
    e.preventDefault();
    if(id) navigate('/chat');
  }

  return (
    <>
      {data !== undefined && data.length !== 0 ?
        data.map((item) => {
          console.log(item.photo, "photo")
          return (

            <div key={item._id}> 
            <div class="carousel-cell " >
              <div className="service-card">
              </div>
         
              <span className="cardName">{item.name} </span>
              <br></br>
              <div className="" style={{
                marginTop: "10px",
                fontSize: "14px"

              }}>
                4.3<img style={{
                  position: "relative",
                  top: "-1px",
                  left: "2px"
                }} width="5%" src={rating5} alt = "loading..."></img>
              </div><br></br>
              <div>
                <Flickity
                  className={'carousel9'} // default ''
                  elementType={'div'} // default 'div'
                  options={flickityOptions} // takes flickity options {}
                  disableImagesLoaded={true} // default false
                  reloadOnUpdate={true} // default false
                  static={true} // default false
                >
                {item.sellerCountrySpecialization !== undefined && item.sellerCountrySpecialization !== null ?
                 item.sellerCountrySpecialization.map((items) => {
                  return (
                    <div key = {items} className="flag-slider" style={{
                      display: "flex"
                    }}>
                    <img src={`${process.env.PUBLIC_URL}/countryFlags/${items}.webp`} className="flag-pic" alt = "loading..." width="20px"></img>
                  </div>
                    )
                   }) : null
                  }  
                </Flickity>
              </div>
              <div style={{
                textAlign: "left"
              }}>
                <div className="degree">
                  Degree
                </div>
                <div>
                  
                {item.sellerDegrees !== undefined && item.sellerDegrees !== null ? 
                  item.sellerDegrees.map((items) => {
                    return (
                      <>
                      <span className="degree-type">{items} </span><span className="degree-type"> | </span>
                      </>
                    )
                  }) : null
                  }
                  
                  <div className="service-S-from">
                    Services from
                  </div><br></br>
                  <span className="INR9" style={{
                    color: "black"
                  }}>INR</span><span className="INR9 INR7" style={{
                    fontWeight: "bold"
                  }} >4999/-</span>
                </div>
               <button className="view-btn" onClick = {e => ViewSellerProfile(e, item._id)}>VIEW PROFILE</button>
                <button style={{
                  position: "relative",
                  left: "30px",
                  top: "10px",
                  border: "none",
                  color: "white",
                  fontSize: "10px",
                  padding: "4px",
                  width: "40%", background: "linear-gradient(90deg, #7176C4 0.15%, #7D7EC7 99.78%)",
                  fontWeight: "bold"

                }} onClick={e => ChatWithMe(e, item._id)}>CHAT</button>
              </div>
            </div>
            </div>
          )
        }) : null
      }
      <Toaster/>
    </>
  )

}
export default PopularSellerCard