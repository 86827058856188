import React, { useEffect } from "react";

function Myorders() {
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
    return (
        <div className="order-main">
            {/* <span className="order">
                My orders
            </span> */}
            <table cellSpacing="60px" cellPadding="20px" bgcolor="white" style={{
                margin: "auto ",
                position: "relative",
                top: "150px",
                width: "%",

                background: "#FFFFFF",
                boxShadow: "0px 72.5294px 108.794px rgba(0, 0, 0, 0.07)"

            }}>
                <tr>
                    <th></th>
                    <th>Counselor</th>
                    <th>Services</th>
                    <th>Order on</th>

                    <th>due on</th>
                    <th>Amount</th>
                    <th>Status</th>
                    <th>Order id</th>
                    <th>Invoice</th>
                </tr>



                <tr>
                    <td>
                        photo
                    </td>
                    <td>
                        Vansh kutaria
                    </td> <td>
                        Sop writting

                    </td>
                    <td>
                        2 nov 2022
                    </td>
                    <td>
                        2 jan 2023
                    </td>
                    <td>
                        4999/-
                    </td>
                    <td>
                        pending
                    </td>
                    <td>
                        657089
                    </td>
                    <td>
                        Download
                    </td>
                </tr>



                <tr>
                    <td>
                        photo
                    </td>
                    <td>
                        Vansh kutaria
                    </td> <td>
                        Sop writting

                    </td>
                    <td>
                        2 nov 2022
                    </td>
                    <td>
                        2 jan 2023
                    </td>
                    <td>
                        4999/-
                    </td>
                    <td>
                        pending
                    </td>
                    <td>
                        657089
                    </td>
                    <td>
                        Download
                    </td>
                </tr>

            </table>
        </div>
    )
}
export default Myorders