
import React, {useState, useEffect } from "react";
import "./contact.css";
import Footer from "../footer/Footer";
import axios from "axios";
import { useLocation } from "react-router-dom";
import ReactGA from "react-ga4"
const illus = new URL("../../images/Frame.webp", import.meta.url);
const illus1 = new URL("../../images/Frame (1).webp", import.meta.url);
const illus2 = new URL("../../images/Frame (2).webp", import.meta.url);
function Contact() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const location = useLocation();
  useEffect(() => {
    ReactGA.send(window.location.pathname + window.location.search);
  }, []);
  const[name, setName] = useState("");
  const[contactNo, setContactNo] = useState("");
  const[email, setEmail] = useState("");
  const[course, setCourse] = useState("city1");
  const[textValue, settextValue] = useState("");
  
  const baseUrl = 'http://localhost:8000';

  const handleSubmit = async (e) => {
    e.preventDefault()
    alert('Form Submitted Successfully')
    
      await axios.post(`${baseUrl}/api/v1/inputs/student`, {
          name: name,
          contactno: contactNo,
          email: email,
          counselorDisciplineSpecialization:course,
          whatDoyouWantToSay:textValue,
        }).then(function (res) {
        }).catch(function (err) {
            // console.log(err);
        })
      }
    
   return (
    <div>

      <div className="contact-body">
        <div className="contact-detail">
          <center><div className="conatact-heading"> CONTACT US</div> </center>
          <div className="contact-content">Whether you have a question, or want to share your feedback, we are here, ready to answer all your questions!</div>

        </div>
        <div>
          <center>
            <form onSubmit={handleSubmit}>
            <div class="forms">
            
              <div class="mb-3 contact-form">
                <div class="row">
                  <div class="col-md-6">
                    <label for="exampleFormControlInput1" class="form-label" >Name<span className="required">*</span></label>
                    <input minLength="3" maxLength="15" name= "name" type="text" required class="form-control" placeholder=" Name" aria-label="First name" onChange = {e => setName(e.target.value)} />
                  </div>
                  <div class="col-md-6">
                    <label for="exampleFormControlInput1" class="form-label">Course</label>
                    <input  name= "name" type="text" required class="form-control" placeholder=" Course Name" aria-label="First name" onChange = {e => setName(e.target.value)} />

                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <label for="exampleFormControlInput1" class="form-label">Email address<span className="required">*</span></label>
                    <input name = "email" type="email" class="form-control" id="exampleFormControlInput1" placeholder="name@example.com" onChange ={e => setEmail(e.target.value)}/>
                  </div>
                  <div class="col-md-6">
                    <label for="exampleFormControlInput1" class="form-label" >Contact number<span className="required">*</span></label>
                    <input title="Must Contain 10 Digits" name ="contactNo" type="tel" class="form-control"  placeholder="9123999999" maxLength={10} pattern="[0-9]{10}" onChange = {e => setContactNo(e.target.value)}/>
                  </div>
                </div>
                <div class="mb-3 contact-form pad-box">
                  <label for="exampleFormControlTextarea1" class="form-label">What do you want to say?</label>
                  <textarea value={textValue} class="form-control" id="exampleFormControlTextarea1" rows="7" onChange = {e => settextValue(e.target.value)}></textarea>
                  <br></br>
                  <div class="btn-box">
                    <button type="submit" class="btn" >Submit Form</button>
                    {/* <input type="submit"></input> */}
                  </div>
                </div>
              </div>

            </div>
            </form>

            <div className="contact-us-all-detail">
              <div className="contact-flex1">
                <div>
                <img src={illus1} width="70px" style={{

                }}>

                </img>
                </div>
                <div className="contact-info" style={{

                }}>
                  Whatsapp
                </div><div
                  style={{
marginTop:"10px"
                  }}>Check out our whatsapp community <br></br> for regular updates</div><br></br>

              <a className="" style={{

                }} href="">Get me there</a>
              </div>

              <div className="contact-flex2">
                <div>
                <img src={illus} width="70px" style={{

                }}>
                </img>
                
                
                </div><div className="contact-info" style={{

                }}>
                  Email us
                </div><div
                  style={{
marginTop:"10px"
                  }}>Email us for your queries <br></br> and feedback</div><br></br><a className="" style={{

                  }} href="">info@kounselo.com</a>
              </div>
              <div className="contact-flex3">
                <div>
                <img src={illus2} width="70px" style={{

                }}>
                </img>
                </div><div className="contact-info" style={{

                }}>
                  Call Us
                </div>
                <br></br><div
                  style={{

                  }}>Call us to speak to a member <br></br>of our team. We are happy to help</div><br></br><a className="" href="">+91 9003440557</a>
              </div>
            </div>
          </center>
        </div>
      </div>
      <Footer />
    </div>
  )
}
export default Contact