import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import './about.css'
import ReactGA from "react-ga4"
// import { Pathname } from "react-router-dom";
// import { Location } from "react-router-dom";

import Footer from "../footer/Footer";
import { useLocation } from "react-router-dom";

const illus = new URL("./images/girl.webp", import.meta.url);
const pic2 = new URL("./images/tanishq.webp", import.meta.url);
const pic3 = new URL("./images/vansh.webp", import.meta.url);
const pic4 = new URL("./images/srishti.webp", import.meta.url);
const illustration = new URL("./images/pedalstart logo 1.webp", import.meta.url)
const illustration5 = new URL("./images/mohak.webp", import.meta.url)
const illustration11 = new URL("./images/g incube logo 1.webp", import.meta.url)
const linkedIN = new URL("./images/LinkedIn.webp", import.meta.url);
const pic5 = new URL("./images/adi.webp", import.meta.url);
const siif = new URL("../../images/SIIF-LOGO-main.png", import.meta.url)
const pilani = new URL("../../images/pieds logo.png", import.meta.url)


function About() {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const location = useLocation();
  useEffect(() => {
    ReactGA.send(window.location.pathname + window.location.search);
  }, []);
  return (
    <div>
      <div>

        <div className="about-body">
          <div className="about-detail">
            <div className="">
              <span className="about-text">ABOUT US</span>
            </div>
          </div>

          <div className="about-content">
            <div className="about-pic">
              <img src={illus} width="300px" alt="loading..."></img>
            </div>

            <div className="about-text2">
              <h2 className="about-head">KNOW ABOUT KOUNSELO</h2>
              <div className="about-text3">    Welcome to Kounselo! We're a dynamic team that's on a mission to make studying abroad accessible and achievable for every student.
                At Kounselo, we believe that finding the right study abroad counselor should be a painless and stress-free experience. That's why we've created a platform that allows you to compare and choose from a variety of knowledgeable and experienced counselors offering multiple study abroad related services with ease. And, if you're feeling unsure, just check out the glowing reviews from other students who have already taken the leap!<br></br><br></br>

                Our platform is more than just a marketplace, it's a community of students, counselors, and international education enthusiasts. Whether you're looking to study in the United States, Europe, Australia, or anywhere else in the world, our pool of expert counselors will help you reach your study abroad goals.

                <br></br><br></br>We understand that studying abroad can be a daunting experience, but with Kounselo by your side, it doesn't have to be. Our dedicated team is here to make sure that every step of the process is easy, efficient, and memorable.

                <br></br><br></br>So, what are you waiting for? Join the Kounselo community and start your journey to a brighter future! Our approach to study abroad is designed to make your experience unique and personalised to suit your needs well.</div>
            </div>

          </div>


          <div className="about-team">
            <div className="our-team">
              MEET OUR TEAM
            </div>
            <div className="row1">
              <div className="col-5 mteam">
                <img src={pic3} className="about-team-pic" alt="loading..."></img>
                <h6 class="about-team-name">Vansh Kathuria</h6>
                <h6 class="about-team-name2">Growth</h6>

                <Link classname="footlink" to="https://www.linkedin.com/in/vansh-kathuria-460b3299?lipi=urn%3Ali%3Apage%3Ad_flagship3_profile_view_base_contact_details%3Bk2OzjdFnROyelyXIlf7biw%3D%3D" target="_blank"><img src={linkedIN} className="social-media2" alt=""></img></Link>

              </div>
              <div className="col-5 mteam">
                <img src={pic2} className="about-team-pic" alt="loading..."></img>
                <h6 class="about-team-name">Tanishq Shukla</h6>
                <h6 class="about-team-name2">Product</h6>

                <Link classname="footlink" to="https://www.linkedin.com/in/tanishq-shukla-735016195" target="_blank"><img src={linkedIN} className="social-media2" alt=""></img></Link>

              </div>
              <div className="col-5 mteam">
                <img src={illustration5} className="about-team-pic" alt="loading..."></img>
                <h6 class="about-team-name">Mohak Singh</h6>
                <h6 class="about-team-name2">Product</h6>

                <Link classname="footlink" to="https://www.linkedin.com/in/mohak-rajput-0854b7202" target="_blank"><img src={linkedIN} className="social-media2" alt=""></img></Link>

              </div>
              <div className="col-5 mteam">
                <img src={pic4} className="about-team-pic" alt="loading..."></img>
                <h6 class="about-team-name">Srishti Trivedi</h6>
                <h6 class="about-team-name2">Design</h6>

                <Link classname="footlink" to="https://www.linkedin.com/in/srishti-trivedi-2ab258183" target="_blank"><img src={linkedIN} className="social-media2" alt=""></img></Link>

              </div>


              <div className="col-5 mteam">
                <img src={pic5} className="about-team-pic" alt="loading..."></img>
                <h6 class="about-team-name">Aditya Vishpute</h6>
                <h6 class="about-team-name2">Design</h6>

                <Link classname="footlink" to="https://www.linkedin.com/in/aditya-vispute-193538239?lipi=urn%3Ali%3Apage%3Ad_flagship3_profile_view_base_contact_details%3BpU95gUWgQb6oau0BJJ%2F4WQ%3D%3D" target="_blank"><img src={linkedIN} className="social-media2" alt=""></img></Link>

              </div>

            </div>


            <div className="incube-about">
              <div className="incube-component" style={{ marginBottom: "40px", marginTop: "40px" }} >
                <span className="incube-head incube-pc">Supported By</span><br></br><br></br>
                <hr width="40%" className="incube-pc" style={{ position: "relative", margin: "auto", marginBottom: "20px" }}></hr>


                <div className="incube-content incube-pc">
                  <div>
                    <img src={illustration} className="incube-pic" width="25%" alt="loading..."></img>
                  </div>
                  <div>
                    <img src={illustration11} className="incube-pic2" width="25%" alt="loading..."></img>

                  </div>
                  <div>
                    <img src={siif} className="incube-pic3" width="25%" alt="loading..."></img>
                  </div>
                  <div>
                    <img src={pilani} className="incube-pic4" width="25%" alt="loading..."></img>
                  </div>
                </div>
                
              </div>


            </div>
          
          </div>

          <div className="incube-component  display-mobile" style={{
                marginBottom: "40px",
                marginTop: "40px"
              }} >
                <span className="incube-head">Supported By</span><br>
                </br><br></br><hr width="40%" style={{
                  position: "relative", margin: "auto",
                  marginBottom: "20px"

                }}></hr>


                <div className="incube-content" style={{

                }}>
                  <div>
                    <img src={illustration} className="incube-pic" width="25%" alt="loading..."></img>
                  </div>
                  <div>
                    <img src={illustration11} className="incube-pic2" width="25%" alt="loading..."></img>

                  </div>
                  <div>
                <img src={siif} className="" width="25%" alt="loading..."></img>
              </div>
              <div>
                <img src={pilani} className="" width="65%" alt="loading..."></img>
              </div>
                </div>
              </div>
          <Footer />
          <div>


          </div>



        </div>
      </div>


    </div>




  )
}
export default About;