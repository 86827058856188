import React, { useEffect } from "react";
import Footer from "../footer/Footer";
import "../../styles/cookiePolicy.css"

function RefundPolicy() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    
    return (
        <div className="terms privacy-head">
            <div>
                <p class="cookie">Refund Policy
                    <hr style={{
                        margin: "auto"
                    }}></hr>
                </p>
                <div class="outline">
                    <p className="innertext">At Kounselo, we strive to provide the best possible experience for our users, including a transparent and fair refund policy. Please take a moment to familiarize yourself with our refund policy outlined below:</p>
                    <h3 class="heads">Convenience Fees:</h3>
                    <p class="innertext">
                        1.1. The convenience fees charged by the platform is non-refundable.</p>
                    <h3 class="heads">Services Fees:</h3>
                    <p class="innertext">                        2.1 If a user cancels an order midway, the refund will be dependent upon the amount of work done by the counselor at the time of cancellation.</p>
                    <h3 class="heads">Exceptional Circumstances:</h3>
                    <p class="innertext">To use the Marketplace, you must create a user account. You are responsible for maintaining
                        the confidentiality of your account and password, and for restricting access to your computer. You agree to
                        accept responsibility for all activities that occur under your account.</p>
                    <h3 class="heads">User Content:</h3>
                    <p class="innertext"> 3.1. In exceptional cases, where there is a technical issue or failure in service delivery from a counselor on our platform, we will work closely with the affected user to resolve the issue or provide an appropriate refund.</p>
                    <h3 class="heads">Please note the following important points:</h3>
                    <p class="innertext">                                Refunds will be processed using the original payment method whenever possible.
                        Refunds may take up to 5-10 business days to be processed and reflected in the user's account after the amount to be refunded is decided..</p>

                    <p class="innertext">Kounselo reserves the right to modify this refund policy at any time. Any changes or updates will be communicated to users via email or through our platform..</p>

                    <p class="innertext">If you have any questions or require further clarification regarding our refund policy, please don't hesitate to contact our support team at info@kounselo.com.
                    </p>

                    <p class="innertext"> Thank you for being a part of the Kounselo community.
                    </p>

                    <p class="innertext">Best regards,
                        <div>The Kounselo Team</div></p>
                </div>

            </div>
            <Footer />
        </div>
    )
}


export default RefundPolicy























