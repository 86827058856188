import React, { useState, useEffect } from 'react';
import Footer from "../footer/Footer";
import axios from 'axios';
import { useNavigate, Link } from "react-router-dom";
import { SellerCardsAPICall } from "../../Redux/actions/GetAllSellerCardsActionCreator";
import { useDispatch, useSelector } from "react-redux";
import { getSellerIdFromSellerCard } from "./../../Redux/actions/GetSellerID_UserInterfaceActionCreator";

import Carousel from "react-multi-carousel";

import { Multiselect } from 'multiselect-react-dropdown';
import toast, { Toaster } from 'react-hot-toast';
const noprofile = new URL("../../images/noProfilepic.webp", import.meta.url)
const plus = new URL("../../images/plus2.webp", import.meta.url)
// const plus = new URL("../../images/plus2.png", import.meta.url)
// const drop = new URL("../../images/dropdown.png", import.meta.url)
function Sellerprofile() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [selectedCountry, setSelectedCountry] = useState([]);
  const [selectedDiscipline, setSelectedDiscipline] = useState([]);
  const [selectedDegree, setSelectedDegree] = useState([]);
  const [selectedLanguage, setSelectedLanguage] = useState([]);
  const [description, setDescription] = useState("")
  const [sellerDegree, setSellerDegree] = useState("")
  const [sellerStream, setSellerStream] = useState("")
  const [sellerCollege, setSellerCollege] = useState("")
  const [contactNumber, setContactNumber] = useState("");
  const [isSellerAgreed, setIsSellerAgreed] = useState(false);
  const [file, setFile] = useState(null);
  const [GST, setGST] = useState("");
  const [link, setLink] = useState("");
  const [pricing, setPrice] = useState("");
  // const [isContactVerified, setIsContactVerified] = useState(false);
  // const [otp, setOtp] = useState("")
  // const [pan, setPan] = useState("")
  // const [panUrl, setPanUrl] = useState("");

  const country = [
    {
      countryName: "Belize",
    },
    {
      countryName: "Solvenia"
    },
    {
      countryName: "Hungary"
    },
    {
      countryName: "Estonia"
    },
    {
      countryName: "lithuania"
    },
    {
      countryName: "Thailand"
    },
    {
      countryName: "Greece"
    },
    {
      countryName: "Kyrgyzstan"
    },
    {
      countryName: "Armenia"
    },
    {
      countryName: "Belarus"
    },

    {
      countryName: "Kazakhstan"
    },
    {
      countryName: "Norge Unions"
    },
    {
      countryName: "Bosnia & Herzegovina"
    },
    {
      countryName: "South Africa"
    },
    {
      countryName: "Portugal"
    },
    {
      countryName: "Belgium"
    },
    {
      countryName: "Turkey"
    },
    {
      countryName: "Georgia"
    },
    {
      countryName: "Ukrane"
    },
    {
      countryName: "UK"
    },
    {
      countryName: "Vietnam"
    },
    {
      countryName: "Japan"
    },
    {
      countryName: "Austria"
    },
    {
      countryName: "Cyprus"
    },
    {
      countryName: "Europe"
    },
    {
      countryName: "Bulgaria"
    },
    {
      countryName: "Bangladesh"
    },
    {
      countryName: "Barbados"
    },

    {
      countryName: "Russia"
    },

    {
      countryName: "Malaysia"
    },
    {
      countryName: "Malta"
    },
    {
      countryName: "Latvia"
    },
    {
      countryName: "Philippines"
    },
    {
      countryName: "China"
    },
    {
      countryName: "South korea"
    },
    {
      countryName: "New Zealand"
    },
    {
      countryName: "Hong Kong"
    },
    {
      countryName: "Singapore"
    },
    {
      countryName: "Norway"
    },
    {
      countryName: "UAE"
    },
    {
      countryName: "Switzerland"
    },
    {
      countryName: "Thailand"
    },
    {
      countryName: "Luxembourg"
    },
    {
      countryName: "Turkey"
    },
    {
      countryName: "Spain"
    },
    {
      countryName: "Sweden"
    },

    {
      countryName: "Poland"
    },
    {
      countryName: "Netherlands"
    },
    {
      countryName: "Italy"
    },
    {
      countryName: "France"
    },
    {
      countryName: "Finland"
    },
    {
      countryName: "Ireland"
    },
    {
      countryName: "Denmark"
    },
    {
      countryName: "Australia"
    },
    {
      countryName: "Canada"
    },

    {
      countryName: "Maritius"
    },
    {
      countryName: "USA"
    },
    {
      countryName: "Germany"
    },
  ]

  const degrees = [
    {
      degree: "Bachelors"
    },
    {
      degree: "Masters"
    },
    {
      degree: "Diploma"
    },
    {
      degree: "PHD"
    },
  ];

  const disciplines = [
    {
      discipline: "Applied Sciences & Profession",
    },
    {
      discipline: "Art",
    },
    {
      discipline: "Bussines",
    },
    {
      discipline: "Engineering Technology",
    },
    {
      discipline: "Economics",
    },
    {
      discipline: "Environmental Sciences",
    },
    {
      discipline: "Humanities",
    },
    {
      discipline: "Internship English",
    },
    {
      discipline: "Law",
    },
    {
      discipline: "Life Sciences and Medical Health",
    },
    {
      discipline: "Management",
    },
    {
      discipline: "Natural Sciences",
    },
    {
      discipline: "Social Sciences",
    },
    {
      discipline: "Agriculture Production",
    },
    {
      discipline: "Agriculture Sports",
    },
  ];

  const Languages = [
    {
      language: 'English'
    },
    {
      language: 'Hindi'
    },
    {
      language: "Bengali"
    },
    {
      language: "Telugu"
    },
    {
      language: "Marathi"
    },
    {
      language: "Tamil"
    }
    ,
    {
      language: "Urdu"
    }
    ,
    {
      language: "Gujarati"
    },
    {
      language: 'Kannada',
    },
    {
      language: "Punjabi"
    },
    {
      language: 'Malayalam',
    }
    , {
      language: "Odia"
    },
    {
      language: "Assamese"
    },
    {
      language: "Kashmiri"
    },
    {
      language: "Sindhi"
    },
    {
      language: "Konkani"
    },
    {
      language: "Manipuri"
    },
    {
      language: "Nepali"
    }
  ]


  const navigate = useNavigate();
  const id = useSelector((state) => state.get_seller_profile_id.seller_id);
  const email = useSelector((state) => state.get_seller_profile_id.email);
  const name = useSelector((state) => state.get_seller_profile_id.name);
  // const isEmailVerfied = useSelector((state) => state.get_seller_profile_id.isEmailVerfied)
  const baseUrl = process.env.REACT_APP_BASE_URL_DEV;

  const sellerLanguage = selectedLanguage.reduce((acc, selectedLanguage) => acc.concat(selectedLanguage.language), []);
  const sellerCountry = selectedCountry.reduce((acc, selectedCountry) => acc.concat(selectedCountry.countryName), []);
  const sellerDegrees = selectedDegree.reduce((acc, selectedDegree) => acc.concat(selectedDegree.degree), []);
  const sellerDiscipline = selectedDiscipline.reduce((acc, selectedDiscipline) => acc.concat(selectedDiscipline.discipline), []);

  const createSeller = {
    personalDescription: description,
    sellerUniversity: sellerCollege,
    sellerProfessionalDegree: sellerDegree,
    sellerStream: sellerStream,
    sellerLanguageKnown: sellerLanguage,
    sellerCountrySpecialization: sellerCountry,
    sellerDisciplineSpecialization: sellerDiscipline,
    sellerGSTNumber: GST,
    isSellerAgreed: isSellerAgreed,
    sellerDegrees: sellerDegrees,
    contactDetails: "+91" + contactNumber,
    TopmateLink: link,
    initialPricing:pricing
    // isContactVerified: isContactVerified,
    // isEmailVerified: isEmailVerfied
  }

  // const formdata = new FormData();
  // formdata.append('photo', selectedProfileImage);
  // formdata.append('sellerPANImage', pan);
  // console.log(createSeller);
  // 

  const sellerId = useSelector(state => state.get_seller_profileView_id);


  const handleFileChange = (event) => {
    console.log(event.target.value)
    setFile(event.target.files[0]);
  };

  const handleSubmitphoto = async () => {
    const formData = new FormData();
    formData.append('photo', file);
    try {
      const response = await axios.patch(`${baseUrl}/api/v1/sellers/uploadSellerPhoto/${id}`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' }
      });
      console.log(response.data); // Handle the response from the server
      if (response.data.status === "success") {
        toast.success("Profile Photo Updated Sucessfully");
      }
      // setFile(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.patch(`${baseUrl}/api/v1/sellers/updateSeller/${id}`, createSeller);
      if (response.data.status === "success") {
        toast.success("Profile Created Successfully");
        navigate('/create-service');
      }
    } catch (err) {
      // console.log(err);
      toast.error("Something went wrong, Please Try Again Later")
    }
  }
  const [dataSeller, setData2] = useState([]);

  const dataseller = useSelector(state => state.get_Seller_Cards)


  useEffect(() => {
    const baseUrl = process.env.REACT_APP_BASE_URL_DEV;
    const getSellerProfile = async () => {
      try {
        const response = await axios.get(`${baseUrl}/api/v1/sellers/getSellerProfile/${id}`);
        console.log([response.data.data.seller]);
        setData2([response.data.data.seller])
      }
      catch (err) {
        // console.log(err);
      }
    }
    getSellerProfile();
  }, [id]);

  const [showUpload, setShowUpload] = useState(false)
  // const getOtp = async (e) => {
  //   e.preventDefault();
  //   try {
  //     const response = await axios.post(`${baseUrl}/api/v1/sellers/sendOTP`, { contactNumber: "+91" + contactNumber });
  //     console.log(contactNumber, "contactNumber");
  //     if (response.data.status === "success") {
  //       toast.success("OTP Sent Successfully");
  //     }
  //   } catch (err) {
  //     console.log(err);
  //     toast.error("Please Enter the Valid Number");
  //   }
  // }
  // const verifyOtp = async (e) => {
  //   e.preventDefault();
  //   try {
  //     const response = await axios.post(`${baseUrl}/api/v1/sellers/verifyOTP`, { otp });
  //     if (response.data.status === "success") {
  //       toast.success("OTP Verified Successfully");
  //       setIsContactVerified(true);
  //     }
  //   } catch (err) {
  //     console.log(err.response.message);
  //     toast.error("Please Enter the Valid OTP");
  //   }
  // }

  const [upload, setUpload] = useState(false);

  return (
    <div class="profile">

      <div className='profile-pic-head'></div>
      <div className='profile-pic-head2'>
        <div className='profile-pic-head3'>
          <div >
            <div className='profile-pic'>

              {dataSeller.lenght !== 0 ?


                dataSeller.map((item) => {

                  return (
                    <div key={item._id}>
                      <img width="100px" height="100px" style={{ borderRadius: "50%" }} src={item.photo !== undefined ? `https://kounselodata.s3.ap-south-1.amazonaws.com/${item.photo}` : noprofile} alt="loading..."></img>
                    </div>
                  )


                }) : null
              }

              <div>
                <div style={{ margin: "auto", width: "20px", position: "relative", top: "-10px" }}>
                  <img onClick={() => setShowUpload(true)} width="20px" src={plus} alt = "loading..."></img>
                </div>
                {showUpload &&
                  <form onSubmit={handleSubmitphoto}>



                    <div onMouseLeave={() => setUpload(false)} className="upload-div4" style={{
                      border: "1px solid black"
                    }}>

                      <div class="upload" style={{
                        margin: "auto",
                        width: "100px"

                      }}>
                        <div>
                          <button type="button" class="btn-warning">
                            <i class="fa fa-upload"></i> Browse
                            <input onChange={handleFileChange} type="file" />
                          </button>
                        </div>
                      </div>


                      <hr style={{
                        margin: "10px auto"
                      }}></hr>

                      <button type="submit" className="button-upload" style={{
                        margin: "auto"
                      }}>upload</button>
                      <button onClick={() => setShowUpload(false)} className="photo-cancel">Cancel</button>
                    </div>
                  </form>


                }

              </div>
            </div>
          </div>
          {/* <img src={plus} onClick={() => setUpload(!upload)} className='personal-plus' alt="loading..."></img> */}
          <span style={{ marginLeft: "30px" }} className='heading2'>{name}</span><br></br>
        </div>
        <div div className='profile-pic-head4'>

        </div>
      </div>
      <form onSubmit={handleSubmit}>
        < div className='profile-forms'>
          <span class="personal-heading ">
            <span class=" heading-count">1</span> Personal information
          </span><br></br>
          <hr className='hr1'>
          </hr>
          <div className='creation-profile1' >
            <h1 class="personal-heading4 ">
              <span class=" heading-count2">1</span>Personal information
            </h1><br></br>
            <label for="persnol info" class="profile-label bold" >Personal Info</label><br></br><br></br>
            <textarea value={description} onChange={e => setDescription(e.target.value)} type="text" id="personal-info" rows="10" cols="50" size="60" class="profile-info6" ></textarea><br></br>
            <div style={{
              marginTop: "50px",
            }}>
              <label for="Education" class="profile-label bold">Education</label>
              <div className='education'>
                <div className='label-flex'>
                  <label for="Degree" class="profile-label">Degree<span className="">*</span></label>
                </div>
                <div className='profile-input'>
                  <input type="text" value={sellerDegree} onChange={e => setSellerDegree(e.target.value)} class="profile-info" size="60" name="degree"></input>
                </div>
                <div className='label-flex'>
                  <label for="college" class="profile-label">College<span className="">*</span></label>
                </div>
                <div className='profile-input'>
                  <input type="text" value={sellerCollege} onChange={e => setSellerCollege(e.target.value)} class="profile-info" size="60" name="college"></input>
                </div>
              </div>
            </div>
            <div className='education'>
              <div className='label-flex2'>
                <label for="college" class="profile-label">Stream<span className="">*</span></label>
              </div>
              <div className='profile-input2'>
                <input type="text" value={sellerStream} onChange={e => setSellerStream(e.target.value)} class="profile-info" name="diploma"></input>
              </div>
            </div>

            <div className='education' style={{
              marginTop: "30px"
            }}>
              <div className='label-flex3'>
                <label for="college" class="profile-label">Languages<span className="">*</span></label>
              </div>
              <div className='profile-input3'>
                <Multiselect options={Languages}  selectedValues={selectedLanguage} onSelect={(selectedList) => setSelectedLanguage(selectedList)}
                  onRemove={(selectedList) => setSelectedLanguage(selectedList)}
                  displayValue="language" placeholder="Select Known Languages" />
              </div>
            </div>
            <span class="personal-heading2"> <span class=" heading-count">2</span> Professional info</span><br></br>
            <hr className='hr2'></hr>

            <div className='profile-step2'>
              <h1 class="personal-heading4 ">
                <span class=" heading-count2">
                  2</span>Professional information
              </h1><br></br>
              <div className='education'>
                <div className='label-flex4'>
                  <label class="profile-label">Countries you specialize in<span className="">*</span></label>
                </div>
                <div className='profile-input4'>
                  <Multiselect options={country}  selectedValues={selectedCountry} onSelect={(selectedList) => setSelectedCountry(selectedList)}
                    onRemove={(selectedList) => setSelectedCountry(selectedList)}
                    displayValue="countryName" placeholder="Select Countries" />
                </div>
              </div>
              <div className='education'>
                <div className='label-flex4'>
                  <label class="profile-label">Degree you specialize in<span className="">*</span></label>
                </div>
                <div className='profile-input4'>
                  <Multiselect options={degrees}  selectedValues={selectedDegree} onSelect={(selectedList) => setSelectedDegree(selectedList)}
                    onRemove={(selectedList) => setSelectedDegree(selectedList)}
                    displayValue="degree" placeholder="Select Degrees" />
                </div>
              </div>
              <div className='education'>
                <div className='label-flex4'>
                  <label class="profile-label">Disciplines you specialise in<span className="">*</span></label>
                </div>
                <div className='profile-input5'>
                  <Multiselect options={disciplines}  selectedValues={selectedDiscipline} onSelect={(selectedList) => setSelectedDiscipline(selectedList)}
                    onRemove={(selectedList) => setSelectedDegree(selectedList)}
                    displayValue="discipline" placeholder="Select Disciplines" />
                </div>
              </div>

            </div>
            <span class="personal-heading3"><span class=" heading-count" >3</span><span style={
              {
                position: "relative",
                left: "4%"
              }
            }>Verification</span></span>
            <div className='profile-step2'>
              <h1 class="personal-heading4 ">
                <span class=" heading-count2">3</span>Verification
              </h1><br></br>
              <div className='education'>
                <div className='label-flex5'>
                  <label class="profile-label">Email<span className="">*</span></label>
                </div>
                <div className='profile-input4'> {email}</div>
              </div>
              <div className='education'>
                <div className='label-flex5'>
                  <label class="profile-label">TOPMATE<span className="">*</span></label>
                </div>
                <div className='profile-input4'><input type="text" value={link} onChange={e => setLink(e.target.value)} class="profile-info"  size="60" name="phone" placeholder="Enter valid number"></input>
                </div>
              </div>
              <div className='education'>
                <div className='label-flex5'>
                  <label class="profile-label">Phone Number<span className="">*</span></label>
                </div>
                <div className='profile-input4'>
                  <input type="tel" value={contactNumber} onChange={e => setContactNumber(e.target.value)} class="profile-info" maxLength="10" size="60" name="phone" placeholder="Enter valid number"></input>
                  {/* {(setContactNumber) && (
                  <div className='getotpdiv'>
                    <button className='getOtp' onClick={getOtp}> Get OTP</button>
                  </div>
                )}  */}
                </div>
              </div>

              {/* <div className='profile-input2'>
                <input type='text' value={otp} onChange={e => setOtp(e.target.value)} className='profile-info' placeholder='OTP'></input>
                <div className='getotpdiv'>
                  <button className='getOtp2' onClick={getOtp}> Resend </button>
                </div>
              </div>

              <div className='profile-input2'>
                <button className='verify' onClick={verifyOtp}> Verify</button>
              </div>
            </div> */}


              {/* <div className='education'>
              <div className='label-flex5'>
                <label class="profile-label">PAN<span className="">*</span></label>
              </div>
              <div className='profile-input4'>
                <input type='file' value={pan} onChange={e => setPanUrl(e.target.value)} id="file"></input>
                {pan && (
                  <div>
                    <img src={panUrl} alt="Preview" />
                    <span>{pan.name}</span>
                  </div>
                )}
              </div>
            </div> */}

              <div className='education'>
                <div className='label-flex5'>
                  <label class="profile-label">GST Number<span className=""></span></label>
                </div>

                <div className='profile-input4'>
                  <input type="text" value={GST} onChange={e => setGST(e.target.value)} class="profile-info" size="60" placeholder="Enter your GST Number" ></input>
                </div>
                <div className='profile-input4'>
                  <input type="text" value={pricing} onChange={e => setPrice(e.target.value)} class="profile-info" size="60" placeholder="pricing" ></input>
                </div>
              </div>

            </div>
          </div>

          <div className='check-flex'>
            <div>
              <input type="checkbox" style={{ marginTop: "9px" }}  className='' value={isSellerAgreed} onChange={e => setIsSellerAgreed(e.target.value)}></input>
            </div>
            <div>
              <label
                className='check-label'>By ticking this, you agree to privacy policy and terms and conditions of our platform. Your personal & financial information will not be shared with any third party apps/companies and is strictly for internal use only.</label>
            </div>
          </div>
          <button type='submit' className='profile-save'>Save</button>

          <Footer />
          <Toaster />
        </div>
      </form>
    </div>
  )
}
export default Sellerprofile