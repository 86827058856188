import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import axios from 'axios';
// import { saveNavigationVariableAction } from "./../../Redux/actions/serviceCardActionCreator"
// import { APICall } from '../../Redux/actions/serviceCardActionCreator';
import { useDispatch, useSelector } from "react-redux";
const illustration5 = new URL("../../images/image 20.webp", import.meta.url);
const noprofile = new URL("../../images/noProfilepic.webp", import.meta.url)
const rating5 = new URL("../../images/1star.webp", import.meta.url)
function SellerService() {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [data, setData] = useState('');
  // const data = useSelector(state => state.fetched_service_data)
  const sellerId = useSelector(state => state.get_seller_profileView_id);
  const dispatch = useDispatch();
  const baseUrl = process.env.REACT_APP_BASE_URL_DEV;

  useEffect(() => {

    const getSellerCards = async() => {
      try{
        const response = await axios.get(`${baseUrl}/api/v1/services/Get-All-Hosted-Services-Seller/${sellerId}`);
        console.log(response, "response");
        console.log(response.data.data)
        setData(response.data.data);
      }catch(err){
        console.log(err)
      }
    }
    getSellerCards();
  }, [sellerId])
  
  // const BuyServiceClick = (e, serviceId) => {
  //   e.preventDefault();
  //   navigate(`/buy-service`, dispatch(saveNavigationVariableAction(serviceUrl, obj, serviceId)));
  // }

  return (
    <>
      {data.length !== 0 ?
        data.map((item) => {
          return (

            <div key={item._id} class="carousel-cell5 " >
              <div className="service-card2">
                <img width="60px" className="service-illus" src={`${process.env.PUBLIC_URL}/sellerCardIllus/${item.serviceCategory}.webp`} alt="loading..."></img>
              </div>
              <span style={{
                width: "15%",
                borderRadius: "50%",
                height: "px",
                padding: "10px 20px 10px 20px",

                position: "absolute",
                left: "1.5%",
                top: "97px",
              }}>
                
                <img width="40px"height="40px" style={{borderRadius:"50%"}} heigh="40px" src={item.seller.length !==0  && item.seller[0].photo !== undefined ? `https://kounselodata.s3.ap-south-1.amazonaws.com/${item.seller[0].photo}` : noprofile} alt = "loading..."></img></span>


              <span className="cardName2">{(item.seller !== undefined) ? item.seller[0].name : ''}</span><span className="card-country" style={{
                padding: "2px",
                position: "relative",
                left: "30%",
                top: "9px",
              }}>
                <span style={{ position: "relative", left: "20px" }}>Country</span> <br></br>
                <img src={`${process.env.PUBLIC_URL}/countryFlags/${item.country}.webp`} class="seller-card-flag" alt="loading..." width="10%"></img>
              </span>
              <br></br>
              <div className="rating-digit">
                4.3<img style={{
                  position: "relative",
                  top: "-1px",
                  left: "2px"
                }} width="3%" src={rating5} alt="loading..."></img>
              </div>
              <hr style={{
                position: "relative",
                width: "90%",
                top: "-10px",
                margin: "10px auto",
                color: "black",
                fontWeight: "bolder"
              }}></hr>

              <div className="service-head">
                {(item.title_Service.length > 30) ? item.title_Service.slice(0, 30) : item.title_Service}
              </div>
              <div className="service-content">
                {(item.description.length > 125) ? item.description.slice(0, 125) + '...' : item.description}
              </div>
              <div>
                <br></br>
                <div >
                  <span className="INR2 INR5">Price:</span>
                  <span className="INR2 INR3">INR</span><span className="INR2 INR4">{item.pricing}/-</span>
                </div>
              </div>

            </div>

          )
        }) : null
      }
    </>
  )
}
export default SellerService