import React from "react";
const illustration5=new URL("../../images/qr.webp",import.meta.url)
function PayNow(){
    return(
        <div>
            <h1 style={{
                position:"relative",
                top:"80px",
                color: "#7D7EC7"

            }}>Payment option 

            </h1>
            <div style={{
                position:"relative",
                top:"100px"

            }}>Qr code</div>
            <img  style={{
                position:"relative",
                top:"100px"
            }}src={illustration5} width="300px" alt = "loading..."></img>
        </div>
    )
    }

export default PayNow