
import React from "react";
import "../../styles/userLanding.css"
const illustration5 = new URL("../../images/image 20.webp", import.meta.url)
const illustration11 = new URL("../../images/CA.webp", import.meta.url)
const rating5 = new URL("../../images/1star.webp", import.meta.url)
const noprofile = new URL("../../images/noProfilepic.webp", import.meta.url)

function DashCards2({data}) {
  
  return (
    <>
      {(data !== undefined && data.length !== 0) ?
        data.map((item) => {
          return (
            <div key = {item._id} class="carousel-cell15 carousel-cell4" >

              <div className="service-card2">
                <img width="35%" src={illustration5} alt="loading..."></img>
                <hr style={{
                  position: "relative",
                  width: "90%",
                  top: "-10px",
                  left: "5%"


                }}></hr>
              </div>
              <span style={{
                    width: "15%",
                    borderRadius: "50%",
                    height: "px",
                    padding: "10px 20px 10px 20px",
                   
                    position: "absolute",
                    left: "1.5%",
                    top: "97px",
                  }}> <img width="40px" src={noprofile} alt = "loading..."></img> </span>   <span className="cardName3">{(item.seller !== undefined) ? item.seller[0].name : ''}</span><span className="card-country2" style={{
                background: "",

                padding: "2px",

                position: "relative",
                left: "30%",
                top: "-20px",

              }}>
                <span style={{position:"relative",left:"20px"}}>Country</span><br></br>
                <img src={illustration11} class="seller-card-flag2" alt="loading..." width="5%"></img>
              </span>
              <br></br>
              <div className="rating-digit2">
                4.3<img style={{
                  position: "relative",
                  top: "-1px",
                  left: "2px"
                }} width="2%" src={rating5} alt="loading..."></img>
              </div>

              <div className="service-head">
                {item.title_Service}
              </div>
              <div className="service-content"
              >
                {item.description}
              </div>
              <div>

                <br></br>
                <span className="INR2 INR5">price:</span>
                <span className="INR2 INR12" style={{

                }}>INR</span><span className="INR2 INR11" style={{

                }}>{item.pricing}/-</span>
              </div>

            </div>
          )
        }) : null
      }

    </>)
}
export default DashCards2