import React, { useState, useEffect } from "react";
import { Link, useNavigate } from 'react-router-dom';
import toast, { Toaster } from 'react-hot-toast';
import { useSelector } from "react-redux";
import axios from "axios";
import Footer from "../footer/Footer";
import DashSlider from "./sellerDashSlider1";
import DashSlider2 from "./sellerDashSlider2";
import DashSlider4 from "./dashSlider3";
import DashCards2 from "./dash-card2"
import Multiselect from 'multiselect-react-dropdown';
const level = new URL("../../images/level.webp", import.meta.url)
const dash = new URL("../../images/dash-edit2.webp", import.meta.url)
const earning = new URL("../../images/my-earning.webp", import.meta.url)
const myACC = new URL("../../images/my-acc.webp", import.meta.url)
const fiveStars = new URL("../../images/5stars.webp", import.meta.url)
const myearningBlack = new URL("../../images/my-earningBlack.webp", import.meta.url)
const noprofile = new URL("../../images/noProfilepic.webp", import.meta.url)
const plus = new URL("../../images/plus2.webp", import.meta.url)
function SDashboard() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const sellerId = useSelector(state => state.get_seller_profile_id.seller_id);
  const [show, setShow] = useState("kounselo")
  const [show2, setShow2] = useState(true)
  const [show3, setShow3] = useState(true)
  const [show4, setShow4] = useState(true)
  const [showdeletOption, setDelete] = useState(false)
  const [upload, setUpload] = useState(false)
  const navigate = useNavigate();
  const [selectedImage, setSelectedImage] = useState(null);
  const sellerRole = useSelector((state) => state.get_seller_profile_id.role);
  const userRole = useSelector((state) => state.get_user_id.role);
  const [file, setFile] = useState(null);

  const handleFileChange = (event) => {
    console.log(event.target.value)
    setFile(event.target.files[0]);
  };

  const handleSubmitphoto = async () => {
    const formData = new FormData();
    formData.append('photo', file);
    try {
      const response = await axios.patch(`${baseUrl}/api/v1/sellers/uploadSellerPhoto/${sellerId}`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' }
      });
      console.log(response.data); // Handle the response from the server
      if (response.data.status === "success") {
        toast.success("Profile Photo Updated Sucessfully");
      }
      // setFile(response.data);
    } catch (error) {
      console.error(error);
    }
  };



  const[showUpload,setShowUpload]=useState(false)
  useEffect(() => {
    if (!sellerRole && !userRole) {
      navigate('/', {
        replace: true,
        state: {
          signIn: true,
          type: "counselor"
        }

      })
    } else if (userRole) {
      toast.error('You are not allowed to open this url');
      navigate('/userLanding');
    }
  }, [navigate, sellerRole, userRole])


  const country = [
    {
      countryName: "Belize",
    },
    {
      countryName: "Solvenia"
    },
    {
      countryName: "Hungary"
    },
    {
      countryName: "Estonia"
    },
    {
      countryName: "lithuania"
    },
    {
      countryName: "Thailand"
    },
    {
      countryName: "Greece"
    },
    {
      countryName: "Kyrgyzstan"
    },
    {
      countryName: "Armenia"
    },
    {
      countryName: "Belarus"
    },

    {
      countryName: "Khazakisthan"
    },
    {
      countryName: "Norge Unions"
    },
    {
      countryName: "Bosnia & Herzegovina "
    },
    {
      countryName: "South Africa"
    },
    {
      countryName: "Portugal"
    },
    {
      countryName: "Belgium"
    },
    {
      countryName: "Turkey"
    },
    {
      countryName: "Georgia"
    },
    {
      countryName: "Ukrane"
    },
    {
      countryName: "UK"
    },
    {
      countryName: "US"
    },
    {
      countryName: "Vietnam"
    },
    {
      countryName: "Japan"
    },
    {
      countryName: "Austria"
    },
    {
      countryName: "Cyprus"
    },
    {
      countryName: "Europe "
    },
    {
      countryName: "Bulgaria"
    },
    {
      countryName: "Bangladesh"
    },
    {
      countryName: "Barbados"
    },

    {
      countryName: "Russia"
    },

    {
      countryName: "Malaysia"
    },
    {
      countryName: "Mauritius"
    },
    {
      countryName: "Malta"
    },
    {
      countryName: "Latvia"
    },
    {
      countryName: "Philippines"
    },
    {
      countryName: "China "
    },
    {
      countryName: "Slovenia"
    },
    {
      countryName: "South korea"
    },

    {
      countryName: "New Zealand"
    },
    {
      countryName: "Hong Kong"
    },
    {
      countryName: "Singapore"
    },
    {
      countryName: "Norway"
    },
    {
      countryName: "UAE"
    },
    {
      countryName: "United Kingdom"
    },
    {
      countryName: "Switzerland"
    },
    {
      countryName: "Thailand"
    },
    {
      countryName: "Luxembourg"
    },
    {
      countryName: "Turkey"
    },
    {
      countryName: "Spain"
    },
    {
      countryName: "Sweden"
    },

    {
      countryName: "Poland"
    },
    {
      countryName: "Netherlands"
    },
    {
      countryName: "Italy"
    },
    {
      countryName: "France"
    },
    {
      countryName: "Finland"
    },
    {
      countryName: "Ireland"
    },
    {
      countryName: "Denmark"
    },
    {
      countryName: "Australia"
    },
    {
      countryName: "Canada"
    },
    {
      countryName: "Kazakhastan"
    },
    {
      countryName: "Maritius"
    },
    {
      countryName: "USA"
    },
    {
      countryName: "Germany"
    },
  ]

  const degrees = [
    {
      degree: "Bachelor"
    },
    {
      degree: "Master"
    },
    {
      degree: "MBA"
    },
    {
      degree: "PHD"
    },
  ];

  const disciplines = [
    {
      discipline: "Applied Sciences & Profession",
    },
    {
      discipline: "Art",
    },
    {
      discipline: "Bussiness",
    },
    {
      discipline: "Engineering Technology",
    },
    {
      discipline: "Economics",
    },
    {
      discipline: "Environmental Sciences",
    },
    {
      discipline: "Humanities",
    },
    {
      discipline: "Internship_English",
    },
    {
      discipline: "Law",
    },
    {
      discipline: "Life Sciences and Medical Health",
    },
    {
      discipline: "Management",
    },
    {
      discipline: "Natural Sciences",
    },
    {
      discipline: "Social_Sciences",
    },
    {
      discipline: "Agriculture Production",
    },
    {
      discipline: "Agriculture Sports",
    },
  ];

  const Languages = [
    {
      language: 'English'
    },
    {
      language: 'Hindi'
    },
    {
      language: "Bengali"
    },
    {
      language: "Telugu"
    },
    {
      language: "Marathi"
    },
    {
      language: "Tamil"
    },
    {
      language: "Urdu"
    }
    ,
    {
      language: "Gujarati"
    },
    {
      language: 'Kannada',
    },
    {
      language: "Punjabi"
    },
    {
      language: 'Malayalam',
    },
    {
      language: "Odia"
    },
    {
      language: "Assamese"
    },
    {
      language: "Kashmiri"
    },
    {
      language: "Sindhi"
    },
    {
      language: "Konkani"
    },
    {
      language: "Manipuri"
    },
    {
      language: "Nepali"
    }
  ];

 
  const sellerName = useSelector(state => state.get_seller_profile_id.name);
  const baseUrl = process.env.REACT_APP_BASE_URL_DEV;
  const [data, setData] = useState([]);

  useEffect(() => {
    const getSellerProfile = async () => {
      try {
        const response = await axios.get(`${baseUrl}/api/v1/sellers/getSellerProfile/${sellerId}`);
        setData([response.data.data.seller]);
      }
      catch (err) {
        // console.log(err);
      }
    }
    getSellerProfile();
  }, [sellerId]);


  const [selectedCountry, setSelectedCountry] = useState([]);
  const [selectedDiscipline, setSelectedDiscipline] = useState([]);
  const [selectedDegree, setSelectedDegree] = useState([]);
  const [selectedLanguage, setSelectedLanguage] = useState([]);
  const [description, setDescription] = useState('')
  const [sellerDegree, setSellerDegree] = useState('')
  const [sellerStream, setSellerStream] = useState('')
  const [sellerCollege, setSellerCollege] = useState('')

  useEffect(() => {
    if (data === undefined || data.length === 0) return
    setDescription(data[0].personalDescription !== null ? data[0].personalDescription : '');
    setSellerDegree(data[0].sellerProfessionalDegree !== null ? data[0].sellerProfessionalDegree : '');
    setSellerStream(data[0].sellerStream !== null ? data[0].sellerStream : '');
    setSellerCollege(data[0].sellerUniversity !== null ? data[0].sellerUniversity : '');

    if (data[0].sellerLanguageKnown) {
      const modifiedLanguage = data[0].sellerLanguageKnown.map((item) => ({
        language: item
      }));
      setSelectedLanguage(modifiedLanguage);
    }

    if (data[0].sellerCountrySpecialization) {
      const modifiedCountry = data[0].sellerCountrySpecialization.map((item) => ({
        countryName: item
      }));
      setSelectedCountry(modifiedCountry);
    }
    if (data[0].sellerDegrees) {
      const modifiedDegrees = data[0].sellerDegrees.map((item) => ({
        degree: item
      }));
      setSelectedDegree(modifiedDegrees);
    }
    if (data[0].sellerDisciplineSpecialization) {
      const modifiedDiscipline = data[0].sellerDisciplineSpecialization.map((item) => ({
        discipline: item
      }));
      setSelectedDiscipline(modifiedDiscipline);
    }
  }, [data])

  const handleDelete = async () => {
    const response = await axios.delete(`${baseUrl}/api/v1/sellers/deleteSeller/${sellerId}`);
    if (response.data.status === 'success') {
      navigate('/');
    }
  }

  const handleCancel = () => {
    navigate('/counselor-dashboard')
  }


  const sellerLanguage = selectedLanguage.reduce((acc, selectedLanguage) => acc.concat(selectedLanguage.language), []);
  const sellerCountry = selectedCountry.reduce((acc, selectedCountry) => acc.concat(selectedCountry.countryName), []);
  const sellerDegrees = selectedDegree.reduce((acc, selectedDegree) => acc.concat(selectedDegree.degree), []);
  const sellerDiscipline = selectedDiscipline.reduce((acc, selectedDiscipline) => acc.concat(selectedDiscipline.discipline), []);

  const updateSeller = {
    personalDescription: description,
    sellerUniversity: sellerCollege,
    sellerProfessionalDegree: sellerDegree,
    sellerStream: sellerStream,
    sellerLanguageKnown: sellerLanguage,
    sellerCountrySpecialization: sellerCountry,
    sellerDisciplineSpecialization: sellerDiscipline,
    sellerDegrees: sellerDegrees,

  }
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.patch(`${baseUrl}/api/v1/sellers/updateSeller/${sellerId}`, updateSeller);
      if (response.data.status === "success") {
        toast.success("Profile Updated Successfully");
        navigate('/counselor-dashboard');
      }
    } catch (err) {
      // console.log(err)
      toast.error("Something went wrong, Please Try Again Later");
    }
  };
  const renderTab = (show) => {
    switch (show) {
      default:
        return (
          <div className="dash-content1">
            <div className="dash-button2">
              <div><h4 className="dash-hosted button-phone">Hosted Services</h4></div>
              <div>
                <Link to="/create-service"><button className="create-service">+ Create service</button></Link>
                <Link title="comming soon" to=""><button className="manage-service"> <img width="15px" class="level" src={level} alt="loading..."></img> Manage orders</button>
                </Link></div>
              <div className="button-mobile"><h4 className="dash-hosted " style={{

              }}>Hosted Services</h4></div>
            </div>

            {show4 ? <div>
              <DashSlider />
            </div> :
              <div style={{
                display: "flex",
                flexWrap: "wrap",
                marginTop: "300px"
              }}>

              </div>
            } 
            <div>
              <Link to="/all-hosted-services" className="dash-view" > View all</Link>
            </div>
            <div className="sellerDashcard">
            </div>
            <h4 className="ongoing" >
              Ongoing orders
            </h4>
            <div className=" dash-content2">
              {show3 ?
                <div>
                  <DashSlider4 />
                </div> :
                <div style={{
                  display: "flex",
                  flexWrap: "wrap"
                }}>
                  <DashCards2 />
                </div>
              }
              <div>
                <Link to="/all-ongoing-services" className="dash-view" > View all</Link>
              </div>
            </div>
            <div className="dash-content3">
              <h4 className="draft">
                Drafts
              </h4>

              {show2 ? <div >
                <DashSlider2 />
              </div> :
                <div style={{
                  display: "flex",
                  flexWrap: "wrap"
                }}>

                </div>
              }
              <div>
                <Link to="/all-drafted-services" className="dash-view" > View all</Link>

              </div>
              <div style={{
                marginTop: "100px"
              }}>
                <Footer />
              </div>
            </div>


          </div>
        )

      case "kounselo2":
        return (
          <div>
            <div className="sellerDash2">
              <div>
                <div className="avialable-funds">
                  Funds avialable
                </div>
                <h1 className="my-funds">
                  00
                </h1>
                <div className="last-seen">Last updated: 5 March 2023 at 1:55 PM</div>
                <button className="dash-getPayout">Get payout</button>
                <hr style={{
                  margin: "50px auto"
                }}></hr>
                <div className="revenew" style={{

                }}>
                  <div className="seller-month">
                    <div>Revenue this month</div>
                    <h1 className="my-funds">
                      00
                    </h1>
                    <div className="last-seen">Last updated: 5 March 2023 at 1:55 PM</div>
                  </div>
                  <div>
                    <div className="seller-date">Revenue to date</div>
                    <h1 className="my-funds">
                      00
                    </h1>
                    <div className="last-seen">Last updated: 5 March 2023 at 1:55 PM</div>
                  </div>
                </div>
                <hr style={{
                  margin: "50px auto"
                }}></hr>
                <div className="avialable-funds">
                  Order completed
                  <h4 className="my-funds">00</h4>
                </div>
              </div>

            </div>
            <Footer />
          </div>

        )

      case "kounselo3":
        return (
          <div>
            {(data !== undefined && data.length !== 0) ?
              data.map((item) => {
                return (

                  <div key={item._id} class="profile">
                    <form className='profile-forms'>

                      <div className='creation-profile2' >
                        <label for="persnol info" class="profile-label profile-margin" >Personal Info<button className="dash-edit-color"> <img src={dash} class="edit-dash" alt="loading..."></img></button></label><br></br>
                        <textarea type="text" id="personal-info" rows="10" cols="50" size="60" class="profile-info6" value={description} onChange={e => setDescription(e.target.value)}></textarea><br></br>
                        <div style={{
                          marginTop: "50px",
                        }}>
                          <label for="Education" class="profile-label">Education  </label>
                          <div className='education'>
                            <div className='label-flex'>
                              <label for="Degree" class="profile-label">Degree<button className="dash-edit-color"> <img src={dash} class="edit-dash" alt="loading..."></img></button></label>
                            </div>
                            <div className='profile-input'>
                              <input type="text" class="profile-info" size="60" name="degree" value={sellerDegree} onChange={e => setSellerDegree(e.target.value)}></input>
                            </div>
                            <div className='label-flex'>
                              <label for="college" class="profile-label">College<button className="dash-edit-color"> <img src={dash} class="edit-dash" alt="loading..."></img></button></label>
                            </div>
                            <div className='profile-input'>
                              <input type="text" class="profile-info" size="60" name="college" value={sellerCollege} onChange={e => setSellerCollege(e.target.value)}></input>
                            </div>
                          </div>
                        </div>
                        <div className='education'>
                          <div className='label-flex2'>
                            <label for="college" class="profile-label">Stream<button className="dash-edit-color"> <img src={dash} class="edit-dash" alt="loading..."></img></button></label>
                          </div>
                          <div className='profile-input2'>
                            <input type="text" class="profile-info" name="diploma" value={sellerStream} onChange={e => setSellerStream(e.target.value)}></input>
                          </div>
                        </div>

                        <div className='education' style={{
                          marginTop: "30px"
                        }}>
                          <div className='label-flex3'>
                            <label for="college" class="profile-label">Languages<button className="dash-edit-color"> <img src={dash} class="edit-dash" alt="loading..."></img></button></label>
                          </div>
                          <div className='profile-input3'>
                            <div className="col-md-10">
                              <label className="form-label"> </label>

                              <div className="text-dark">
                                <Multiselect options={Languages} required selectedValues={selectedLanguage} onSelect={(selectedList) => setSelectedLanguage(selectedList)}
                                  onRemove={(selectedList) => setSelectedLanguage(selectedList)}
                                  displayValue="language" placeholder="Select Known Languages" />
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className='profile-step2'>
                          <div className='education'>
                            <div className='label-flex4'>
                              <label class="profile-label">Countries you specialize in<button className="dash-edit-color"> <img src={dash} class="edit-dash" alt="loading..."></img></button></label>
                            </div>
                            <div className='profile-input4'>
                              <div className="col-md-10">
                                <label className="form-label"> </label>
                                <div className="text-dark">
                                  <Multiselect options={country} required selectedValues={selectedCountry} onSelect={(selectedList) => setSelectedCountry(selectedList)}
                                    onRemove={(selectedList) => setSelectedCountry(selectedList)}
                                    displayValue="countryName" placeholder="Select Countries" />
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className='education'>
                            <div className='label-flex4'>
                              <label class="profile-label">Degree you specialize in<button className="dash-edit-color"> <img src={dash} class="edit-dash" alt="loading..."></img></button></label>
                            </div>
                            <div className='profile-input4'>
                              <div className="col-md-10">
                                <label className="form-label"> </label>

                                <div className="text-dark">
                                  <Multiselect options={degrees} required selectedValues={selectedDegree} onSelect={(selectedList) => setSelectedDegree(selectedList)}
                                    onRemove={(selectedList) => setSelectedDegree(selectedList)}
                                    displayValue="degree" placeholder="Select Degrees" />

                                </div>
                              </div>

                            </div>
                          </div>
                          <div className='education'>
                            <div className='label-flex4'>
                              <label class="profile-label">Disciplines you specialise in<button className="dash-edit-color"> <img src={dash} class="edit-dash" alt="loading..."></img></button></label>
                            </div>
                            <div className='profile-input5'>
                              <div className="col-md-10">
                                <label className="form-label"> </label>

                                <div className="text-dark">
                                  <Multiselect options={disciplines} required selectedValues={selectedDiscipline} onSelect={(selectedList) => setSelectedDiscipline(selectedList)}
                                    onRemove={(selectedList) => setSelectedDegree(selectedList)}
                                    displayValue="discipline" placeholder="Select Disciplines" />

                                </div>
                              </div>
                            </div>
                            <div className='profile-input5'>
                              <div className="my-selects2">
                                <div style={{
                                  width: "100%"
                                }}>

                                </div>
                              </div>
                            </div>
                          </div>

                        </div>

                        <div className='profile-step2'>
                          <div className='education'>
                            <div className='label-flex5'>
                              <label class="profile-label">Phone Number<button className="dash-edit-color"> <img src={dash} class="edit-dash" alt="loading..."></img></button></label>
                            </div>
                            <div className='profile-input4'>
                              <div type="tel" class="profile-info" size="60" name="phone">{item.contactDetails}</div>
                            </div>


                          </div>
                          <div className='education'>
                            <div className='label-flex5'>
                              <label class="profile-label">Email<button className="dash-edit-color"> <img src={dash} class="edit-dash" alt="loading..."></img></button></label>
                            </div>
                            <div className='profile-input4'>
                              <div type="email" class="profile-info" size="60" name="email" placeholder=""  >{item.email}</div>
                            </div>
                          </div>

                        </div>
                      </div>
                      <div className="delete-my-acc">
                        <div className="savecancel">

                          <button className='profile-save2' onClick={handleSubmit}>Save</button>
                          <button className='profile-cancel2' onClick={handleCancel}>Cancel</button>
                        </div>
                        <button onClick={handleDelete} className="profile-delete-dash">
                          Delete My Account
                        </button>
                      </div>
                    </form>
                    <Footer />
                  </div>
                )
              }) : null
            }
          </div>
        )
    }
  }
  return (
    <div>
      {data !== undefined && data.length !== 0 ?
        data.map((item) => {
          return (
            <div key={item._id} className="Dash-head">
              <div className="dash-photo">
                <img width="120px" height="120px"   style={{borderRadius:"50%"}} src={item.photo !== undefined ? `https://kounselodata.s3.ap-south-1.amazonaws.com/${item.photo}` : noprofile} ></img>
                   
              </div>
              
            <div  style={{margin:"auto",width:"20px",position:"relative",top:"-75px"}}>
            <img onClick={()=>setShowUpload(true)} width="20px" src={plus}></img>

           
            </div>
            { showUpload &&
              <form onSubmit={handleSubmitphoto}>
         
               
                <div onMouseLeave={()=>setUpload(false)} className="upload-divss"style={{
                                 border:"1px solid black",
                                 margin:"auto"
                               }}>
                               
                               <div class="upload" style={{
                                 margin:"auto",
                                 width:"100px"
                       
                               }}>
                                 <div>
                             <button type = "button" class = "btn-warning">
                               <i class = "fa fa-upload"></i> Browse
                               <input onChange={handleFileChange} type="file"/>
                             </button>
                            </div>
                           </div>
                        
                         
                           <hr style={{
                               margin:"10px auto"
                             }}></hr>
                 
                           <button type="submit" className="button-upload" style={{
                             margin:"auto"
                           }}>upload</button>   
                           <button onClick={()=>setShowUpload(false)} className="photo-cancel">Cancel</button>
                           </div>
                       </form>
             
                               
                          } 
             
              <h2 className="dash-name">{sellerName}</h2>
              <h6 className="dash-rating">Overall rating (4.5/5) <img className="dash-rating-head" width="100px" src={fiveStars} alt="loading..."></img></h6>
            </div>
          )
        }) : null
      }

      <div className="dash-button">
        <button className={`seller-dash-button ${show === "kounselo" ? "active2" : ""}`} onClick={() => setShow("kounselo")} ><img src={myearningBlack} className="level" width="15px" alt="loading..."></img> My work</button>

        <button className={`seller-dash-button ${show === "kounselo2" ? "active2" : ""}`} onClick={() => setShow("kounselo2")}><img src={earning} className="level" width="15px" alt="loading..."></img> My earning</button>

        <button className={`seller-dash-button ${show === "kounselo3" ? "active2" : ""}`} onClick={() => setShow("kounselo3")}
        ><img src={myACC} className="level" width="15px" alt="loading..."></img> My account</button></div>
      {renderTab(show)}
      <Toaster />
    </div>
  )
}
export default SDashboard