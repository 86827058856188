import React from "react";
import Footer from "../footer/Footer";
import axios from "axios";
import toast, { Toaster } from 'react-hot-toast';
import { useEffect,useState } from "react";

const illustration7 = new URL("../../images/multi.webp", import.meta.url)
const point = new URL("../../images/point.webp", import.meta.url)
const man = new URL("../../images/man.webp", import.meta.url)
const hat = new URL("../../images/Graduation Hat.webp", import.meta.url)
const coin = new URL("../../images/coin.webp", import.meta.url)
const globe = new URL("../../images/globe-search.webp", import.meta.url)
const sales = new URL("../../images/sales.webp", import.meta.url)
function HowUser() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [contact, setContact] = useState("");

    const baseUrl = process.env.REACT_APP_BASE_URL_DEV;

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
          const response = await axios.post(`${baseUrl}/api/v1/inputs/student`, {
            name: name,
            email: email,
            contactno:contact
        
            // isEmailVerified: isEmailVerified
          });
          if (response.data.status === "success") {
            console.log(response)
            toast.success("Thanks for submitting form we will contact you soon");
           
          }
        } catch (error) {
          // console.log(error)
          toast.error("somthing went wrong")
        }
      }
    return (
        <div>
            <center>
                <div className="how-background">
                    <h1 className="how-head" style={{
                        color: "#32326D"
                    }}>
                        HOW TO USE OUR PLATFORM   AS   </h1>
                    <h1 className="how-head2" > A STUDENT</h1>
                </div>
                <hr style={{
                    position: "relative",
                    top: "150px",
                    width: "80%",
                    border: "1px solid black"

                }}>
                </hr>
                <div className="how-content">
                    <div className="step-content1">
                        <h6 className="step-font1">STEP</h6>
                        <h6 className="step-font2">01</h6>
                    </div>
                    <div className="how-content1">
                        <img src={point} className="point" alt="loading..."></img>
                        <img src={point} className="point1" alt="loading..."></img>
                        <div className="how_1">
                            <div style={{
                                display: "flex",
                                justifyContent: "space-between"
                            }}>
                                <div>                 <div>
                                    <h5 className="how-content-head">browse through a variety </h5>
                                    <h5 className="how-content-head">  of services & counselors</h5>
                                </div>
                                    <div className="how-content-head2">
                                        See different services and counselor profiles through various filters
                                    </div>
                                </div>

                                <div style={{

                                }}>
                                    <img src={man} className="how-illus" width="90px" alt="loading..."></img>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="step-content2">
                        <h6 className="step-font1">STEP</h6>
                        <h6 className="step-font2">02</h6>
                    </div>


                    <div className="how-content2">
                        <img src={point} className="point2" alt="loading..."></img>
                        <div className="how_5">
                            <div style={{
                                display: "flex",
                                justifyContent: "space-between"
                            }} >
                                <div style={{

                                }}>
                                    <img src={hat} className="how-illus" width="90px" alt="loading..."></img>
                                </div>
                                <div>
                                    <div>
                                        <h5 className="how-content-head">Select a suitable counselor  </h5>
                                        <h5 className="how-content-head">  for the service you need</h5>
                                    </div>
                                    <div className="how-content-head2">
                                        Counselors on our platform are best for different courses and countries
                                    </div>
                                </div>


                            </div>
                        </div>

                    </div>
                    <div className="step-content3">
                        <h6 className="step-font1">STEP</h6>
                        <h6 className="step-font2">03</h6>
                    </div>
                    <div className="how-content3">
                        <img src={point} className="point-last" alt="loading..."></img>
                        <img src={point} className="point3" alt="loading..."></img>
                        <div className="how_3">
                            <div style={{
                                display: "flex",
                                justifyContent: "space-between"
                            }}>
                                <div>                 <div>
                                    <h5 className="how-content-head"> Pay and get the service </h5>
                                    <h5 className="how-content-head">  </h5>
                                </div>
                                    <div className="how-content-head2">
                                        Make the payment  to your selected counselor for your desired service
                                    </div>
                                </div>

                                <div style={{

                                }}>
                                    <img src={coin} className="how-illus" width="90px" alt="loading..."></img>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>


                <div className="how-content-mobile">

                    <h6 className="step-font2">
                        STEP 1
                    </h6>
                    <div className="how-content4">

                        <div className="how_2">
                            <div style={{
                                display: "flex",
                                justifyContent: "space-between"
                            }}>
                                <div style={{}}>
                                    <div>
                                        <h5 className="how-content-head">browse through a variety </h5>
                                        <h5 className="how-content-head">  of services & counselors</h5>
                                    </div>
                                    <div className="how-content-head2">
                                        See different services and counselor profiles through various filters
                                    </div>
                                </div>

                                <div style={{

                                }}>
                                    <img src={man} className="how-illus" width="90px" alt="loading..."></img>
                                </div>
                            </div>
                        </div>
                    </div>
                    <h6 className="step-font2" style={{
                        marginBottom: "30px"
                    }}>
                        STEP 2
                    </h6>
                    <div className="how_2" style={{
                        width:"96%"                    }}>
                        <div style={{
                            display: "flex",
                            justifyContent: "space-between"
                        }} >
                            <div style={{

                            }}>
                                <img src={hat} className="how-illus" width="90px" alt="loading..."></img>
                            </div>
                            <div>
                                <div>
                                    <h5 className="how-content-head">Select a suitable counselor  </h5>
                                    <h5 className="how-content-head">  for the service you need</h5>
                                </div>
                                <div className="how-content-head2">
                                    Counselors on our platform are best for different courses and countries
                                </div>
                            </div>


                        </div>
                    </div>
                    <h6 className="step-font2" style={{

                    }}>
                        STEP 3
                    </h6>
                    <div className="how-content4">

                        <div className="how_2">
                            <div style={{
                                display: "flex",
                                justifyContent: "space-between"
                            }}>
                                <div>                 <div>
                                    <h5 className="how-content-head"> Pay and get the service </h5>
                                    <h5 className="how-content-head">  </h5>
                                </div>
                                    <div className="how-content-head2">
                                        Make the payment  to your selected counselor for your desired service
                                    </div>
                                </div>

                                <div style={{

                                }}>
                                    <img src={coin} className="how-illus" width="90px" alt="loading..."></img>
                                </div>
                            </div>
                        </div>
                    </div>

                    <span className="step2" style={
                        {


                        }
                    }>STEP</span><br></br>
                    <span className="step2-2" style={{

                    }}>
                        02

                    </span>
                    <h5 className="how-mobile how-mobile2" >STEP 03</h5>

                </div>
            </center>


            <center> <div className="how-content-sec-2">
                <h4 className=" why-choose" style={{



                }}>WHY CHOOSE KOUNSELO?</h4>
                <hr style={{
                    background: "white",
                    border: "2px solid white",
                    width: "200px",
                    position: "relative",
                    top: "-20px"
                }}></hr>
                <div className="how-flex">
                    <div className="how-flex1">
                        <center>
                            <center>
                                <img src={illustration7} style={
                                    {
                                        marginBottom: "20px"
                                    }
                                } width="40px" alt="loading..."></img>
                                <h4 className="how-color2">Create an a la carte of study abroad services on our platform</h4>
                                <h4 className="how-color" style={{

                                }}> Get all the services you  need for your college applications, even if it is just 1 service. Hire the best consultants for each individual task.</h4>
                            </center>
                        </center>

                    </div>
                    <div className="how-flex2">

                        <center>
                            <img style={{
                                marginBottom: "20px"
                            }} src={globe} width="40px" alt="loading..."></img>
                            <h4 className="how-color2" style={{

                                height: "35px"
                            }}>Browse through multiple services </h4>
                            <h4 className="how-color" style={{

                            }}>Counselors offer various services in different categories at a range of prices. Choose any service/package offered individually as per your budget, need!</h4>
                        </center>
                    </div>
                    <div className="how-flex3">
                        <center>
                            <img style={{
                                marginBottom: "20px"
                            }} src={sales} width="40px" alt="loading..."></img>
                            <h4 style={{

                                height: "35px"
                            }} className="how-color2">Experience the convenience</h4>

                            <h4 className="how-color" style={{


                            }}>Get access to several Counselors, see their profile, reviews and qualifications on one platform and communicate with them through our built-in chat feature!</h4>
                        </center>
                    </div>

                </div>
            </div></center>
            <div className="how-lead">
                <h1 className="decide">  Can’t decide  </h1>
                <h1 className="decide">the right counselor?</h1>
                <h5 className="decide2">Drop your details and we’ll help you out</h5>
                <form onSubmit={handleSubmit} className="how-lead-form">


{/* <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-person-fill icon3" viewBox="0 0 16 16">
    <path d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H3Zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z" /> */}
{/* </svg> */}
<input  value={name} onChange={(e)=>setName(e.target.value)} type="text" className="how-form name2" placeholder="Name*"></input><br></br>
{/* <span className="kuonselo-from-heading"><b>email</b></span><br></br> */}
{/* <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-envelope-at-fill icon" viewBox="0 0 16 16">
    <path d="M2 2A2 2 0 0 0 .05 3.555L8 8.414l7.95-4.859A2 2 0 0 0 14 2H2Zm-2 9.8V4.698l5.803 3.546L0 11.801Zm6.761-2.97-6.57 4.026A2 2 0 0 0 2 14h6.256A4.493 4.493 0 0 1 8 12.5a4.49 4.49 0 0 1 1.606-3.446l-.367-.225L8 9.586l-1.239-.757ZM16 9.671V4.697l-5.803 3.546.338.208A4.482 4.482 0 0 1 12.5 8c1.414 0 2.675.652 3.5 1.671Z" />
    <path d="M15.834 12.244c0 1.168-.577 2.025-1.587 2.025-.503 0-1.002-.228-1.12-.648h-.043c-.118.416-.543.643-1.015.643-.77 0-1.259-.542-1.259-1.434v-.529c0-.844.481-1.4 1.26-1.4.585 0 .87.333.953.63h.03v-.568h.905v2.19c0 .272.18.42.411.42.315 0 .639-.415.639-1.39v-.118c0-1.277-.95-2.326-2.484-2.326h-.04c-1.582 0-2.64 1.067-2.64 2.724v.157c0 1.867 1.237 2.654 2.57 2.654h.045c.507 0 .935-.07 1.18-.18v.731c-.219.1-.643.175-1.237.175h-.044C10.438 16 9 14.82 9 12.646v-.214C9 10.36 10.421 9 12.485 9h.035c2.12 0 3.314 1.43 3.314 3.034v.21Zm-4.04.21v.227c0 .586.227.8.581.8.31 0 .564-.17.564-.743v-.367c0-.516-.275-.708-.572-.708-.346 0-.573.245-.573.791Z" />
</svg> */}
<input value={email} onChange={(e)=>setEmail(e.target.value)} type="email" className="how-form email2" placeholder="Email*"></input><br></br>
{/* <span className="kuonselo-from-heading"><b>phone</b></span><br></br> */}
{/* <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-telephone-fill icon2" viewBox="0 0 16 16">
    <path fill-rule="evenodd" d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z" />
</svg> */}
<input value={contact} onChange={(e)=>setContact(e.target.value)} type="text" className="how-form tel2" placeholder="Phone Number*"></input><br></br>

<button type="submit" className="submit2">submit</button>
</form>
            </div>
            <Footer />
        </div>
    )
}
export default HowUser