import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import './signup.css'
import { useDispatch } from "react-redux";
import { getSellerIdFromAuth } from "../../Redux/actions/GetSellerIdFromAuthActionCreators";
import { getUserIdFromAuth } from "../../Redux/actions/GetUserIdActionCreator";
import toast, { Toaster } from 'react-hot-toast';

const illus2 = new URL("../../images/image 10.webp", import.meta.url);

function Signin({ type }) {
  const [show, setShow] = useState("case2")
  const [show2, setShow2] = useState("user")
  const [show3, setShow3] = useState(true)
  useEffect(() => {
    if (type !== '') {
      setShow2(type)
    }
  }, [type])

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [token, setToken] = useState("")
  const [cookie, setCookie] = useState("")
  const [isSuccess, setIsSuccess] = useState(false);
  const [isFailed, setIsFailed] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const baseUrl = process.env.REACT_APP_BASE_URL_DEV;
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handlechange2 = (caseName) => {
    setShow2(caseName)
  }

  //   if (responseStatus > 200 && responseStatus < 299) {
  //     setIsSuccess(true);
  //     setTimeout(() => {
  //       setIsSuccess(false);
  //       navigate("/login");
  //     }, 2000);
  //   } else {
  //     setIsFailed(true);
  //     setIsLoading(false);
  //     setTimeout(() => {
  //       setIsFailed(false);
  //     }, 2000);
  //     alert("registration failed");
  //  }


  const handleLoginSeller = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`${baseUrl}/api/v1/sellers/login`,
        {
          email: email,
          password: password,
          headers: {
            Authorization: `Bearer ${token}`
          },
        });
      if (response.data.status === "success") {
        setToken(response.data.token);
        dispatch(getSellerIdFromAuth(response.data.data.user._id, response.data.data.user.role, response.data.data.user.name, response.data.data.user.email, response.data.data.user.isEmailVerfied));
        toast.success(`You logged in successfully`);
        setShow3(false);
        navigate('/counselor-dashboard');
      }
    } catch (error) {
      // console.log(error)
      toast.error('Incorrect Email or Password');
    }
  }

  const handleLoginUsers = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`${baseUrl}/api/v1/users/login`,
        {
          email: email,
          password: password,
          headers: {
            Authorization: `Bearer ${token}`
          },
        });
      if (response.data.status === "success") {
        setToken(response.data.token);
        dispatch(getUserIdFromAuth(response.data.data.user._id, response.data.data.user.role))
        toast.success(`You logged in successfully`);
        setShow3(false);
        navigate('/userLanding');
      }
    } catch (error) {
      // console.log(error);
      toast.error('Incorrect Email or Password');
    }
  }


  const handleforgotPassword = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`${baseUrl}/api/v1/sellers/forgotPassword`, {
        email: email,
        withCredentials: true
      }

      );
      if (response.data.status === "success") {
        toast.success('Password Reset Link has been sent to your pE-mail')
      }
    } catch (error) {
      // console.log(error.response.data)
    }
  }
  return (
    <div className="signLogin">
      {show3 &&
        <div className="signup-flexs">

          <div style={{
            display: "flex"
          }}>
            <div className="signup-pic">
              <button class="wrong" onClick={() => setShow3(false)} >X</button>
              <h6 className="sinup-pic-content" style={{
                color: "white",
                position: "relative",
                marginTop: "130px"

              }}>How good of you to be<br></br>
                at the right place at the right time!</h6>
              <img src={illus2} class="res-img" width="50%" alt="loading..."></img>
            </div>

            <div className="signup-forms">
              <button class="wrong2" onClick={() => setShow3(false)} >X</button>
              <div className="auth-div">

                <span className={`signup-btn2 ${show === "case1" ? "active" : ""}`}  >Login</span>



                <div class="Signup-heads">Welcome</div>

                <div>


                  <form class="row g-0" >
                    <div className="loginlogin" style={{
                      marginTop: "30px"
                    }}>
                      <span className={`signup-btn ${show2 === "user" ? "active" : ""}`} onClick={() => handlechange2("user")}> User</span>


                      <span className={`signup-btn ${show2 === "counselor" ? "active" : ""}`} onClick={() => handlechange2("counselor")}> Counselor</span><br></br><br></br>
                      {/* counselor */}
                      {show2 === "user" && (
                        <div>
                          <label for="exampleFormControlInput1" class="form-label">Email</label>
                          <input value={email} type="email" class=" signup-info" onChange={(e) => {
                            setEmail(e.target.value);
                          }} required placeholder="example@gmail.com" aria-label="First name" />

                          <label for="exampleFormControlInput1" class="form-label">Password</label>
                          <input pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}" title="Must contain at least one number and one uppercase and lowercase letter, and at least 8 or more characters" value={password} type="password" class=" signup-info" onChange={(e) => {
                            setPassword(e.target.value);
                          }} placeholder="*****" aria-label="First name" required />
                          <button type='submit' onClick={handleLoginUsers} className="sign-button">Login As User</button>


                          <button className="remind" onClick={() => handlechange2("forgot")}>Forgot password ?</button>

                        </div>
                      )}
                      {/* seller */}

                      {show2 === "counselor" && (
                        <div>
                          <label for="exampleFormControlInput1" class="form-label">Email</label>
                          <input required value={email} type="email" class=" signup-info" onChange={(e) => setEmail(e.target.value)} placeholder="example@gmail.com" />

                          <label for="exampleFormControlInput1" class="form-label">Password</label>
                          <input required pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}" title="Must contain at least one number and one uppercase and lowercase letter, and at least 8 or more characters" value={password} type="password" class=" signup-info" onChange={(e) => setPassword(e.target.value)} placeholder="*****" />

                          <button onClick={handleLoginSeller} className="sign-button">Login As Counselor</button>
                          <button className="remind" onClick={() => handlechange2("forgot")}>Forgot password ?</button>
                        </div>

                      )}



                      {/* test */}
                      {/* <input value={email} onChange={(e)=>setEmail(e.target.value)} type="email"></input><br></br>
                            <input value={password} onChange={(e)=>setPassword(e.target.value)}  type="password"></input><br></br>
                            <button onClick={handleLoginSeller}> submit</button>
                       */}


                      {
                        show2 === "forgot" && (


                          <form onSubmit={handleforgotPassword}>

                            <label className="Signup-heads3"> Forgot your Password! Submit your detail below </label><br></br>

                            <label for="exampleFormControlInput1" class="form-label">Enter your email</label>
                            <input type="email" class=" signup-info" onChange={(e) => {
                              setEmail(e.target.value);
                            }} placeholder="example@gmail.com" aria-label="First name" />



                            <button type='submit' className="getLink">Get Link</button>
                            <button className="remind" >Resend Link</button>
                          </form>)

                      }
                      <div style={{
                        display: "flex",
                        marginBottom: "30px",
                        marginTop: "12px"
                      }}>

                      </div>

                      {/* <h5 style={{
                        textAlign: "center"
                      }}>Or</h5>

                      <button className="sign-goggle">Sign in with google</button> */}
                    </div>
                    <h5 className="need" style={{
                      marginTop: "33px"
                    }}>
                      <Link to="/contact-us">Need help?</Link>

                    </h5>
                  </form>


                </div>
              </div>
            </div>
          </div>
        </div>
      }
      <Toaster />
    </div>

  )
}
export default Signin