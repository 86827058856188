import React, { useState,useEffect } from "react";
import PopularProfileMobile from "./mobile-slider";
import Konselo from "./konsleoform";
import Footer from "../footer/Footer";
import { useLocation } from "react-router-dom";
import ReactGA from "react-ga4"
import ServiceSlider from "./serviceSlider";
const illustration1 = new URL("../../images/girlwithglobe.webp", import.meta.url);
const illustration2 = new URL("../../images/Group (1).webp", import.meta.url)
const illustration3 = new URL("../../images/Vector.webp", import.meta.url)
const illustration5 = new URL("../../images/whysidegirl.webp", import.meta.url)
const dropdown = new URL("../../images/dropdownHome.webp", import.meta.url)
const illustration = new URL("../../images/pedalstart logo 1.webp", import.meta.url)
const illustration11 = new URL("../../images/g incube logo 1.webp", import.meta.url)
const pilani = new URL("../../images/pieds logo.png", import.meta.url)
const siif = new URL("../../images/SIIF-LOGO-main.png", import.meta.url)
const universityW = new URL("../../images/uw 1.png", import.meta.url)
const south = new URL("../../images/south.png", import.meta.url)
const trinity = new URL("../../images/Trinity.png", import.meta.url)
const gorgea = new URL("../../images/gorgea.png", import.meta.url)
const Wirtschaftsuniversität = new URL("../../images/Wirtschaftsuniversität.png", import.meta.url)
const purdue = new URL("../../images/purdue.png", import.meta.url)

function Search() {
  const [isActive, setIsActive] = useState(false);
  const [isActive3, setIsActive3] = useState(false);
  const [isActive4, setIsActive4] = useState(false);
  const [contents, SetContent] = useState(false)
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    ReactGA.send(window.location.pathname + window.location.search);
  }, []);
  return (
    <div>
      <div className="mobile-view">
        <div className="content2">
          <div className="search-display2">
            <div style={{
              position: "relative",
              top: "140px",
              height: "auto",
              width: "100%"
            }}>
              <span className="content-text3">Hire  <span style={{
                color: "#7D7EC7", fontWeight: "600"
              }}> Counselors</span> for <br></br> your <span> Study <img src={illustration2} width="20%" className="search-abroad2" alt="loading..."></img> </span><br></br> Journey</span>
            </div>
            <div style={{
              position: "relative",
              top: "200px"
            }}>
              <span className="content-text4">
                Find the best study abroad counselors<br>
                </br>and buy services offerd by them on one platform
              </span><br></br>
            </div>
            <div style={{

            }}>
              {/* <input type="search" placeholder="MBA,USA" className="search2"></input> */}

            </div>
            <img src={illustration1} width="55%" className="search-illus2" alt="loading..."></img>
            <img src={illustration3} width="7%"
              className="star-illus2" alt="loading..."></img>

          </div>

        </div>
        <PopularProfileMobile />
        <div className="why-kounselo2">

          <div className="why-kounselo-text2" style={{
            margin: "auto"
          }}>
            <span className="why-text3" style={{
              fontWeight: "600"
            }}>Why </span><span className="why-text3" style={{
              color: "#7075C4", fontWeight: "600"
            }}>Kounselo ?</span><br></br>

            <span className="why-text3">Hire the best<br></br> for your college<br></br>application </span><br></br><br></br>
            <span className="why-text4">Browse through multiple counselors <br></br>
              offering various services, chat with them and select
              the best one as per your preference, all at one platform.</span>
            <div className="">
              <img src={illustration5} class="why-illus" width="100%" alt="loading..." ></img>

            </div>

          </div>

        </div>

        {/* <PopularProfileMobile /> */}
        <Konselo />
        <div className="buzz-main-mobile" style={{
          width: "100%"
        }}>
          <span className="buzz-heading">Did you hear the buzz ?</span><br>
          </br><span className="buzz-text">Take our customer’s word for it</span>
          <div class=" buzz-component" style={{


          }}>
            {/* <span className="buzz-heading">Did you here the buzz</span><br>
      </br><span className="buzz-text">see the good things no one say about us</span> */}

            <br></br>
            <div className="buzz-cards col-3">
              <span className="buzz-name" style={{
                fontFamily: 'Inter',
                fontStyle: "italic",

                fontWeight: "500",
                fontSize: "20.7992px",
                lineHeight: "29px",
                position: "relative",
                left: "2%",
                top: "20px"

              }}>Manan chandra</span><br></br><span className="buzz-university" style={{
                fontFamily: 'Inter',
                fontStyle: "italic",

                fontWeight: "500",
                fontSize: "15.7992px",
                lineHeight: "29px",
                position: "relative",
                left: "2%",
                top: "20px"

              }}
              >Purdue University. 2022</span>
              <hr className="" style={{
                position: "relative",
                top: "10px"
              }}></hr>
              <span className="buzz-card-text">I think rather than switching to one particular Counsellor, we should look for an ideal match according to our career goals. Kounselo is like Study abroad matrimony for Counsellors </span><br></br>



            </div>
            <div className="buzz-cards col-3">
              <span className="buzz-name" style={{
                fontFamily: 'Inter',
                fontStyle: "italic",

                fontWeight: "500",
                fontSize: "20.7992px",
                lineHeight: "29px",
                position: "relative",
                left: "2%",
                top: "20px"

              }}> Ashai</span><br></br><span className="buzz-university" style={{
                fontFamily: 'Inter',
                fontStyle: "italic",

                fontWeight: "500",
                fontSize: "15.7992px",
                lineHeight: "29px",
                position: "relative",
                left: "2%",
                top: "20px"

              }}
              ></span>
              <hr className="" style={{
                position: "relative",
                top: "10px"
              }}></hr>
              <span className="buzz-card-text">Thanks to Kounselo's wide variety of counselors, I was able to get the best person to navigate the competitive world of business school admissions and secure an offer from Schulich School of Business. I can confidently recommend Kounselo to anyone looking for expert consultants to support them in their journey towards achieving academic and professional goals.</span><br></br>

            </div>
            <div className="buzz-cards col-3">
              <span className="buzz-name" style={{
                fontFamily: 'Inter',
                fontStyle: "italic",

                fontWeight: "500",
                fontSize: "20.7992px",
                lineHeight: "29px",
                position: "relative",
                left: "2%",
                top: "20px"

              }}>Ninad</span><br></br><span className="buzz-university" style={{
                fontFamily: 'Inter',
                fontStyle: "italic",

                fontWeight: "500",
                fontSize: "15.7992px",
                lineHeight: "29px",
                position: "relative",
                left: "2%",
                top: "20px"

              }}
              ></span>
              <hr className="" style={{
                position: "relative",
                top: "10px"
              }}></hr>
              <span className="buzz-card-text">The counselors on Kounselo made my study abroad application process much easier as I could find the right counselor for my exact requirement. Their guidance and support helped me secure admission to Otto von Guericke University, Germany. I highly recommend searching for consultants on kounselo to anyone looking to study abroad. </span><br></br>



            </div>


          </div>
          {/* I think rather than switching to one particular Counsellor, we should look for an ideal match according to our career goals. Kounselo is like Study abroad matrimony for Counsellors */}

          <div className="faq-component">

            <center>
            <div >
            <h3 className="hired-head" style={{marginBottom:"50px"}}>
              Students who hired from Kounselo got <span style={{ color: "#6262A0" }}>Admits</span> from
            </h3>
            <div style={{ display: "flex", width: "100%", margin: "auto",flexWrap:"wrap" }}>
              <div style={{ width: "50%", margin: " 20px auto" }}>
                <img width="90%" src={universityW}></img>
              </div>
              <div style={{ width: "50%", margin: "20px auto" }}>
                <img width="90%" src={south}></img>
              </div>
          
            </div>
            <div style={{ display: "flex", width: "100%", margin: "auto" }}>
            <div style={{ width: "70%", margin: "20px auto" }}>
                <img width="100%" style={{position:"relative",left:"50px"}} src={trinity}></img>
              </div>
              <div style={{ with: "30%", margin: "20px auto" }}>
                <img width="50%" src={Wirtschaftsuniversität}></img>
              </div>

            </div>
          

            <div style={{ display: "flex", width: "100%", margin: "auto" }}>
            <div style={{ width: "60%", margin: "20px auto" }}>
                <img width="60%" src={gorgea}></img>
              </div>
              <div style={{ width: "40%", margin: "20px auto" }}>
                <img width="70%" src={purdue}></img>
              </div>
             
            </div>
          </div>

              <div className="accordion-item">
                <div className="faq-head">FAQs</div>
                <div className="faq-head2">
                  Yeah! We knew you would have these questions.
                </div>
                <div className="accordion-title" onClick={() => setIsActive(!isActive)}>
                  <div >How can I use Kounselo for my study abroad journey?</div>
                  <div>{isActive ? <img src={dropdown} className="drop-acc" style={{

                  }} alt="loading..."></img> : <img src={dropdown} alt="loading..."></img>}</div>
                </div>
                {isActive && <div className="accordion-content">Good question! Use Kounselo to the fullest to fuel your study abroad journey as you can:
                  <div class="accordion-1-padding" >1. Get access to multiple education consultants at one place</div>
                  <div class="accordion-1-padding">2. Go through their profiles, chat with them, compare them, understand their expertise and offerings</div>
                  <div class="accordion-1-padding">3. Select study abroad services as per your budget and requirements and buy them from a counsellor of your choice!</div>
                  <div class="accordion-1-padding">4. Receive your deliverables from the counsellor, review it and if you’re not satisfied, you can ask for a revision.</div></div>}
              </div>
              <div className="accordion-item">
                <div className="accordion-title" onClick={() => SetContent(!contents)}>
                  <div > Are counselors with zero reviews genuine?</div>
                  <div>{contents ? <img src={dropdown} className="drop-acc" alt="loading..."></img> : <img src={dropdown} alt="loading..."></img>}</div>
                </div>
                {contents && <div className="accordion-content">Definitely yes. They have zero reviews as they might not have sold any services yet but they are definitely reliable as all the sellers onboarded on Kounselo are verified by our team and therefore are genuine.</div>}
              </div>
              <div className="accordion-item">
                <div className="accordion-title" onClick={() => setIsActive3(!isActive3)}>
                  <div > Do we need to pay in advance for the service?</div>
                  <div>{isActive3 ? <img src={dropdown} className="drop-acc" alt="loading..."></img> : <img src={dropdown} alt="loading..."></img>}</div>
                </div>
                {isActive3 && <div className="accordion-content">Yes but don’t worry, your money is safe with us. In case of any discrepancy in the process, we will refund your amount.</div>}
              </div>
              <div className="accordion-item">
                <div className="accordion-title" onClick={() => setIsActive4(!isActive4)}>
                  <div >Is it safe to buy services on your platform?</div>
                  <div>{isActive4 ? <img src={dropdown} className="drop-acc" alt="loading..."></img> : <img src={dropdown} alt="loading..."></img>}</div>
                </div>
                {isActive4 && <div className="accordion-content">Yes, all our transactions are carried through safe payment gateways. If your transaction fails and the money gets debited, it will get back to your account. So feel free to buy any service!
                </div>}
              </div>

              <div className="incube-component" style={{
                marginBottom: "40px",
                marginTop: "40px"
              }} >
                <span className="incube-head">Supported By</span><br>
                </br><br></br><hr width="40%" style={{
                  position: "relative", margin: "auto",
                  marginBottom: "20px"

                }}></hr>


                <div className="incube-content" style={{

                }}>
                  <div>
                    <img src={illustration} className="incube-pic" width="25%" alt="loading..."></img>
                  </div>
                  <div>
                    <img src={illustration11} className="incube-pic2" width="25%" alt="loading..."></img>

                  </div>
                  <div>
                <img src={siif} className="" width="25%" alt="loading..."></img>
              </div>
              <div>
                <img src={pilani} className="" width="65%" alt="loading..."></img>
              </div>
                </div>
              </div>

            </center>

            <Footer />
            
          </div>

        </div>

      </div>


    </div>
  )

}
export default Search;
