import React, { useState, useEffect } from "react";
import ReactMarkdown from 'react-markdown'
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import ReactGA from "react-ga4"
import axios from "axios"
import { Link } from "react-router-dom";
import Footer from "../footer/Footer";
import '../../styles/blog.css'
import { useDispatch,useSelector } from "react-redux";
import { BlogCardsAPICall } from "../../Redux/actions/blogId";
const no_profile = new URL("../../images/noProfilepic.webp", import.meta.url)
function Blog() {
  // const data = useSelector(state => state.get_Seller_Cards)
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  useEffect(() => {
    ReactGA.send(window.location.pathname + window.location.search);
  }, []);
  const baseUrl = process.env.REACT_APP_BASE_URL_DEV;
  const [data, setData] = useState([])

  // const data = useSelector(state => state.get_blog_id)
 
  // useEffect(() => {
  //   BlogCardsAPICall('Getblogs', dispatch);
   

  // }, []);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await axios.get(`${baseUrl}/api/v1/blogs/getblog`);
      setData(response.data.data.blog);
    } catch (error) {
    }
  };


  

 return (
  <div>

    <div className="blog-banner">

    </div>



    <div className="blog-content">
      {data.length !==0?
        data.map((item) => {
          return (

            <div  style={{margin:"20px auto"}} class="card col-3">
                           <img src={item.photo !== undefined ? `https://kounselodata.s3.ap-south-1.amazonaws.com/${item.photo}` : no_profile}   width="90%" alt="loading..."></img>
              <div class="card-body" style={{
                textAlign: "left"
              }}>
                <h5 style={{
                  color: "#9C74F1"
                }}>{item.date}</h5>
                <h5><Link to="/blog" state={item._id}>{item.heading}</Link></h5>
                <p>Related:</p>
                <p style={{color:"#727272"}}>        <ReactMarkdown>{(item.description.length > 125) ? item.description.slice(0, 125)  + '...': item.description }</ReactMarkdown>  </p>
              </div>

            </div>


          )
        }):null
      }
    </div>






    <Footer />


  </div>
  )
}
export default Blog
