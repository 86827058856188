import React, {useEffect, useState} from "react";
import Footer from "../footer/Footer";
import "./userDash.css"
import "../../styles/seller.css";
import {useSelector, useDispatch} from 'react-redux';
import { getUserId } from "../../Redux/actions/actionTypes";
import axios from 'axios';
const no_profile = new URL("../../images/noProfilepic.webp", import.meta.url)
const minus = new URL("../../images/minus.webp", import.meta.url)
const burger = new URL("../../images/burger.webp", import.meta.url)
const rating4 = new URL("../../images/Group 33611.webp", import.meta.url)
const myorder = new URL("../../images/myorders.webp", import.meta.url)
const shield = new URL("../../images/my-msg.webp", import.meta.url)
const mail = new URL("../../images/@.webp", import.meta.url)
const question = new URL("../../images/question.webp", import.meta.url)
const del = new URL("../../images/delete.webp", import.meta.url)
const edit = new URL("../../images/edit1.webp", import.meta.url)
const help = new URL("../../images/help.webp", import.meta.url)
const drop = new URL("../../images/dropdown.webp", import.meta.url)
const plus = new URL("../../images/plus.webp", import.meta.url)

const tedameda = new URL("../../images/tedameda5.webp", import.meta.url)
const tilted2 = new URL("../../images/tilted2.webp", import.meta.url);
const tilted3 = new URL("../../images/tilted3.webp", import.meta.url);

function UserDash(){
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
    
    const dispatch = useDispatch();
    const user_id = useSelector((state) => state.get_user_id);
    const baseUrl = process.env.REACT_APP_BASE_URL_DEV;
    const [data, setData] = useState([]);

    const collegeData = [


        {
          college: "Munich Business School"
    
    
        },
        {
          college: "Berlin University of Applied Sciences"
    
    
        }, {
          college: "Cologne Business School"
    
    
        }, {
          college: "University of Toronto"
    
    
        }, {
          college: "The University of Waikato"
    
    
        }, {
          college: "University of Canberra"
    
    
        },
    
    
        {
          college: "University of Otago"
    
    
        },
        {
          college: "Durham College"
    
    
        }, {
          college: "University of Southern Queensland"
    
    
        }, {
          college: "New Zealand tertiary College"
    
    
        }, {
          college: "Trinity College Dublin"
    
    
        }, {
          college: "Griffith College Dublin"
    
    
        },
    
    
        {
          college: "Dublin Business School"
    
    
        }, {
          college: "Kutaisi University"
    
    
        }, {
          college: "Yerevan Haybusak University"
    
    
        }, {
          college: "Kazakh Medical University of Continuing Education"
    
    
        },
    
    
    
    
    
    
    
        {
          college: "O.O.Bogomolets National Medical University, Ukraine"
    
    
        }, {
          college: "University of East London"
    
    
        }, {
          college: "University of Worcester"
    
    
        }, {
          college: "Birmingham City University"
    
    
        },
    
    
    
        {
          college: "Business and Hotel Management School"
    
    
        }, {
          college: "Les Roches"
    
    
        }, {
          college: "Ecole Ducasse"
    
    
        }, {
          college: "EU Business School"
    
    
        }, {
          college: "Rome Business School"
    
    
        }, {
          college: "GISMA Business School"
    
    
        }, {
          college: "Oxford Brookes University"
    
    
        },
        {
          college: "Australian National University"
    
    
        }, {
          college: "RMIT University"
    
    
        }, {
          college: "The University of Sydney"
    
    
        }, {
          college: "University of Queensland"
    
    
        },
    
    
    
        {
          college: "Texas A&M University"
    
    
    
        }, {
          college: "Kyrgyzstan Education Centre"
    
    
        }, {
          college: "The University of Illinois at Chicago"
    
    
        }, {
          college: "The University of Melbourne"
    
    
        },
    
    
    
    
    
        {
          college: "Mari State University"
    
    
    
        }, {
          college: "Perm State Medical University"
    
    
        }, {
          college: "Siberian State Medical University"
    
    
        }, {
          college: "Orenburg State Medical University, Russia "
    
    
        },
    
        {
          college: "University of Cambridge"
    
    
    
        }, {
          college: "University of Leeds"
    
    
        }, {
          college: "The University of Adelaide"
    
    
        }, {
          college: "Middlesex University, London"
    
    
        },
    
    
    
        {
          college: "McGill University, Canada"
    
    
        }, {
          college: "TU Kaiserslautern"
    
    
        },
    
    
        {
    
          college: "Dublin City University"
    
    
    
        }, {
          college: "University of Bedfordshire, UK"
    
    
        }, {
          college: "Ulster University, UK"
    
    
        }, {
          college: "Newcastle University "
    
    
        },
    
        {
          college: "McGill University, Canada"
    
    
        }, {
          college: "Nanyang Technological University"
    
    
        },
    
    
        {
    
          college: "Niagara College"
    
    
    
        }, {
          college: "University Canada West"
    
    
        }, {
          college: "Karlsruhe Institute of Technology"
    
    
        }, {
          college: "Technical University of Berlin"
    
    
        },
    
    
        {
          college: "RWTH Aachen University"
    
    
        },
    
    
        {
    
          college: "Technical University of Munich"
    
    
    
        }, {
          college: "University Canada West"
    
    
        }, {
          college: "KUniversity of Huddersfield"
    
    
        }, {
          college: "The University of Warwick"
    
    
        },
        {
          college: "The University of British Columbia"
    
    
        }, {
          college: "University of Reading"
    
    
        },
    
        {
          college: "Australian National University"
    
    
        }, {
          college: "University of Liverpool"
    
    
        },
        {
          college: "University of Windsor"
    
    
        }, {
          college: "The University of Auckland"
    
    
        },
    
    
    
    
    
    
        {
          college: "University of Cumbria"
    
    
        },
        {
          college: "Kingston University London"
    
    
        }, {
          college: "Purdue University"
    
    
        },
        {
          college: "Auckland University of Technology"
    
    
        },
        {
          college: "Illinois Institute of Technology"
    
    
        }, {
          college: "University of Portsmouth"
    
    
        },
    
    
    
    
    
    
    
    
    
    
        {
          college: "Northwestern Polytechnic University"
    
    
        },
        {
          college: "University of Texas at Arlington"
    
    
        }, {
          college: "Monash University"
    
    
        },
        {
          college: "London Business School"
    
    
        },
        {
          college: "Imperial College London"
    
    
        }, {
          college: "University of Bristol"
    
    
        },
    
    
    
    
    
    
    
    
        {
          college: "Cranfield University"
    
    
        },
    
    
    
    
    
    
    
    
    
    
    
    
    
        {
          college: "University of Southern California"
    
    
        }, {
          college: "University of California, Berkeley"
    
    
        },
        {
          college: "University of Illinois at Urbana-Champaign"
    
    
        },
        {
          college: "Georgia Institute of Technology"
    
    
        }, {
          college: "The University of Texas at Austin"
    
    
        },
    
    
    
        {
          college: "University of Pennsylvania"
    
    
        },
        {
          college: "Princeton University"
    
    
        }, {
          college: "Columbia University"
    
    
        },
    
    
    
    
    
        {
          college: "Dartmouth"
    
    
        },
        {
          college: "Duke University"
    
    
        }, {
          college: "NYU, Tandon"
    
    
        },
    
    
        {
          college: "Harvard University"
    
    
        },
        {
          college: "Arizona State University"
    
    
        }, {
          college: "Massachusetts Institute of Technology"
    
    
        },
      ]

    useEffect(()=> {
        try {
            const response  = async() => await axios.get(`${baseUrl}/api/v1/users/${user_id}`);
            setData(response.data.data.user)
            dispatch(getUserId(response.data.data.user.photo));
        }catch(err){
            // console.log(err);
        }
    }, [user_id])
    
    return (
        <div>
           {data.length !==0 ?
                data.map((item) => {
                    return(
                        <div key = {item._id}>
                        <img src={tedameda} style={{
                            position: "absolute",
                    left: "87%",
                    width: "13%"
                }} alt = "loading"></img>
            <img src={tilted2} style={{
                position: "absolute",
                left: "90%",
                width: "10%"
            }} alt = "loading"></img>
            <img src={tilted3} style={{
                position: "absolute",
                left: "93%",
                width: "7%"
            }} alt = "loading"></img>
            <img style={{
                position: "relative",
                top: "130px",
                left: "2%",
                width: "10%"
                
            }} src={burger} id="burger" alt = "loading"></img>
            <div className="user-discription2">


                <img src={myorder} style={{
                    
                }} width="15px" alt = "loading"></img>    <span className="seller-rating2">My orders</span><br></br>


                <img src={shield} style={{
                    
                }} width="15px" alt = "loading"></img> <span className="seller-rating2">My Messages</span><br></br>

                <img src={mail} width="15px" alt = "loading"></img> <span className="seller-rating2">My Email Address <img style={{
                    width: "5%",
                    float: "right",
                    position: "relative",
                    top: "10px"
                }} src={plus} id="my-plus" alt = "loading"></img>
                    <img style={{
                        width: "5%",
                        float: "right",
                        position: "relative",
                        top: "10px"
                    }} src={minus} id="my-minus" alt = "loading"></img></span><br></br>
                <input type="email" className="minus" placeholder="example@gmail.com" style={{
                    width: "100%",
                    padding: "0px 20px 0px 10px",
                    outline: "none",
                    border: "1px solid #8B8B8B",
                    borderRadius: "6px"
                }}></input>
                <hr></hr>
                <img src={question} style={{
                    
                }} width="15px" alt = "loading"></img> <span className="seller-rating2">Forgot Password</span><br></br>
                <img src={edit} width="15px" alt = "loading"></img> <span className="seller-rating2">Change Password</span><br></br>
                <img src={del} width="15px" alt = "loading"></img> <span className="seller-rating2">Delete My Account</span><br></br>
                <img src={help} style={{
                    
                }} width="15px" alt = "loading"></img> <span className="seller-rating2">Help</span><br></br>
            </div>


<div
            className="user-dash-mobile" >
                <img src={no_profile} width="130px" style={{
                    position: "relative",
                    left: "2%"
                    
                }} className="user-personal-image" alt = "loading"></img><img src={edit} id="photo-edit" className="seller-personal-image3" style={{
                    
                }} width="50%" alt = "loading"></img>
                <div className="edit-photo2" id="edit-photo">
                    <button className="my-cross" id="my-pic-cross">x</button>

                    <img src={no_profile} width="100px" style={{
                        position: "relative",
                        left: "5%"
                        
                    }} className="" alt = "loading"></img><img src={edit} className="edit-pic-icon" style={{
                        
                    }} width="15px" alt = "loading"></img>
                    <br></br>
                    <button className="my-save" style={{
                        marginTop: "40px"
                    }}>
                        SAVE
                    </button>
                    <button className="my-cancil">
                        CANCEL
                    </button>
                </div>
                <h4 className="user-l-name">Mohak singh <img src={edit} id="edit" style={{
                    
                }} width="15px" alt = "loading"></img> </h4>
                <div className="edit-name2" id="edit-div">
                    <button className="my-cross" id="my-cross">x</button>
                    <h5 className="my-name">Enter your name</h5>
                    <input type="text" className="my-input" placeholder="Monica Talpade"></input><br></br>
                    <button className="my-save">
                        SAVE
                    </button>
                    <button className="my-cancil">
                        CANCEL
                    </button>


                </div>
                <h4 className="user-dash-head2">Just a few things for us to know you better!</h4>
                <div className="underline3"></div>
                <div className="user-head3">
                    We will give you the best suggestions based on the info you provide



                    <div className="my-selects2">
                        <div style={{
                            width: "100%"
                        }}>
                            <div className="country-drop3">
                                <span className="drop-in-head" style={{
                                    
                                    
                                }}> Target Country</span>
                                <img id="drop" src={drop} style={{
                                    margin: "10px",
                                    float: "right",
                                    width: "5%"
                                    
                                }} alt = "loading"></img>
                            </div>
                            <div className="suggestion2" style={{
                                
                            }}>
                                    India <span>x</span>
                                </div>
                                
                            <div className="country-drop-div">
                                <div style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    padding: "10px",
                                    
                                    
                                    
                                }}>
                                    <div className="my-country">India</div> <div> <input className="my-check" type="checkbox" style={{
                                        
                                        
                                        
                                    }}></input>
                                        <span className="checkmark">
                                        </span></div>

                                </div>

                                <div style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    padding: "10px"
                                    
                                }}>
                                    <div className="my-country">India</div> <div> <input className="my-check" type="checkbox" style={{
                                        
                                        
                                        
                                    }}></input>
                                        <span className="checkmark">
                                        </span></div>

                                </div> <div style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    padding: "10px"
                                    
                                }}>
                                    <div className="my-country">India</div> <div> <input className="my-check" type="checkbox" style={{
                                        
                                        
                                        
                                    }}></input>
                                        <span className="checkmark">
                                        </span></div>

                                </div> <div style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    padding: "10px"
                                    
                                }}>
                                    <div className="my-country">India</div> <div> <input className="my-check" type="checkbox" style={{
                                        
                                        
                                        
                                    }}></input>
                                        <span className="checkmark">
                                        </span></div>

                                </div> <div style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    padding: "10px"
                                    
                                }}>
                                    <div className="my-country">India</div> <div> <input className="my-check" type="checkbox" style={{
                                        
                                        
                                        
                                    }}></input>
                                        <span className="checkmark">
                                        </span></div>

                                </div>
                                <div style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    padding: "10px"
                                    
                                }}>
                                    <div className="my-country">India</div> <div> <input className="my-check" type="checkbox" style={{

                                        
                                        
                                    }}></input>
                                        <span className="checkmark">
                                        </span></div>

                                </div> <div style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    padding: "10px"
                                    
                                }}>
                                    <div className="my-country">shrilanka</div> <div> <input className="my-check" type="checkbox" style={{

                                        
                                        
                                    }}></input>
                                        <span className="checkmark">
                                        </span></div>

                                </div> <div style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    padding: "10px"
                                    
                                }}>
                                    <div className="my-country">India</div> <div> <input className="my-check" type="checkbox" style={{
                                        
                                        
                                        
                                    }}></input>
                                        <span className="checkmark">
                                        </span></div>

                                </div> <div style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    padding: "10px"
                                    
                                }}>
                                    <div className="my-country">India</div> <div> <input className="my-check" type="checkbox" style={{
                                        
                                        
                                        
                                    }}></input>
                                        <span className="checkmark">
                                        </span></div>

                                </div>

                            </div>
                        </div>
                        <div style={{
                            width: "100%"
                        }}>
                            <div className="country-drop4">

                                <span className="drop-in-head" style={{
                                    
                                    
                                    
                                }}>  Discipline you’re interested in</span>
                                <img id="drop2" src={drop} style={{
                                    margin: "10px",
                                    float: "right",
                                    width: "5%"
                                    
                                }} alt = "loading"></img>
                            </div>
                            <div className="country-drop-div2">
                                <div style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    padding: "10px"
                                    
                                }}>
                                    <div className="my-country">India</div> <div> <input className="my-check" type="checkbox" style={{
                                        
                                        
                                        
                                    }}></input>
                                        <span className="checkmark">
                                        </span></div>

                                </div>
                                <div style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    padding: "10px"
                                    
                                }}>
                                    <div className="my-country">India</div> <div> <input className="my-check" type="checkbox" style={{
                                        
                                        
                                        
                                    }}></input>
                                        <span className="checkmark">
                                        </span></div>

                                </div> <div style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    padding: "10px"
                                    
                                }}>
                                    <div className="my-country">India</div> <div> <input className="my-check" type="checkbox" style={{
                                        
                                        
                                        
                                    }}></input>
                                        <span className="checkmark">
                                        </span></div>

                                </div> <div style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    padding: "10px"
                                    
                                }}>
                                    <div className="my-country">India</div> <div> <input className="my-check" type="checkbox" style={{
                                        
                                        
                                        
                                    }}></input>
                                        <span className="checkmark">
                                        </span></div>

                                </div> <div style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    padding: "10px"
                                    
                                }}>
                                    <div className="my-country">India</div> <div> <input className="my-check" type="checkbox" style={{
                                        
                                        
                                        
                                    }}></input>
                                        <span className="checkmark">
                                        </span></div>

                                </div>
                                <div style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    padding: "10px"
                                    
                                }}>
                                    <div className="my-country">India</div> <div> <input className="my-check" type="checkbox" style={{
                                        
                                        
                                        
                                    }}></input>
                                        <span className="checkmark">
                                        </span></div>

                                </div> <div style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    padding: "10px"
                                    
                                }}>
                                    <div className="my-country">India</div> <div> <input className="my-check" type="checkbox" style={{
                                        
                                        
                                        
                                    }}></input>
                                        <span className="checkmark">
                                        </span></div>

                                </div> <div style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    padding: "10px"
                                    
                                }}>
                                    <div className="my-country">India</div> <div> <input className="my-check" type="checkbox" style={{
                                        
                                        
                                        
                                    }}></input>
                                        <span className="checkmark">
                                        </span></div>

                                </div> <div style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    padding: "10px"
                                    
                                }}>
                                    <div className="my-country">India</div> <div> <input className="my-check" type="checkbox" style={{

                                        
                                        
                                    }}></input>
                                        <span className="checkmark">
                                        </span></div>

                                </div>

                            </div>
                        </div>
                    </div>
                    <div className="my-selects2">
                        <div style={{
                            width: "100%"
                        }}>
                            <div className="country-drop3">

                                <span className="drop-in-head" style={{
                                    
                                    
                                }}> Sub Discipline you’re interested in</span>
                                <img id="drop3" src={drop} style={{
                                    margin: "10px",
                                    float: "right",
                                    width: "5%"
                                    
                                }} alt = "loading"></img>
                            </div>
                            <div className="country-drop-div3">
                                <div style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    padding: "10px"
                                    
                                }}>
                                    <div className="my-country">India</div> <div> <input className="my-check" type="checkbox" style={{
                                        
                                        
                                        
                                    }}></input>
                                        <span className="checkmark">
                                        </span></div>

                                </div>
                                <div style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    padding: "10px"
                                    
                                }}>
                                    <div className="my-country">India</div> <div> <input className="my-check" type="checkbox" style={{
                                        
                                        
                                        
                                    }}></input>
                                        <span className="checkmark">
                                        </span></div>

                                </div> <div style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    padding: "10px"
                                    
                                }}>
                                    <div className="my-country">India</div> <div> <input className="my-check" type="checkbox" style={{
                                        
                                        
                                        
                                    }}></input>
                                        <span className="checkmark">
                                        </span></div>

                                </div> <div style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    padding: "10px"
                                    
                                }}>
                                    <div className="my-country">India</div> <div> <input className="my-check" type="checkbox" style={{
                                        
                                        
                                        
                                    }}></input>
                                        <span className="checkmark">
                                        </span></div>

                                </div> <div style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    padding: "10px"
                                    
                                }}>
                                    <div className="my-country">India</div> <div> <input className="my-check" type="checkbox" style={{
                                        
                                        
                                        
                                    }}></input>
                                        <span className="checkmark">
                                        </span></div>

                                </div>
                                <div style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    padding: "10px"
                                    
                                }}>
                                    <div className="my-country">India</div> <div> <input className="my-check" type="checkbox" style={{

                                        
                                        
                                    }}></input>
                                        <span className="checkmark">
                                        </span></div>

                                </div> <div style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    padding: "10px"
                                    
                                }}>
                                    <div className="my-country">India</div> <div> <input className="my-check" type="checkbox" style={{
                                        
                                        
                                        
                                    }}></input>
                                        <span className="checkmark">
                                        </span></div>

                                </div> <div style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    padding: "10px"
                                    
                                }}>
                                    <div className="my-country">India</div> <div> <input className="my-check" type="checkbox" style={{

                                        
                                        
                                    }}></input>
                                        <span className="checkmark">
                                        </span></div>

                                </div> <div style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    padding: "10px"
                                    
                                }}>
                                    <div className="my-country">India</div> <div> <input className="my-check" type="checkbox" style={{

                                        
                                        
                                    }}></input>
                                        <span className="checkmark">
                                        </span></div>

                                </div>

                            </div>
                        </div>
                        <div style={{
                            width: "100%"
                        }}>
                            <div className="country-drop4">
                                <span className="drop-in-head" style={{
                                    
                                    
                                    
                                }}> College you’re interested in </span>
                                <img id="drop4" src={drop} style={{
                                    margin: "10px",
                                    float: "right",
                                    width: "5%"
                                    
                                }} alt = "loading"></img>
                            </div>
                            <div className="country-drop-div4">
                                <div className="" style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    padding: "10px"
                                    
                                    
                                }}>
                                    <div className="my-country">University of California</div> <div> <img src={plus} alt ="loading..."></img>
                                        <span className="checkmark">
                                        </span></div>

                                </div>
                                <div style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    padding: "10px"
                                    
                                }}>
                                    <div className="my-country">University of California</div> <div> <img src={plus} alt ="loading..."></img>
                                        <span className="checkmark">
                                        </span></div>

                                </div>   <div style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    padding: "10px"
                                    
                                }}>
                                    <div className="my-country">University of California</div> <div> <img src={plus} alt ="loading..."></img>
                                        <span className="checkmark">
                                        </span></div>

                                </div>   <div style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    padding: "10px"
                                    
                                }}>
                                    <div className="my-country">University of California</div> <div> <img src={plus} alt ="loading..."></img>
                                        <span className="checkmark">
                                        </span></div>

                                </div>   <div style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    padding: "10px"
                                    
                                }}>
                                    <div className="my-country">University of California</div> <div> <img src={plus} alt ="loading..."></img>
                                        <span className="checkmark">
                                        </span></div>

                                </div>   <div style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    padding: "10px"
                                    
                                }}>
                                    <div className="my-country">University of California</div> <div> <img src={plus} alt ="loading..."></img>
                                        <span className="checkmark">
                                        </span></div>

                                </div>   <div style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    padding: "10px"
                                    
                                }}>
                                    <div className="my-country">University of California</div> <div> <img src={plus} alt ="loading..."></img>
                                        <span className="checkmark">
                                        </span></div>

                                </div>


                            </div>
                        </div>
                    </div>
                    <div className="my-selects2">
                        <div style={{
                            width: "100%"
                        }}>
                            <div className="country-drop3">
                                <span className="drop-in-head">Intake</span>
                                <img id="drop5" src={drop} style={{
                                    margin: "10px",
                                    float: "right",
                                    width: "5%"
                                    
                                }} alt = "loading"></img>
                            </div>
                            <div className="country-drop-div5">
                                <div style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    padding: "10px"
                                    
                                }}>
                                    <div className="my-country">India</div> <div> <input className="my-check" type="checkbox" style={{
                                        
                                        
                                        
                                    }}></input>
                                        <span className="checkmark">
                                        </span></div>

                                </div>
                                <div style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    padding: "10px"
                                    
                                }}>
                                    <div className="my-country">India</div> <div> <input className="my-check" type="checkbox" style={{
                                        

                                        
                                    }}></input>
                                        <span className="checkmark">
                                        </span></div>

                                </div> <div style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    padding: "10px"
                                    
                                }}>
                                    <div className="my-country">India</div> <div> <input className="my-check" type="checkbox" style={{
                                        
                                        
                                        
                                    }}></input>
                                        <span className="checkmark">
                                        </span></div>

                                </div> <div style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    padding: "10px"
                                    
                                }}>
                                    <div className="my-country">India</div> <div> <input className="my-check" type="checkbox" style={{
                                        
                                        
                                        
                                    }}></input>
                                        <span className="checkmark">
                                        </span></div>

                                </div> <div style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    padding: "10px"
                                    
                                }}>
                                    <div className="my-country">India</div> <div> <input className="my-check" type="checkbox" style={{

                                        
                                        
                                    }}></input>
                                        <span className="checkmark">
                                        </span></div>

                                </div>
                                <div style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    padding: "10px"
                                    
                                }}>
                                    <div className="my-country">India</div> <div> <input className="my-check" type="checkbox" style={{
                                        
                                        
                                        
                                    }}></input>
                                        <span className="checkmark">
                                        </span></div>

                                </div> <div style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    padding: "10px"
                                    
                                }}>
                                    <div className="my-country">India</div> <div> <input className="my-check" type="checkbox" style={{
                                        
                                        
                                        
                                    }}></input>
                                        <span className="checkmark">
                                        </span></div>

                                </div> <div style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    padding: "10px"
                                    
                                }}>
                                    <div className="my-country">India</div> <div> <input className="my-check" type="checkbox" style={{
                                        
                                        
                                        
                                    }}></input>
                                        <span className="checkmark">
                                        </span></div>

                                </div> <div style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    padding: "10px"
                                    
                                }}>
                                    <div className="my-country">India</div> <div> <input className="my-check" type="checkbox" style={{
                                        
                                        
                                        
                                    }}></input>
                                        <span className="checkmark">
                                        </span></div>

                                </div>

                            </div>
                        </div>
                        <div style={{
                            width: "100%"
                        }}>
                            <div className="country-drop4">
                                <span className="drop-in-head">Exam Scores</span>
                                <img id="drop6" src={drop} style={{
                                    margin: "10px",
                                    float: "right",
                                    width: "5%"
                                    
                                }} alt = "loading"></img>
                            </div>
                            <div className="country-drop-div6">
                                <div style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    padding: "10px"
                                    
                                }}>
                                    <div className="my-country">India</div> <div> <input className="my-check" type="checkbox" style={{
                                        
                                        
                                        
                                    }}></input>
                                        <span className="checkmark">
                                        </span></div>

                                </div>
                                <div style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    padding: "10px"
                                    
                                }}>
                                    <div className="my-country">India</div> <div> <input className="my-check" type="checkbox" style={{
                                        
                                        
                                        
                                    }}></input>
                                        <span className="checkmark">
                                        </span></div>

                                </div> <div style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    padding: "10px"
                                    
                                }}>
                                    <div className="my-country">India</div> <div> <input className="my-check" type="checkbox" style={{
                                        
                                        
                                        
                                    }}></input>
                                        <span className="checkmark">
                                        </span></div>

                                </div> <div style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    padding: "10px"
                                    
                                }}>
                                    <div className="my-country">India</div> <div> <input className="my-check" type="checkbox" style={{
                                        
                                        
                                        
                                    }}></input>
                                        <span className="checkmark">
                                        </span></div>

                                </div> <div style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    padding: "10px"
                                    
                                }}>
                                    <div className="my-country">India</div> <div> <input className="my-check" type="checkbox" style={{

                                        
                                        
                                    }}></input>
                                        <span className="checkmark">
                                        </span></div>

                                </div>
                                <div style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    padding: "10px"
                                    
                                }}>
                                    <div className="my-country">India</div> <div> <input className="my-check" type="checkbox" style={{
                                        
                                        
                                        
                                    }}></input>
                                        <span className="checkmark">
                                        </span></div>

                                </div> <div style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    padding: "10px"
                                    
                                }}>
                                    <div className="my-country">India</div> <div> <input className="my-check" type="checkbox" style={{
                                        
                                        
                                        
                                    }}></input>
                                        <span className="checkmark">
                                        </span></div>

                                </div> <div style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    padding: "10px"
                                    
                                }}>
                                    <div className="my-country">India</div> <div> <input className="my-check" type="checkbox" style={{
                                        
                                        
                                        
                                    }}></input>
                                        <span className="checkmark">
                                        </span></div>

                                </div> <div style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    padding: "10px"
                                    
                                }}>
                                    <div className="my-country">India</div> <div> <input className="my-check" type="checkbox" style={{
                                        
                                        
                                        
                                    }}></input>
                                        <span className="checkmark">
                                        </span></div>

                                </div>

                            </div>
                        </div>

                    </div>




                    <h3 className="my-reviews-head2" style={{
                        
                        
                    }}>MY Reviews</h3>
                    <div className="underline4"></div>

                    <div className="my-reviews2">

                        <div className="my-photo2">

                        </div>
                        <div className="my-rev2">
                            <h5 className="agency">Agency</h5>
                            <img width="35%" className="my-rating" src={rating4} alt = "loading"></img>
                            <div className="reviews-dis2">
                                <span style={{
                                    float: "left"
                                }}>The setup was extremely easy. It had good UX</span>
                            </div>
                        </div>
                    </div>
                    <div className="my-reviews2">

                        <div className="my-photo2">

                        </div>
                        <div className="my-rev2">
                            <h5 className="agency">Agency</h5>
                            <img width="35%" className="my-rating" src={rating4} alt = "loading"></img>
                            <div className="reviews-dis2">
                                <span style={{
                                    float: "left"
                                }}>The setup was extremely easy. It had good UX</span>
                            </div>
                        </div>
                    </div> <div className="my-reviews2">

                        <div className="my-photo2">

                        </div>
                        <div className="my-rev2">
                            <h5 className="agency">Agency</h5>
                            <img width="35%" className="my-rating" src={rating4} alt = "loading"></img>
                            <div className="reviews-dis2">
                                <span style={{
                                    float: "left"
                                }}>The setup was extremely easy. It had good UX</span>
                            </div>
                        </div>
                    </div> <div className="my-reviews2">

                        <div className="my-photo2">

                        </div>
                        <div className="my-rev2">
                            <h5 className="agency">Agency</h5>
                            <img width="35%" className="my-rating" src={rating4} alt = "loading"></img>
                            <div className="reviews-dis2">
                                <span style={{
                                    float: "left"
                                }}>The setup was extremely easy. It had good UX</span>
                            </div>
                        </div>
                    </div> <div className="my-reviews2">

                        <div className="my-photo2">

                        </div>
                        <div className="my-rev2">
                            <h5 className="agency">Agency</h5>
                            <img width="35%" className="my-rating" src={rating4} alt = "loading"></img>
                            <div className="reviews-dis2">
                                <span style={{
                                    float: "left"
                                }}>The setup was extremely easy. It had good UX</span>
                            </div>
                        </div>
                    </div> <div className="my-reviews2">

                        <div className="my-photo2">

                        </div>
                        <div className="my-rev2">
                            <h5 className="agency">Agency</h5>
                            <img width="35%" className="my-rating" src={rating4} alt = "loading"></img>
                            <div className="reviews-dis2">
                                <span style={{
                                    float: "left"
                                }}>The setup was extremely easy. It had good UX</span>
                            </div>
                        </div>
                    </div> <div className="my-reviews2">

                        <div className="my-photo2">

                        </div>
                        <div className="my-rev2">
                            <h5 className="agency">Agency</h5>
                            <img width="35%" className="my-rating" src={rating4} alt = "loading"></img>
                            <div className="reviews-dis2">
                                <span style={{
                                    float: "left"
                                }}>The setup was extremely easy. It had good UX</span>
                            </div>
                        </div>
                    </div> <div className="my-reviews2">

                        <div className="my-photo2">

                        </div>
                        <div className="my-rev2">
                            <h5 className="agency">Agency</h5>
                            <img width="35%" className="my-rating" src={rating4} alt = "loading"></img>
                            <div className="reviews-dis2">
                                <span style={{
                                    float: "left"
                                }}>The setup was extremely easy. It had good UX</span>
                            </div>
                        </div>
                    </div>
                </div>


                <Footer />
            </div>

<div>

                <div className="user-dash-pc">
                    <div className="sellerr-info">
                        <img src={no_profile} width="100px" style={{
                            position: "relative",
                            left: "2%"
                            
                        }} className="seller-personal-image" alt = "loading"></img><img src={edit} id="photo-edit2" className="seller-personal-image2" style={{
                            
                        }} width="15px" alt = "loading"></img>
                        <div className="edit-photo" id="edit-photo2">
                            <button className="my-cross" id="my-pic-cross2">x</button>

                            <img src={no_profile} width="100px" style={{
                                position: "relative",
                                left: "5%"
                                
                            }} className="" alt = "loading"></img><img src={edit} className="edit-pic-icon" style={{
                                
                            }} width="15px" alt = "loading"></img>
                            <br></br>
                            <button className="my-save" style={{
                                marginTop: "40px"
                            }}>
                                SAVE
                            </button>
                            <button className="my-cancil">
                                CANCEL
                            </button>
                        </div>
                        <h4 className="seller-l-name">Mohak singh <img src={edit} id="edit2" style={{
                            
                        }} width="15px" alt = "loading"></img> </h4>
                        <div className="edit-name" id="edit-div2">
                            <button className="my-cross" id="my-cross2">x</button>
                            <h5 className="my-name">Enter your name</h5>
                            <input type="text" className="my-input" placeholder="Monica Talpade"></input><br></br>
                            <button className="my-save">
                                SAVE
                            </button>
                            <button className="my-cancil">
                                CANCEL
                            </button>
                        </div>
                        <div className="user-discription">






                            <img src={myorder} style={{
                                
                            }} width="15px" alt = "loading"></img>    <span className="seller-rating2">My orders</span><br></br>


                            <img src={shield} style={{
                                
                            }} width="15px" alt = "loading"></img> <span className="seller-rating2">My Messages</span><br></br>

                            <img src={mail} style={{
                                
                            }} width="15px" alt = "loading"></img> <span className="seller-rating2">My Email Address <img style={{
                                width: "5%",
                                float: "right",
                                position: "relative",
                                top: "10px"
                            }} src={plus} id="my-plus2" alt = "loading"></img>
                                <img style={{
                                    width: "5%",
                                    float: "right",
                                    position: "relative",
                                    top: "10px"
                                }} src={minus} id="my-minus2" alt = "loading"></img></span><br></br>
                            <input type="email" className="minus2" placeholder="example@gmail.com" style={{
                                width: "100%",
                                padding: "0px 20px 0px 10px",
                                outline: "none",
                                border: "1px solid #8B8B8B",
                                borderRadius: "6px"
                            }}></input>
                            <hr></hr>
                            <img src={question} style={{
                                
                            }} width="15px" alt = "loading"></img> <span className="seller-rating2">Forgot Password</span><br></br>
                            <img src={edit} width="15px" alt = "loading"></img> <span className="seller-rating2">Change Password</span><br></br>
                            <img src={del}  width="15px" alt = "loading"></img> <span className="seller-rating2">Delete My Account</span><br></br>
                            <img src={help} width="15px" alt = "loading"></img> <span className="seller-rating2">Help</span><br></br>
                        </div>




                    </div>
                    <div className="seller-service-pic" style={{
                        
                    }}>
                        <h4 className="user-dash-head">Just a few things for us to know you better!</h4>
                        <div className="underline2"></div>
                        <div className="user-head2">
                            We will give you the best suggestions based on the info you provide
                        </div>
                        <div className="my-selects">
                            <div style={{
                                width: "100%"
                            }}>
                                <div className="country-drop">
                                    <span className="drop-in-head" style={{
                                        
                                        
                                    }}> Target Country</span>
                                    <img id="drop" src={drop} style={{
                                        margin: "10px",
                                        float: "right",
                                        width: "5%"
                                        
                                    }} alt = "loading"></img>
                                </div>
                                <div className="suggestion" style={{
                                    background: "#EDEBFE",
                                    borderRadius: "9.5315px",
                                    width: "10%",
                                    position: "absolute",
                                    left: "-5%",
                                    top: "34px",
                                    
                                    color: "#7275C4"
                                }}>
                                    India <span>x</span>
                                </div>
                                <div className="country-drop-div">
                                    <div style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        padding: "10px",
                                        
                                        
                                        
                                    }}>
                                        <div className="my-country">India</div> <div> <input className="my-check" type="checkbox" style={{
                                            
                                            
                                            
                                        }}></input>
                                            <span className="checkmark">
                                            </span></div>

                                    </div>

                                    <div style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        padding: "10px"
                                        
                                    }}>
                                        <div className="my-country">India</div> <div> <input className="my-check" type="checkbox" style={{
                                            
                                            
                                            
                                        }}></input>
                                            <span className="checkmark">
                                            </span></div>

                                    </div> <div style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        padding: "10px"
                                        
                                    }}>
                                        <div className="my-country">India</div> <div> <input className="my-check" type="checkbox" style={{
                                            
                                            
                                            
                                        }}></input>
                                            <span className="checkmark">
                                            </span></div>

                                    </div> <div style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        padding: "10px"
                                        
                                    }}>
                                        <div className="my-country">India</div> <div> <input className="my-check" type="checkbox" style={{

                                            
                                            
                                        }}></input>
                                            <span className="checkmark">
                                            </span></div>

                                    </div> <div style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        padding: "10px"
                                        
                                    }}>
                                        <div className="my-country">India</div> <div> <input className="my-check" type="checkbox" style={{
                                            
                                            
                                            
                                        }}></input>
                                            <span className="checkmark">
                                            </span></div>

                                    </div>
                                    <div style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        padding: "10px"
                                        
                                    }}>
                                        <div className="my-country">India</div> <div> <input className="my-check" type="checkbox" style={{
                                            
                                            
                                            
                                        }}></input>
                                            <span className="checkmark">
                                            </span></div>

                                    </div> <div style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        padding: "10px"
                                        
                                    }}>
                                        <div className="my-country">India</div> <div> <input className="my-check" type="checkbox" style={{
                                            
                                            
                                            
                                        }}></input>
                                            <span className="checkmark">
                                            </span></div>

                                    </div> <div style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        padding: "10px"
                                        
                                    }}>
                                        <div className="my-country">India</div> <div> <input className="my-check" type="checkbox" style={{
                                            
                                            
                                            
                                        }}></input>
                                            <span className="checkmark">
                                            </span></div>

                                    </div> <div style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        padding: "10px"
                                        
                                    }}>
                                        <div className="my-country">India</div> <div> <input className="my-check" type="checkbox" style={{
                                            
                                            
                                            
                                        }}></input>
                                            <span className="checkmark">
                                            </span></div>

                                    </div>

                                </div>
                            </div>
                            <div style={{
                                width: "100%"
                            }}>
                                <div className="country-drop2">

                                    <span className="drop-in-head" style={{
                                        
                                        
                                        
                                    }}>  Discipline you’re interested in</span>
                                    <img id="drop2" src={drop} style={{
                                        margin: "10px",
                                        float: "right",
                                        width: "5%"
                                        
                                    }} alt = "loading"></img>
                                </div>
                                <div className="country-drop-div2">
                                    <div style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        padding: "10px"
                                        
                                    }}>
                                        <div className="my-country">India</div> <div> <input className="my-check" type="checkbox" style={{
                                            
                                            
                                            
                                        }}></input>
                                            <span className="checkmark">
                                            </span></div>

                                    </div>
                                    <div style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        padding: "10px"
                                        
                                    }}>
                                        <div className="my-country">India</div> <div> <input className="my-check" type="checkbox" style={{
                                            
                                            
                                            
                                        }}></input>
                                            <span className="checkmark">
                                            </span></div>

                                    </div> <div style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        padding: "10px"
                                        
                                    }}>
                                        <div className="my-country">India</div> <div> <input className="my-check" type="checkbox" style={{
                                            
                                            
                                            
                                        }}></input>
                                            <span className="checkmark">
                                            </span></div>

                                    </div> <div style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        padding: "10px"
                                        
                                    }}>
                                        <div className="my-country">India</div> <div> <input className="my-check" type="checkbox" style={{

                                            
                                            
                                        }}></input>
                                            <span className="checkmark">
                                            </span></div>

                                    </div> <div style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        padding: "10px"
                                        
                                    }}>
                                        <div className="my-country">India</div> <div> <input className="my-check" type="checkbox" style={{
                                            
                                            
                                            
                                        }}></input>
                                            <span className="checkmark">
                                            </span></div>

                                    </div>
                                    <div style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        padding: "10px"
                                        
                                    }}>
                                        <div className="my-country">India</div> <div> <input className="my-check" type="checkbox" style={{
                                            
                                            
                                            
                                        }}></input>
                                            <span className="checkmark">
                                            </span></div>

                                    </div> <div style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        padding: "10px"
                                        
                                    }}>
                                        <div className="my-country">India</div> <div> <input className="my-check" type="checkbox" style={{
                                            
                                            
                                            
                                        }}></input>
                                            <span className="checkmark">
                                            </span></div>

                                    </div> <div style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        padding: "10px"
                                        
                                    }}>
                                        <div className="my-country">India</div> <div> <input className="my-check" type="checkbox" style={{
                                            
                                            
                                            
                                        }}></input>
                                            <span className="checkmark">
                                            </span></div>

                                    </div> <div style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        padding: "10px"
                                        
                                    }}>
                                        <div className="my-country">India</div> <div> <input className="my-check" type="checkbox" style={{

                                            
                                            
                                        }}></input>
                                            <span className="checkmark">
                                            </span></div>

                                    </div>

                                </div>
                            </div>
                        </div>
                        <div className="my-selects">
                            <div style={{
                                width: "100%"
                            }}>
                                <div className="country-drop">

                                    <span className="drop-in-head" style={{
                                        
                                        
                                    }}> Sub Discipline you’re interested in</span>
                                    <img id="drop3" src={drop} style={{
                                        margin: "10px",
                                        float: "right",
                                        width: "5%"
                                        
                                    }} alt = "loading"></img>
                                </div>
                                <div className="country-drop-div3">
                                    <div style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        padding: "10px"
                                        
                                    }}>
                                        <div className="my-country">India</div> <div> <input className="my-check" type="checkbox" style={{
                                            
                                            
                                            
                                        }}></input>
                                            <span className="checkmark">
                                            </span></div>

                                    </div>
                                    <div style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        padding: "10px"
                                        
                                    }}>
                                        <div className="my-country">India</div> <div> <input className="my-check" type="checkbox" style={{
                                            
                                            
                                            
                                        }}></input>
                                            <span className="checkmark">
                                            </span></div>

                                    </div> <div style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        padding: "10px"
                                        
                                    }}>
                                        <div className="my-country">India</div> <div> <input className="my-check" type="checkbox" style={{
                                            
                                            
                                            
                                        }}></input>
                                            <span className="checkmark">
                                            </span></div>

                                    </div> <div style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        padding: "10px"
                                        
                                    }}>
                                        <div className="my-country">India</div> <div> <input className="my-check" type="checkbox" style={{
                                            
                                            
                                            
                                        }}></input>
                                            <span className="checkmark">
                                            </span></div>

                                    </div> <div style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        padding: "10px"
                                        
                                    }}>
                                        <div className="my-country">India</div> <div> <input className="my-check" type="checkbox" style={{
                                            
                                            
                                            
                                        }}></input>
                                            <span className="checkmark">
                                            </span></div>

                                    </div>
                                    <div style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        padding: "10px"
                                        
                                    }}>
                                        <div className="my-country">India</div> <div> <input className="my-check" type="checkbox" style={{
                                            
                                            
                                            
                                        }}></input>
                                            <span className="checkmark">
                                            </span></div>

                                    </div> <div style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        padding: "10px"
                                        
                                    }}>
                                        <div className="my-country">India</div> <div> <input className="my-check" type="checkbox" style={{

                                            
                                            
                                        }}></input>
                                            <span className="checkmark">
                                            </span></div>

                                    </div> <div style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        padding: "10px"
                                        
                                    }}>
                                        <div className="my-country">India</div> <div> <input className="my-check" type="checkbox" style={{
                                            
                                            
                                            
                                        }}></input>
                                            <span className="checkmark">
                                            </span></div>

                                    </div> <div style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        padding: "10px"
                                        
                                    }}>
                                        <div className="my-country">India</div> <div> <input className="my-check" type="checkbox" style={{
                                            
                                            
                                            
                                        }}></input>
                                            <span className="checkmark">
                                            </span></div>

                                    </div>

                                </div>
                            </div>
                            <div style={{
                                width: "100%"
                            }}>
                                <div className="country-drop2">
                                    <span className="drop-in-head" style={{
                                        
                                        
                                        
                                    }}> College you’re interested in </span>
                                    <img id="drop4" src={drop} style={{
                                        margin: "10px",
                                        float: "right",
                                        width: "5%"
                                        
                                    }} alt = "loading"></img>
                                </div>
                                <div className="country-drop-div4">
                                    <div className="" style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        padding: "10px"
                                        
                                        
                                    }}>
                                        <div className="my-country">University of California</div> <div> <img src={plus} alt = "loading"></img>
                                            <span className="checkmark">
                                            </span></div>

                                    </div>
                                    <div style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        padding: "10px"
                                        
                                    }}>
                                        <div className="my-country">University of California</div> <div> <img src={plus} alt = "loading"></img>
                                            <span className="checkmark">
                                            </span></div>

                                    </div>   <div style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        padding: "10px"
                                        
                                    }}>
                                        <div className="my-country">University of California</div> <div> <img src={plus} alt = "loading"></img>
                                            <span className="checkmark">
                                            </span></div>

                                    </div>   <div style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        padding: "10px"
                                        
                                    }}>
                                        <div className="my-country">University of California</div> <div> <img src={plus} alt = "loading"></img>
                                            <span className="checkmark">
                                            </span></div>

                                    </div>   <div style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        padding: "10px"
                                        
                                    }}>
                                        <div className="my-country">University of California</div> <div> <img src={plus} alt = "loading"></img>
                                            <span className="checkmark">
                                            </span></div>

                                    </div>   <div style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        padding: "10px"
                                        
                                    }}>
                                        <div className="my-country">University of California</div> <div> <img src={plus} alt = "loading"></img>
                                            <span className="checkmark">
                                            </span></div>

                                    </div>   <div style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        padding: "10px"
                                        
                                    }}>
                                        <div className="my-country">University of California</div> <div> <img src={plus} alt = "loading"></img>
                                            <span className="checkmark">
                                            </span></div>

                                    </div>


                                </div>
                            </div>
                        </div>
                        <div className="my-selects">
                            <div style={{
                                width: "100%"
                            }}>
                                <div className="country-drop">
                                    <span className="drop-in-head">Intake</span>
                                    <img id="drop5" src={drop} style={{
                                        margin: "10px",
                                        float: "right",
                                        width: "5%"
                                        
                                    }} alt = "loading"></img>
                                </div>
                                <div className="country-drop-div5">
                                    <div style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        padding: "10px"
                                        
                                    }}>
                                        <div className="my-country">India</div> <div> <input className="my-check" type="checkbox" style={{
                                            
                                            
                                            
                                        }}></input>
                                            <span className="checkmark">
                                            </span></div>

                                    </div>
                                    <div style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        padding: "10px"
                                        
                                    }}>
                                        <div className="my-country">India</div> <div> <input className="my-check" type="checkbox" style={{
                                            
                                            
                                            
                                        }}></input>
                                            <span className="checkmark">
                                            </span></div>

                                    </div> <div style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        padding: "10px"
                                        
                                    }}>
                                        <div className="my-country">India</div> <div> <input className="my-check" type="checkbox" style={{
                                            
                                            
                                            
                                        }}></input>
                                            <span className="checkmark">
                                            </span></div>

                                    </div> <div style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        padding: "10px"
                                        
                                    }}>
                                        <div className="my-country">India</div> <div> <input className="my-check" type="checkbox" style={{
                                            
                                            
                                            
                                        }}></input>
                                            <span className="checkmark">
                                            </span></div>

                                    </div> <div style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        padding: "10px"
                                        
                                    }}>
                                        <div className="my-country">India</div> <div> <input className="my-check" type="checkbox" style={{

                                            
                                            
                                        }}></input>
                                            <span className="checkmark">
                                            </span></div>

                                    </div>
                                    <div style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        padding: "10px"
                                        
                                    }}>
                                        <div className="my-country">India</div> <div> <input className="my-check" type="checkbox" style={{
                                            
                                            
                                            
                                        }}></input>
                                            <span className="checkmark">
                                            </span></div>

                                    </div> <div style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        padding: "10px"
                                        
                                    }}>
                                        <div className="my-country">India</div> <div> <input className="my-check" type="checkbox" style={{

                                            
                                            
                                        }}></input>
                                            <span className="checkmark">
                                            </span></div>

                                    </div> <div style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        padding: "10px"
                                        
                                    }}>
                                        <div className="my-country">India</div> <div> <input className="my-check" type="checkbox" style={{
                                            
                                            
                                            
                                        }}></input>
                                            <span className="checkmark">
                                            </span></div>

                                    </div> <div style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        padding: "10px"
                                        
                                    }}>
                                        <div className="my-country">India</div> <div> <input className="my-check" type="checkbox" style={{
                                            
                                            
                                            
                                        }}></input>
                                            <span className="checkmark">
                                            </span></div>

                                    </div>

                                </div>
                            </div>
                            <div style={{
                                width: "100%"
                            }}>
                                <div className="country-drop2">
                                    <span className="drop-in-head">Exam Scores</span>
                                    <img id="drop6" src={drop} style={{
                                        margin: "10px",
                                        float: "right",
                                        width: "5%"
                                        
                                    }} alt = "loading"></img>
                                </div>
                                <div className="country-drop-div6">
                                    <div style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        padding: "10px"
                                        
                                    }}>
                                        <div className="my-country">India</div> <div> <input className="my-check" type="checkbox" style={{
                                            
                                            
                                            
                                        }}></input>
                                            <span className="checkmark">
                                            </span></div>

                                    </div>
                                    <div style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        padding: "10px"
                                        
                                    }}>
                                        <div className="my-country">India</div> <div> <input className="my-check" type="checkbox" style={{
                                            
                                            
                                            
                                        }}></input>
                                            <span className="checkmark">
                                            </span></div>

                                    </div> <div style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        padding: "10px"
                                        
                                    }}>
                                        <div className="my-country">India</div> <div> <input className="my-check" type="checkbox" style={{

                                            
                                            
                                        }}></input>
                                            <span className="checkmark">
                                            </span></div>

                                    </div> <div style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        padding: "10px"
                                        
                                    }}>
                                        <div className="my-country">India</div> <div> <input className="my-check" type="checkbox" style={{
                                            
                                            
                                            
                                        }}></input>
                                            <span className="checkmark">
                                            </span></div>

                                    </div> <div style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        padding: "10px"
                                        
                                    }}>
                                        <div className="my-country">India</div> <div> <input className="my-check" type="checkbox" style={{
                                            
                                            
                                            
                                        }}></input>
                                            <span className="checkmark">
                                            </span></div>

                                    </div>
                                    <div style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        padding: "10px"
                                        
                                    }}>
                                        <div className="my-country">India</div> <div> <input className="my-check" type="checkbox" style={{
                                            
                                            
                                            
                                        }}></input>
                                            <span className="checkmark">
                                            </span></div>

                                    </div> <div style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        padding: "10px"
                                        
                                    }}>
                                        <div className="my-country">India</div> <div> <input className="my-check" type="checkbox" style={{
                                            
                                            
                                            
                                        }}></input>
                                            <span className="checkmark">
                                            </span></div>

                                    </div> <div style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        padding: "10px"
                                        
                                    }}>
                                        <div className="my-country">India</div> <div> <input className="my-check" type="checkbox" style={{

                                            
                                            
                                        }}></input>
                                            <span className="checkmark">
                                            </span></div>

                                    </div> <div style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        padding: "10px"
                                        
                                    }}>
                                        <div className="my-country">India</div> <div> <input className="my-check" type="checkbox" style={{
                                            

                                            
                                        }}></input>
                                            <span className="checkmark">
                                            </span></div>

                                    </div>

                                </div>
                            </div>

                        </div>
                        <h3 className="my-reviews-head" style={{
                            
                            
                        }}>MY Reviews</h3>
                        <div className="underline"></div>

                        <div className="my-reviews">

                            <div className="my-photo">

                            </div>
                            <div className="my-rev">
                                <h5 className="agency">Agency</h5>
                                <img width="35%" className="my-rating" src={rating4} alt = "loading"></img>
                                <div className="reviews-dis">
                                    <span style={{
                                        float: "left"
                                    }}>The setup was extremely easy. It had good UX</span>
                                </div>
                            </div>
                        </div>
                        <div className="my-reviews">

                            <div className="my-photo">

                            </div>
                            <div className="my-rev">
                                <h5 className="agency">Agency</h5>
                                <img width="35%" className="my-rating" src={rating4} alt = "loading"></img>
                                <div className="reviews-dis">
                                    <span style={{
                                        float: "left"
                                    }}>The setup was extremely easy. It had good UX</span>
                                </div>
                            </div>
                        </div>
                        <div className="my-reviews">

                            <div className="my-photo">

                            </div>
                            <div className="my-rev">
                                <h5 className="agency">Agency</h5>
                                <img width="35%" className="my-rating" src={rating4} alt = "loading"></img>
                                <div className="reviews-dis">
                                    <span style={{
                                        float: "left"
                                    }}>The setup was extremely easy. It had good UX</span>
                                </div>
                            </div>
                        </div> <div className="my-reviews">

                            <div className="my-photo">

                            </div>
                            <div className="my-rev">
                                <h5 className="agency">Agency</h5>
                                <img width="35%" className="my-rating" src={rating4} alt = "loading"></img>
                                <div className="reviews-dis">
                                    <span style={{
                                        float: "left"
                                    }}>The setup was extremely easy. It had good UX</span>
                                </div>
                            </div>
                        </div> <div className="my-reviews">

                            <div className="my-photo">

                            </div>
                            <div className="my-rev">
                                <h5 className="agency">Agency</h5>
                                <img width="35%" className="my-rating" src={rating4} alt = "loading"></img>
                                <div className="reviews-dis">
                                    <span style={{
                                        float: "left"
                                    }}>The setup was extremely easy. It had good UX</span>
                                </div>
                            </div>
                        </div> <div className="my-reviews">

                            <div className="my-photo">

                            </div>
                            <div className="my-rev">
                                <h5 className="agency">Agency</h5>
                                <img width="35%" className="my-rating" src={rating4} alt = "loading"></img>
                                <div className="reviews-dis">
                                    <span style={{
                                        float: "left"
                                    }}>The setup was extremely easy. It had good UX</span>
                                </div>
                            </div>
                        </div>
                    </div>



                </div>

            </div>
        </div>
        )
        }) : null
    }
        <Footer/>
        </div>
        )
    }
export default UserDash