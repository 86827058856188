import React, { useEffect } from "react";
import Footer from "../footer/Footer";
import "../../styles/userLanding.css"
import DashCards2 from "./dash-card2";
import { useSelector } from "react-redux";
const illus = new URL("../../images/Ellipse 15.webp", import.meta.url)

function AllOngoingSeller() {
    const data = useSelector((state) => state.get_Seller_Dashboard_Ongoing_Order_Data) 
        useEffect(() => {
                window.scrollTo(0, 0);
        }, []);
        return (
                <div>
                        <h5 className="service-pages-head" style={{
                                color: "#7D7EC7"
                        }}>All Ongoing Orders</h5>

                        <hr className="service-line"></hr>

                        <img src={illus} width="550px" className="teda-meda" alt="loading..."></img>
                        <div className="row row2">
                                <DashCards2 data = {data} />
                        </div>
                        <Footer />
                </div>
        )
}
export default AllOngoingSeller
