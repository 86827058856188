import React, { useEffect } from "react";
import Content from "./content";
import Search from "./Search";

function Home(){
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
      
    return(
        <div>
        <Search/>
        <Content/>
        </div>
    )
}

export default Home