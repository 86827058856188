import React, {useEffect} from "react";
import Footer from "../footer/Footer";
import "../../styles/userLanding.css"
import UserServiceSlider from "./userServiceSlider";
import UserLandingSlider from "./userlandingSlider";
import ServiceComponent2 from "../individualservice/service-component2";
import ServiceComponent from "../individualservice/servicesComponent";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import toast, {Toaster} from 'react-hot-toast';
const no_profile = new URL("./images/21SDD 1.webp", import.meta.url);
const illus = new URL("./images/Ellipse 15.webp", import.meta.url);

function UserLandingPage() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const navigate = useNavigate();
  const userRole = useSelector((state) => state.get_user_id.role);
  const sellerRole = useSelector((state) => state.get_seller_profile_id.role);
  // useEffect=(()=>{
  //   ReactGa.pageview(window.location.pathname + window.location.search)
  // },[])
  useEffect(()=> {
    if(!userRole && !sellerRole){
      navigate('/', {replace : true,
        state : {
          signIn : true,
          type : "user"
        }
      })
    }else if(sellerRole){
      toast.error('You are not allowed to open this url');
      navigate('/counselor-dashboard');
    }
  }, [navigate, userRole, sellerRole])
  return (
    <div>

      <ServiceComponent />
      <ServiceComponent2/>
      <div className="user-banner">

        <div className="banner1">
          <div className="banner-heading">Planning to study</div><div className="abroad">Abroad?</div>

          <div className="banner-get" > </div>
          <div className="banner-top-c" >Let's find most suited <span style={{
            color: "#F97269"
          }}>counselor </span>  today</div>

        </div>
        <div className="banner2">
          <img src={no_profile} className="user-illus" width="40%" alt = "loading..."></img>

        </div>
      </div>
      <img src={illus} width="550px" className="teda-meda" alt = "loading..."></img>
      <h5 className="user-heading1" style={{

        /* identical to box height */

        color: "#7D7EC7"
      }}>Explore Top Counselors</h5>
      <hr style={{
        width: "50%",
        position: "relative",
        top: "180px",
        margin: "auto",

        background: "#7D7EC7",
        height: "5px",
        borderRadius: "5px"
      }}></hr>
      <div className="container-fluid">

        <img src={illus} width="550px" className="teda-meda" alt = "loading..."></img>
        <UserLandingSlider />
        <h5 className="user-landing-heading2" style={{

        }}>Recomended for you</h5>
        <hr style={{
          width: "50%",
          position: "relative",
          top: "-150px",
          margin: "auto",
          marginBottom: "40px",
          background: "#7D7EC7",
          height: "5px",
          borderRadius: "5px"
        }}></hr>
        <UserServiceSlider />

      </div>

      <Footer />
      <Toaster/>
    </div>
  )
}
export default UserLandingPage