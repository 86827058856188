


import Flickity from 'react-flickity-component'
import React from 'react'
import { Link } from 'react-router-dom'
import "../../styles/serviceSlider.css"
import "../../styles/userLanding.css"
const illustration5 = new URL("../../images/uni.webp", import.meta.url);
const illustration9 = new URL("../../images/image 20.webp", import.meta.url);
const illustration11 = new URL("../../images/visaa.webp", import.meta.url);
const illustration14 = new URL("../../images/profileEvaluationIllustration.webp", import.meta.url);
const illustration15 = new URL("../../images/app.webp", import.meta.url);
const illustration16 = new URL("../../images/resumeBuildingIllustration.webp", import.meta.url);
const illustration17 = new URL("../../images/essayEditingIllustration.webp", import.meta.url);
const scholarship = new URL("../../images/scholorshipIllustration.webp", import.meta.url);

function ServiceSlider() {

  const flickityOptions = {
    initialIndex: 1,
    wrapAround: true,
    freeScroll: true,
    pageDots: true,
    autoPlay:"1000s",
    pauseAutoPlayOnHover: true
  }
  return (
    <div class="slider">
      <div className='white'>
        {/* <img src={illustration10}width="20%"style={{
        position:"absolute",
        left:"94%",
        top:"-30px"

        
      }} alt = "loading..."></img> */}
        <h4 className='service-slider-head '>Popular services</h4>
        <h6 className=' font-service2'>Explore services offered by expert counselors</h6>

        <Link className="slider-hyper" to="/all-service"><h4 className='service-view-all ' style={{
         
          top: "-10px",
          color: " #7D7DB2"
        }}>View all</h4></Link>

      </div>

      <Flickity
        className={'carousel1'} // default ''
        elementType={'div'} // default 'div'
        options={flickityOptions} // takes flickity options {}
        disableImagesLoaded={true} // default false
        reloadOnUpdate={true} // default false
        static={true} // default false
      >
     
    <div class="carousel-cell1 "><center>
      <div className="service-card2">
            <img
              src={illustration9}
              style={{
                marginTop: "10px",
              }}
              width="25%"
            alt = "loading..."></img>
            <hr
              style={{
                width: "80%",
                fontStyle: "bolder",
                color: "black",
              }}
            ></hr>
          </div>
          <br></br>

          <div className="service-head2"><Link className="service-link" to= "/statement-of-purpose">SOP</Link></div>
          <div className="service-content service-content2">
            SOP is the backbone of any aspirant’s application and needs to
            showcase your story to the fullest in front if the admissions
            committee. Get guidance on SOP from study abroad experts on drafting
            the right SOP.
          </div>
        </center>
        <div
          style={{
            position: "relative",
            top: "-15px",
          }}
        >
          <span className="starting">Starting from:</span>
          <span className="INR8">INR</span>
          <span className="INR6 INR9">4999/-</span>
        </div>
    </div>
    
    <div class="carousel-cell1 "><center>
    <div className="service-card2">
            <img
              src={illustration14}
              style={{
                marginTop: "10px",
              }}
              width="19%"
            alt = "loading..."></img>
            <hr
              style={{
                width: "80%",
                fontStyle: "bolder",
                color: "black",
              }}
            ></hr>
          </div>
          <br></br>

          <div className="service-head2"><Link className='service-link' to="/profile-evaluation">Profile Evaluation</Link></div>
          <div className="service-content service-content2">
            Every profile is unique and has a different chance of admit for
            different programs. Get detailed insights about your profile and
            understand your chances of getting into your dream program.
          </div>
        </center>
        <div
          style={{
            position: "relative",
            top: "-15px",
          }}
        >
          <span className="starting">Starting from:</span>
          <span className="INR8">INR</span>
          <span className="INR6 INR9">4999/-</span>
        </div>
    </div>

    <div class="carousel-cell1 "><center>
    <div className="service-card2">
            <img
              src={illustration5}
              style={{
                marginTop: "10px",
              }}
              width="19%"
            alt = "loading..."></img>
            <hr
              style={{
                width: "80%",
                fontStyle: "bolder",
                color: "black",
              }}
            ></hr>
          </div>
          <br></br>

          <div className="service-head2"><Link className='service-link' to="/university-shortlisting">University Shortlisting</Link></div>
          <div className="service-content service-content2">
            Decided upon a course and want know the best universities offering
            the same? Get guidance from overseas education experts to shortlist
            the best universities for your desired course.
          </div>
        </center>
        <div
          style={{
            position: "relative",
            top: "-15px",
          }}
        >
          <span className="starting">Starting from:</span>
          <span className="INR8">INR</span>
          <span className="INR6 INR9">4999/-</span>
        </div>
    </div>
    <div class="carousel-cell1 "><center>
    <div className="service-card2">
            <img
              src={illustration16}
              style={{
                marginTop: "10px",
              }}
              width="19%"
            alt = "loading..."></img>
            <hr
              style={{
                width: "80%",
                fontStyle: "bolder",
                color: "black",
              }}
            ></hr>
          </div>
          <br></br>
          <div className="service-head2"><Link className='service-link' to="/resume">Resume Writing</Link></div>
          <div className="service-content service-content2">
            A large number of universities ask for resume to judge your
            candidature for that particular course and it plays an important
            role in your admissions. 
          </div>
        </center>
        <div
          style={{
            position: "relative",
            top: "-15px",
          }}
        >
          <span className="starting">Starting from:</span>
          <span className="INR8">INR</span>
          <span className="INR6 INR9">4999/-</span>
        </div>
    </div>
    <div class="carousel-cell1 "><center>
    <div className="service-card2">
            <img
              src={scholarship}
              style={{
                marginTop: "10px",
              }}
              width="19%" alt = "loading..."
            ></img>
            <hr
              style={{
                width: "80%",
                fontStyle: "bolder",
                color: "black",
              }}
            ></hr>
          </div>
          <br></br>
          <div className="service-head2"><Link className='service-link' to="/scholarship-application">Scholorship Assistance</Link></div>
          <div className="service-content service-content2">
            Stand a chance to get a scholarship for your program abroad with the
            top experts offering assistance with scholarships for various
            countries and courses. Get in touch now
          </div>
        </center>
        <div
          style={{
            position: "relative",
            top: "-15px",
          }}
        >
          <span className="starting">Starting from:</span>
          <span className="INR8">INR</span>
          <span className="INR6 INR9">4999/-</span>
        </div>
    </div>
    <div class="carousel-cell1 "><center>
    <div className="service-card2">
            <img
              src={illustration15}
              style={{
                marginTop: "10px",
              }}
              width="15%" alt = "loading..."
            ></img>
            <hr
              style={{
                width: "80%",
                fontStyle: "bolder",
                color: "black",
              }}
            ></hr>
          </div>
          <br></br>

          <div className="service-head2"><Link className='service-link' to="/college-applications">Complete Application Support</Link> </div>
          <div className="service-content service-content2">
            Get end-to-end assistance for your study abroad applications journey
            from industry experts offering services for multiple countries and
            programs actoss the globe
          </div>
        </center>
        <div
          style={{
            position: "relative",
            top: "-25px",
          }}
        >
          <span className="starting">Starting from:</span>
          <span className="INR8">INR</span>
          <span className="INR6 INR9">4999/-</span>
        </div>
    </div>
    <div class="carousel-cell1 "><center>
    <div className="service-card2">
            <img
              src={illustration17}
              style={{
                marginTop: "10px",
              }}
              width="19%" alt = "loading..."
            ></img>
            <hr
              style={{
                width: "80%",
                fontStyle: "bolder",
                color: "black",
              }}
            ></hr>
          </div>
          <br></br>

          <div className="service-head2"><Link to="/essays" className='service-link'>Essay Editing</Link> </div>
          <div className="service-content service-content2">
            Essays are the base of any application and need to be strong in
            showing your candidature for a particular course in any university.
            Draft top notch LORs, Personal Letters, Motivation letters with
            counselors on our platform.
          </div>
        </center>
        <div
          style={{
            position: "relative",
            top: "-15px",
          }}
        >
          <span className="starting">Starting from:</span>
          <span className="INR8">INR</span>
          <span className="INR6 INR9">4999/-</span>
        </div>
    </div>
    <div class="carousel-cell1 "><center>
    <div className="service-card2">
            <img
              src={illustration11}
              style={{
                marginTop: "10px",
              }}
              width="19%" alt = "loading..."
            ></img>
            <hr
              style={{
                width: "80%",
                fontStyle: "bolder",
                color: "black",
              }}
            ></hr>
          </div>
          <br></br>

          <div className="service-head2"><Link to="/visa-services" className='service-link'>Visa Support</Link></div>
          <div className="service-content service-content2">
            Getting a visa is a fairly tricky process. Ace the visa applications
            by teaming up with some the top industry experts available on
            kounselo.
          </div>
        </center>
        <div
          style={{
            position: "relative",
            top: "-15px",
          }}
        >
          <span className="starting">Starting from:</span>
          <span className="INR8">INR</span>
          <span className="INR6 INR9">4999/-</span>
        </div>
    </div>
      </Flickity>


    </div>
  )
}
export default ServiceSlider