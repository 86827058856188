
import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import ReactGA from "react-ga4"
import "./becomeAseller.css"
import Footer from "../footer/Footer";
import { Link } from "react-router-dom";
import Signup2 from "../auth/siginupBecome";
const illus13 = new URL("../../images/ic_sharp-support.webp", import.meta.url)
const illus14 = new URL("../../images/carbon.webp", import.meta.url)
const illus16 = new URL("../../images/money2.webp", import.meta.url)
const illustration9 = new URL("../../images/dotss.webp", import.meta.url)

function BECOMEASELLER() {
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
      const location = useLocation();
      useEffect(() => {
        ReactGA.send(window.location.pathname + window.location.search);
      }, []);
      const [show,setShow]=useState(false)
    return (

        <div>
         {show &&


            <Signup2/>
         }
        <div style={
            {
                alignItems:"center",
                justifyContent:"center"
            }
        }>


            <center>
                <h1 className="become-head" style={{

                }}> BECOME A COUNSELOR</h1>
                <h5 className="become-head2" style={{

                }}>  How can Kounselo help you in increasing business?</h5>
                <hr style={{
                    width: " 65%",
                    position: "relative",
                    top: "180px",
                }}></hr>
                <img src={illustration9} className="become-pic" style={{
                    float: "right",
                    marginRight: "80px",
                    width: "6%",
                    position: "relative",
                    top: "110px",
                }}></img>
                <div className="become-content">
                    <div className="become-content1">
                        <div className="become-content-1-1">
                            <img src={illus13} width="100%"></img>
                        </div>
                        <div className="become-content-1-2">
                            <div className="become-content-head">
                                Access to a large network of students
                            </div>
                            <div className="become-content-head2">
                                Get a hold of students directly interested in studying abroad<br></br> and generate good quality leads for your business
                            </div>
                        </div>

                    </div>
                    <div className="become-content2">
                        <div className="become-content-1-1">
                            <img src={illus14} width="100%"></img>
                        </div>
                        <div className="become-content-1-2">
                            <div className="become-content-head">
                                Efficient management of client base
                            </div>
                            <div className="become-content-head2">
                                Our platform allows you to interact with students and manage<br></br>them effectively all at one place and lets you keep track of them

                            </div>
                        </div>

                    </div>
                    <div className="become-content3">
                        <div className="become-content-1-1">
                            <img src={illus16} width="100%" ></img>
                        </div>
                        <div className="become-content-1-2">
                            <div className="become-content-head">
                                Additional revenue stream
                            </div>
                            <div className="become-content-head2">
                                If you’re someone who’s not a full time counselor with some <br></br>registered firm and a team but have the study abroad<br></br>expertise, you can start earning by listing your services
                            </div>
                        </div>

                    </div>
                    <div className="become-content4">
                        <div className="become-content-1-1">
                            <img src={illus13} width="100%" alt = "loading..."></img>
                        </div>
                        <div className="become-content-1-2">
                            <div className="become-content-head">
                                Ongoing support and resources
                            </div>
                            <div className="become-content-head2">
                                Our team provides you with all kinds of support be it with<br></br>tech or to resolve any client issues without any hassle.
                            </div>
                        </div>

                    </div>
                </div>
                <img src={illustration9} className="become-pic become-illus" alt = "loading..."></img>
          
            </center>
            <div className="button-div">
               <Link to="http://counselors.kounselo.com/signup" ><button  className="become-button">Become a Counselor</button></Link> 
                </div>

   
        </div>
        <Footer/>
       </div>
    )
}
export default BECOMEASELLER