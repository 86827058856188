import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Footer from "../footer/Footer";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import toast, { Toaster } from 'react-hot-toast';
import { removeCreateServiceData } from './../../Redux/actions/createServiceActionCreators';

function VisaAssistance() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [title, setTitle] = useState("");
  const [desc, setDesc] = useState("");
  const [pricing, setPricing] = useState("");
  const [delivery, setDelivery] = useState("");
  const [No_Session, set_Session] = useState("");
  const [Requirement, set_Requirement] = useState("");
  const [isInterview, setInterview] = useState(false);
  const [isDocumentation, setDocumentation] = useState(false);
  const [isProfile, setProfile] = useState(false);
  const [isAppointment, setAppointment] = useState(false);
  const [isAdvising, setAdvising] = useState(false);
  const [isFinancial, setFinancial] = useState(false);

  const baseUrl = process.env.REACT_APP_BASE_URL_DEV;
  const navigate = useNavigate();
  const data = useSelector((state) => state.create_service_data);
  const sellerRole = useSelector((state) => state.get_seller_profile_id.role);
  const userRole = useSelector((state) => state.get_user_id.role);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!sellerRole && !userRole) {
      navigate('/', {
        replace: true,
        state: {
          signIn: true,
          type: "counselor"
        }
      });
    } else if (userRole) {
      toast.error('You are not allowed to open this url');
      navigate('/userLanding');
    }
  }, [navigate, sellerRole, userRole])
  const createVisa = {
    title_Service: title,
    description: desc,
    pricing: pricing,
    productDelivery: delivery,
    numberOfSession: No_Session,
    additionalRequirement: Requirement,
  };

  const handleCancel = (e) => {
    e.preventDefault();
    toast('This may delete your progress');
    navigate('/counselor-dashboard');
  }

  const handleSavePublish = async (e) => {
    e.preventDefault();
    try {
      let combinedData = { ...data, ...createVisa, serviceStatus: 'Hosted' };
      const res = await axios.post(`${baseUrl}/api/v1/services/create-VisaAssistance`, combinedData);
      if (res.data.status === 'success') {
        toast.success('Service Created Succesfully');
        dispatch(removeCreateServiceData({}));
      }
    } catch (err) {
      // console.log(err);
      toast.error('Internal Server Error. Try again Later');

    }
    navigate('/counselor-dashboard');
  }

  const handleSave = async (e) => {
    e.preventDefault();
    try {
      const combinedData = { ...data, ...createVisa, serviceStatus: 'Draft' };
      const res = await axios.post(`${baseUrl}/api/v1/services/create-VisaAssistance`, combinedData);
      if (res.data.status === "success") {
        toast.success('Service Drafted Succesfully! You can host it anytime from your dashboard.');
        dispatch(removeCreateServiceData({}));
      }
    } catch (err) {
      // console.log(err);
      toast.error('Internal Server Error. Try again Later');

    }
    navigate('/counselor-dashboard');
  };

  return (
    <div>
      <div class="profile">
        <div className="head-forms">
          <label className="mainheading">Visa Assistance</label>
        </div>
        <div class="pageContainer">
          <div className="overView-flex">
            <div class="miniheading"> Create Your Servcie!</div>
            <div class="miniheading2 "> Step 2/2</div>
          </div>
          <hr class="line"></hr>
          <div className="form-flex">
            <div class="label2">
              <label >Title Of Service</label>
            </div>
            <div className="form-input">
              <input name="title" type="text" class="profile-info2 " minLength="5" maxLength="31" placeholder="Maximum 80 chars or 5 words" id="package" value={title} onChange={e => setTitle(e.target.value)} required /><br></br>
            </div>
          </div>

          <div className="form-flex">
            <div class="label2">
              <label >Description</label>
            </div>
            <div className="form-input">
              <textarea name="description" class="profile-info2 " rows="8" maxLength="500" placeholder="Add details about your service! (100 words)" value={desc} onChange={e => setDesc(e.target.value)} required />
            </div>
          </div>

          <div className="form-flex">
            <div class="label2">
              <label >Pricing</label>
            </div>
            <div className="form-input">
              <input style={{paddingLeft:"30px"}}  name="pricing" type="number" min={1} class="profile-info2 pricing" placeholder="₹" id="package" value={pricing} onChange={e => setPricing(e.target.value)} required /><br></br>
            </div>
          </div>
          <div className="form-flex">
            <div class="label2">
              <label >Duration</label>
            </div>
            <div className="form-input">
              <input name="delivery" type="number" min={1} class="profile-info2 " placeholder="No. of Days" value={delivery} onChange={e => setDelivery(e.target.value)} id="package" required /><br></br>
            </div>
          </div>


          <hr class="line2"></hr>
          <div className="form-flex">
            <div class="label2">
              <label >Number Of Session</label>
            </div>
            <div className="form-input">
              <input name="session" type="number" min={1} class="profile-info2 " placeholder="Number Of Session" id="package" value={No_Session} onChange={e => set_Session(e.target.value)} required /><br></br>
            </div>
          </div>
          <div className="form-flex2">
            <div class="label3">
              <label >Interview Preparation</label>
            </div>
            <div className="form-input">
              <input type="checkbox" class="check-box " placeholder="$" name="package" id="package" value={isInterview} onChange={() => setInterview(true)} /><br></br>
            </div>
          </div>

          <div className="form-flex2">
            <div class="label3">
              <label >Documentation</label>
            </div>
            <div className="form-input">
              <input type="checkbox" class="check-box " placeholder="$" name="package" id="package" value={isDocumentation} onChange={() => setDocumentation(true)} /><br></br>
            </div>
          </div>

          <div className="form-flex2">
            <div class="label3">
              <label >Profile Evaluation</label>
            </div>
            <div className="form-input">
              <input type="checkbox" class="check-box " placeholder="$" name="package" id="package" value={isProfile} onChange={() => setProfile(true)} /><br></br>
            </div>
          </div>
          <div className="form-flex2">
            <div class="label3">
              <label >Financial Profiling</label>
            </div>
            <div className="form-input">
              <input type="checkbox" class="check-box " placeholder="$" name="package" id="package" value={isFinancial} onChange={() => setFinancial(true)} /><br></br>
            </div>
          </div>

          <div className="form-flex2">
            <div class="label3">
              <label >Appointment Booking</label>
            </div>
            <div className="form-input">
              <input type="checkbox" class="check-box " placeholder="$" name="package" id="package" value={isAppointment} onChange={() => setAppointment(true)} /><br></br>
            </div>
          </div>

          <div className="form-flex2">
            <div class="label3">
              <label >Advising</label>
            </div>
            <div className="form-input">
              <input type="checkbox" class="check-box " placeholder="$" name="package" id="package" value={isAdvising} onChange={() => setAdvising(true)} /><br></br>
            </div>
          </div>


          <div className="form-flex">
            <div class="label2">
              <label >Additional Requirement for client</label>
            </div>
            <div className="form-input">
              <input name="requirement$" type="text" placeholder="Add open-ended question /query /requirement from the buyer" id="additional-field" class="profile-info2" value={Requirement} onChange={
                e => set_Requirement(e.target.value)} /><br></br>
            </div>

          </div>
          <div className="button-flex">
            <div className="button-cancel-flex" >

              <button Class="button-cancel" onClick={handleCancel}>CANCEL</button>
            </div>
            <div className="button-save-flex">
              <div>
                <button Class="button-save" onClick={handleSave}>SAVE</button>
              </div>
              <div>

                <button Class="button-savepublish" onClick={handleSavePublish} >SAVE & PUBLISH</button>

              </div>
            </div>
          </div>
          <div className="button-mobile-forms">
            <div className="button-cancel-flex" >1
              <button Class="button-save" onClick={handleSave}>SAVE</button>

            </div>
            <div className="button-save-flex">
              <div>

                <button Class="button-savepublish" onclick={handleSavePublish}>SAVE & PUBLISH</button>

              </div>
              <div>

                <button Class="button-cancel" onClick={handleCancel}>CANCEL</button>

              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
      <Toaster />
    </div>

  )
}
export default VisaAssistance;