import React, { useState, useEffect } from "react";
import "../../styles/userLanding.css"
import { useLocation, useNavigate } from "react-router-dom";
import { saveNavigationVariableAction, APICall } from "../../Redux/actions/serviceCardActionCreator"
import { useDispatch, useSelector } from "react-redux";
import profileImg from '../../images/nomans.webp';
const illustration5 = new URL("../../images/image 20.webp", import.meta.url);
const rating5 = new URL("../../images/1star.webp", import.meta.url)
const noprofile = new URL("../../images/noProfilepic.webp", import.meta.url)



function SellerServiceCards() {
  const [serviceUrl, setserviceUrl] = useState('');
  const [obj, setObj] = useState('');
  const location = useLocation();
  const navigate = useNavigate();
  const data = useSelector(state => state.fetched_service_data); 

  // const AllHostedServicedata = useSelector((state) => state.all_hosted_services)
  const dispatch = useDispatch()
  useEffect(() => {
    const currentUrl = location.pathname;
    const baseUrl = process.env.REACT_APP_BASE_URL_DEV;
    const SOP = `${baseUrl}/api/v1/services/Get-All-Sop-Cards`;
    const VisaAssistance = `${baseUrl}/api/v1/services/Get-All-VisaAssistanceCards`;
    const ScholorshipAssistance =`${baseUrl}/api/v1/services/Get-All-ScholarashipAssistanceCards`;
    const ResumeBuilding= `${baseUrl}/api/v1/services/Get-All-ResumeBuildingCards`;
    const CompleteAppSupport = `${baseUrl}/api/v1/services/Get-All-CASupportCards`;
    const profileEvaluation= `${baseUrl}/api/v1/services/Get-All-ProfileEvaluationCards`;
    const EssayEditing = `${baseUrl}/api/v1/services/Get-All-EssayEditingCards`;
    const UniversityShortlisting = `${baseUrl}/api/v1/services/Get-All-UnivShortCards`;
    const AllServices = `${baseUrl}/api/v1/services/Get-All-Hosted-Services`;
  
    if(currentUrl === '/service-profile-evaluation'){
      setObj("ProfileEvaluation"); 
      setserviceUrl("Get-ProfileEvaluation-Profile");
      APICall(profileEvaluation, "ProfileEvaluation", dispatch)
    }
    else if(currentUrl === '/service-visa'){
      setObj("Visa");
      setserviceUrl("Get-VisaAssistance-Profile");
      APICall(VisaAssistance, "Visa", dispatch)
    } 
    else if(currentUrl === '/service-scholorship'){
      setObj("Scholorship");
      setserviceUrl("Get-ScholarashipAssistance-Profile");
      APICall(ScholorshipAssistance, "Scholorship", dispatch )
    } 
    else if(currentUrl === '/service-resume') {
      setObj("Resume");
      setserviceUrl("Get-ResumeBuilding-Profile");
      APICall(ResumeBuilding, "Resume", dispatch )
    }  
    else if(currentUrl === '/service-comp-app-support'){
      setObj("compAppSupport");
      setserviceUrl("Get-CASupport-Profile");
      APICall(CompleteAppSupport, "compAppSupport", dispatch )
    } 
    else if(currentUrl === '/service-university-shortlisting'){
      setObj("University");
      setserviceUrl("Get-UnivShort-Profile");
      APICall(UniversityShortlisting, "University", dispatch)
    }
    else if(currentUrl === '/service-essay'){
      setObj("Essay");
      setserviceUrl("Get-EssayEditing-Profile");
      APICall(EssayEditing, "Essay", dispatch);
    } 
    else if(currentUrl === '/statement of purpose'){
      setObj("Sop");
      setserviceUrl("Get-Sop-Profile");
      APICall(SOP, "Sop", dispatch)
    }
    else if(currentUrl === '/all-counselor-services'){
      APICall(AllServices,"AllHostedServices", dispatch);
    } 
  }, [location])

  const BuyServiceClick = (e, serviceId) => {
    e.preventDefault();
      navigate(`/buy-service`, dispatch(saveNavigationVariableAction(serviceUrl, obj, serviceId)));
    }
      return (
   
    <div style={{
      display: "flex",
      flexWrap: "wrap"
    }}>
      {data.length !==0 ?
          data.map((item) => {
            console.log(item, "item")
            return (
                <div key = {item._id} class="service-card3" >
                  <div className="service-card2">
                    <img width="60px" className="service-illus" src={`${process.env.PUBLIC_URL}/sellerCardIllus/${item.serviceCategory}.webp`} alt="loading..."></img>
                  </div>
                  <span style={{
                    width: "40px",
                    
                
                    borderRadius: "50%",
                    
                    padding: "10px 20px 10px 20px", 
                    position: "absolute",
                    left: "1.5%",
                    top: "97px",
                  }}> 
                  <img width="40px" height="40px" style={{borderRadius:"50%"}} src={item.seller.length !==0  && item.seller[0].photo !== undefined ? `https://kounselodata.s3.ap-south-1.amazonaws.com/${item.seller[0].photo}` : noprofile} alt = "loading..."></img> </span>  <span className="cardName2">{(item.seller !== undefined && item.seller.length !== 0) ? item.seller[0].name : ''}</span><span className="card-country"
                   style={{
                    padding: "2px",
                    position: "relative",
                    left: "32%",
                    top: "9px",

                  }}>
                    <span style={{position:"relative",left:"20px"}}>Country</span><br></br>
                    <img src={`${process.env.PUBLIC_URL}/countryFlags/${item.country}.webp`} class="seller-card-flag" alt="loading..." width="10%"></img>
                  </span>
                  <br></br>
                  <div className="rating-digit">
                    4.2
                    <img style={{
                      position: "relative",
                      top: "-1px",
                      left: "2px"
                    }} width="3%" src={rating5} alt = "loading..."></img>
                  </div>
                  <hr style={{
                    position: "relative",
                    width: "90%",
                    top: "-10px",
                    margin: "10px auto",
                    color: "black",
                    fontWeight: "bolder"
                  }}></hr>
                  <div className="service-head" onClick = {(e) => BuyServiceClick(e, item._id)}>
                    {(item.title_Service.length > 30) ? item.title_Service.slice(0, 30) : item.title_Service }
                  </div>
                  <div className="service-content">
                    {(item.description.length > 125) ? item.description.slice(0, 125)  + '...': item.description }
                  </div>
                  <div>

                    <br></br>
                    <div >
                      <span className="INR2 INR5">Price:</span>
                      <span className="INR2 INR3">INR</span><span className="INR2 INR4">{item.pricing}/-</span>
                    </div>
                  </div>

                </div>
            
            )
          }):null
      }
    </div>)
}
export default SellerServiceCards