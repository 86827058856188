import { useEffect, useState } from 'react';
import './header.css'
import Signup from '../auth/signup';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Signin from '../auth/login';
import { useSelector, useDispatch } from 'react-redux';
import 'react-phone-number-input/style.css'
import axios from 'axios';
import Otp from '../otp';
import toast, { Toaster } from 'react-hot-toast';
import { getUserIdFromAuth, removeUserIdFromAuth } from './../../Redux/actions/GetUserIdActionCreator';
import { removeNavigationVariableAction } from './../../Redux/actions/serviceCardActionCreator';
import { removeCreateServiceData } from './../../Redux/actions/createServiceActionCreators';
import { removeSellerIdFromBuyService, removeSellerIdFromSellerCard } from './../../Redux/actions/GetSellerID_UserInterfaceActionCreator'
import { removeSellerIdFromAuth } from './../../Redux/actions/GetSellerIdFromAuthActionCreators';

const illustration5 = new URL("../../images/dropdownHome.webp", import.meta.url);
const illustration6 = new URL("../../images/logo-final.webp", import.meta.url);
const chat = new URL("../../images/chat.webp", import.meta.url)
const logout = new URL("../../images/logout.webp", import.meta.url)

function Header() {
  const { state } = useLocation();

  const [show, setShow] = useState(false);
  const [show2, setShow2] = useState(false);
  const [show3, setShow3] = useState(false);
  const [show5, setShow5] = useState(false);
  const [show8, setShow8] = useState(true);
  const [show10, setShow10] = useState(false);
  const [user, setShowUser] = useState(false);
  const userRole = useSelector((state) => state.get_user_id.role);
  const userid = useSelector((state) => state.get_user_id);
  const sellerRole = useSelector((state) => state.get_seller_profile_id.role);
  const sellerid = useSelector((state) => state.get_seller_profile_id);
  const baseUrl = process.env.REACT_APP_BASE_URL_DEV;
  const dispatch = useDispatch();
  const navigation = useNavigate();

  useEffect(() => {
    if (!userRole || !sellerRole) {
      if (state !== null) {
        if (state.signIn === true && !sellerRole) {
          setShow10(true)
        }
      }
    }
  }, [sellerRole, userRole])

  const handleLogout = async (e) => {
    e.preventDefault();
    try {
      const res = await axios.get(`${baseUrl}/api/v1/users/logout`, {
        withCredentials: true
      });
      if (res.data.status === "success") {
        dispatch(getUserIdFromAuth({}));
        dispatch(removeNavigationVariableAction({}));
        dispatch(removeUserIdFromAuth({}));
        dispatch(removeCreateServiceData({}));
        dispatch(removeSellerIdFromBuyService({}));
        dispatch(removeSellerIdFromSellerCard({}));
        dispatch(removeSellerIdFromAuth({}));
        toast.success('You logged Out Successfully');
        navigation('/');
      }
    } catch (err) {
      // console.log(err);
      toast.error('There may be some internal server error');
    }
  }

  return (
    <div>
      {(!userRole && !sellerRole &&

        <div className='new-header pc-nav'>

          <div>
            <h2 className='header-head'> <Link className='brand-name' to="/"><img src={illustration6} style={
              {
                marginRight: "5PX",
                position: "relative",
                top: "-1.5px"
              }
            } width="15%" alt="loading..." /> <span style={{

              marginTop: "10px"
            }}> Kounselo</span></Link> </h2>
          </div>
          <div>
            <ul className='ul-head'>
              <li className='li-head' onClick={() => setShow(!show)}
                onMouseLeave={() => setShow(false)}
              >How It Works <img onMouseLeave={() => setShow(false)} width="10%" id='head-drop1' onClick={() => setShow(!show)} src={illustration5} alt="loading..."></img>

                {show &&
                  <ul className='ul-drop2'>
                    <li className='li-drop'><Link className='service-link2' to="/how-it-works-user">Student</Link></li>
                    <li className='li-drop'><Link className='service-link2' to="/how-it-works-counselor">Counselor</Link></li>
                  </ul>
                }
              </li>

              <li className='li-head' onClick={() => setShow3(!show)}
                onMouseLeave={() => setShow3(false)}
              >Become a Counselor <img onMouseLeave={() => setShow3(false)} width="7%" id='head-drop1' onClick={() => setShow3(!show3)} src={illustration5} alt="loading..."></img>

                {show3 &&
                  <ul className='ul-drop2'>
                    <li className='li-drop'><Link className='service-link2' to="/become-a-counselor">Sign Up</Link></li>
                    <li className='li-drop'><Link className='service-link2' to="https://counselors.kounselo.com/sign-in">Login </Link></li>
                  </ul>
                }
              </li>
              <li className='li-head'> <Link to="/education-counselors"> <button className='get-started'>Find a Counselor  <span style={{
                margin: "5px"
              }}>-{'>'}</span></button></Link></li>
              {/* <li className='li-head loginn' onClick={() => setShow10(!show10)}> Login</li> */}
            </ul>

          </div>


        </div>
      )}
      {userRole === "User" && (
        <div className='new-header pc-nav'>
          <div>
            <h2 className='header-head'> <Link className='brand-name' to="/userLanding"><img src={illustration6} style={
              {
                marginRight: "5PX"
              }
            } width="15%" alt="loading..." /> <span> Kounselo</span></Link> </h2>
          </div>
          <div>
            <ul className='ul-head'>
              <li className='li-head' onClick={() => setShow(!show)}
                onMouseLeave={() => setShow(false)}
              >How It Works <img onMouseLeave={() => setShow(false)} width="10%" id='head-drop1' onClick={() => setShow(!show)} src={illustration5} alt="loading..."></img>

                {show &&
                  <ul className='ul-drop2'>
                    <li className='li-drop'><Link className='service-link2' to="/how-it-works-user">Student</Link></li>
                    <li className='li-drop'><Link className='service-link2' to="/how-it-works-counselor">Counselor</Link></li>
                  </ul>
                }
              </li>
         
              <li className='li-head'><Link to="/become-a-seller" style={{
                textDecoration: "NONE",
                color: "black"
              }}><img src={chat} width="30px" alt="loading..."></img></Link></li>
              <li className='li-head'>  <button onClick={() => setShowUser(!user)} className='get-started2'><img width="35px" src={logout} alt="loading..."></img></button>

                {user &&
                  <ul onMouseLeave={() => setShowUser(false)} className='ul-drop-view'>
                    <li className='li-drop'> <Link className="linkDecor" to="/userDash"> My Dashboard</Link></li>
                    <li className='li-drop'><Link className="linkDecor" to="/myorders">MY Orders</Link></li>
                    <li className='li-drop' onClick={handleLogout} style={{ cursor: 'pointer' }}>Logout</li>
                  </ul>
                }
              </li>

            </ul>

          </div>


        </div>
      )}

      {sellerRole === "Seller" && (
        <div className='new-header pc-nav'>
          <div>
            <h2 className='header-head'> <Link className='brand-name' to="/counselor-dashboard"><img src={illustration6} style={
              {
                marginRight: "5PX"
              }
            } width="15%" alt="loading..." /> <span> Kounselo</span></Link> </h2>
          </div>
          <div>
            <ul className='ul-head'>
              <li className='li-head' onClick={() => setShow(!show)}
                onMouseLeave={() => setShow(false)}
              >How It Works <img onMouseLeave={() => setShow(false)} width="10%" id='head-drop1' onClick={() => setShow(!show)} src={illustration5} alt="loading..."></img>

                {show &&
                  <ul className='ul-drop2'>
                    <li className='li-drop'><Link className='service-link2' to="/how-it-works-user">Student</Link></li>
                    <li className='li-drop'><Link className='service-link2' to="/how-it-works-counselor">Counselor</Link></li>
                  </ul>
                }
              </li>

              <li className='li-head'><Link to="#" title='coming soon' style={{
                textDecoration: "NONE",
                color: "black"
              }}><img src={chat} width="30px" alt="loading..."></img></Link>
              </li>

              <li className='li-head'>  <button onClick={() => setShowUser(!user)} className='get-started2'><img width="35px" src={logout} alt="loading..."></img></button>

                {user &&
                  <ul onMouseLeave={() => setShowUser(false)} className='ul-drop-view'>
                    <li className='li-drop'> <Link className="linkDecor" to="/counselor-dashboard">My Dashboard</Link></li>
                    <li className='li-drop'><Link className="linkDecor" to="/counselor-dashboard">MY Orders</Link></li>
                    <li className='li-drop' onClick={handleLogout} style={{ cursor: 'pointer' }}>Logout</li>
                  </ul>
                }
              </li>

            </ul>

          </div>


        </div>
      )}


      {/*  mobile*/}

      {!userRole && !sellerRole && (
        <nav class="navbar phone-nav navbar-dark" style={{ zIndex: "9999" }} aria-label="First navbar example">
          <div class="container-fluid">
            <a class="navbar-brand" href="#"> <Link className='brand-name' to="/"><img src={illustration6} style={
              {
                marginRight: "5PX"
              }
            } width="15%" alt="loading..." /> <span> Kounselo</span></Link></a>
            <button onClick={() => setShow8(true)} class="navbar-toggler" style={{
              background: "#7D7EC7",
              height: "40px"
            }} type="button" data-bs-toggle="collapse" data-bs-target="#navbarsExample01" aria-controls="navbarsExample01" aria-expanded="false" aria-label="Toggle navigation">
              <span class="navbar-toggler-icon"></span>
            </button>
            {
              show8 &&
              <div class="collapse navbar-collapse" id="navbarsExample01">
                <ul class="navbar-nav me-auto mb-2">
                  <li class="nav-item dropdown">
                    <a style={{
                      textDecoration: "none"
                    }} class=" dropdown-toggle li-head2" href="#" data-bs-toggle="dropdown" aria-expanded="false">How It Works</a>
                    <ul class="dropdown-menu">
                      <li onClick={() => setShow8(false)} className='li-drop'><Link className='service-link2' to="/how-it-works-user">Student</Link></li>
                      <li onClick={() => setShow8(false)} className='li-drop'><Link className='service-link2' to="/how-it-works-counselor">Counselor</Link></li>

                    </ul>
                  </li>
           
                  <li class="nav-item dropdown">
                    <a style={{
                      textDecoration: "none"
                    }} class=" dropdown-toggle li-head2" href="#" data-bs-toggle="dropdown" aria-expanded="false">Become a Counselor</a>
                    <ul class="dropdown-menu">
                      <li onClick={() => setShow8(false)} className='li-drop'><Link className='service-link2' to="/become-a-counselor">Sign Up</Link></li>
                      <li onClick={() => setShow8(false)} className='li-drop'><Link className='service-link2' to="https://counselors.kounselo.com/sign-in">Login</Link></li>

                    </ul>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link " aria-current="page" href="#">   <li class="nav-item">
                      <a class="nav-link " aria-current="page" href="#">

                        <Link to="education-counselors" > <button className='get-started'>Find a Counselor  <span style={{
                          margin: "5px"
                        }}>-{'>'}</span></button></Link></a>
                    </li></a>

                  </li>

                </ul>

              </div>

            }
          </div>
        </nav>
      )}

      {userRole === "User" && (
        <nav class="navbar phone-nav navbar-dark " aria-label="First navbar example">
          <div class="container-fluid">
            <a class="navbar-brand" href="#"> <Link className='brand-name' to="/userLanding"><img src={illustration6} style={
              {
                marginRight: "5PX"
              }
            } width="15%" /> <span> Kounselo</span></Link></a>
            <button class="navbar-toggler" style={{
              background: "linear-gradient(90.53deg, rgba(125, 126, 199, 0.29) 5.43%, rgba(102, 108, 255, 0.28) 94.47%)"
            }} type="button" data-bs-toggle="collapse" data-bs-target="#navbarsExample01" aria-controls="navbarsExample01" aria-expanded="false" aria-label="Toggle navigation">
              <span class="navbar-toggler-icon"></span>
            </button>

            <div class="collapse navbar-collapse" id="navbarsExample01">
              <ul class="navbar-nav me-auto mb-2">
                <li class="nav-item dropdown">
                  <a style={{
                    textDecoration: "none"
                  }} class=" dropdown-toggle li-head2" href="#" data-bs-toggle="dropdown" area-expanded="false">How It Works</a>
                  <ul class="dropdown-menu">
                    <li className='li-drop'><Link className='service-link2' to="/how-it-works-user">Student</Link></li>
                    <li className='li-drop'><Link className='service-link2' to="/how-it-works-counselor">Counselor</Link></li>

                  </ul>
                </li>
                <li class="nav-item dropdown">
                  <a style={{
                    textDecoration: "none"
                  }} class=" dropdown-toggle li-head2" href="#" data-bs-toggle="dropdown" aria-expanded="false">Explore</a>
                  <ul class="dropdown-menu">
                    <li className='li-drop'><Link className='service-link2' to="/all-service">Services</Link></li>
                    <li className='li-drop'><Link className='service-link2' to="/education-counselors">Top Conselor</Link></li>

                  </ul>
                </li>

                <li class="nav-item" style={{
                  marginTop: "20px"
                }}>
                  <Link to="/become-a-seller" style={{
                    textDecoration: "NONE",
                    color: "black",
                    fontWeight: "600",
                    fontStyle: "italic",

                  }}><img src={chat} width="30px" alt="loading..."></img> </Link>
                </li>
                <li class="nav-item">

                  <li className='li-head'>  <button onClick={() => setShowUser(!user)} className='get-started2'><img width="35px" src={logout} alt="loading..."></img></button>

                    {user &&
                      <ul onMouseLeave={() => setShowUser(false)} className='ul-drop-view'>
                        <li className='li-drop'> <Link className="linkDecor" to="/userDash"> My Dashboard</Link></li>
                        <li className='li-drop'><Link className="linkDecor" to="/myorders">MY Orders</Link></li>
                        <li className='li-drop' onClick={handleLogout} style={{ cursor: 'pointer' }}>Logout</li>
                      </ul>
                    }
                  </li>
                </li>

              </ul>

            </div>


          </div>

        </nav>
      )}
      {sellerRole === "Seller" && (
        <nav style={{ zIndex: "9999" }} class="navbar phone-nav navbar-dark 
" aria-label="First navbar example">
          <div class="container-fluid">
            <a class="navbar-brand" href="#"> <Link className='brand-name' to="/counselor-dashboard"><img src={illustration6} style={
              {
                marginRight: "5PX"
              }
            } width="15%" alt="loading..." /> <span> Kounselo</span></Link></a>
            <button class="navbar-toggler" style={{

              background: "linear-gradient(90.53deg, rgba(125, 126, 199, 0.29) 5.43%, rgba(102, 108, 255, 0.28) 94.47%)"
            }} type="button" data-bs-toggle="collapse" data-bs-target="#navbarsExample01" aria-controls="navbarsExample01" aria-expanded="false" aria-label="Toggle navigation">
              <span class="navbar-toggler-icon"></span>
            </button>

            <div class="collapse navbar-collapse" id="navbarsExample01">
              <ul class="navbar-nav me-auto mb-2">
                <li class="nav-item dropdown">
                  <a style={{
                    textDecoration: "none"
                  }} class=" dropdown-toggle li-head2" href="#" data-bs-toggle="dropdown" aria-expanded="false">How It Works</a>
                  <ul class="dropdown-menu">
                    <li className='li-drop'><Link className='service-link2' to="/how-it-works-user">Student</Link></li>
                    <li className='li-drop'><Link className='service-link2' to="/how-it-works-counselor">Counselor</Link></li>

                  </ul>
                </li>
                <li class="nav-item dropdown">
                  <a style={{
                    textDecoration: "none"
                  }} class=" dropdown-toggle li-head2" href="#" data-bs-toggle="dropdown" aria-expanded="false">Explore</a>
                  <ul class="dropdown-menu">
                    <li className='li-drop'><Link className='service-link2' to="/all-service">Services</Link></li>
                    <li className='li-drop'><Link className='service-link2' to="/education-counselors">Top Counselor</Link></li>

                  </ul>
                </li>

                <li class="nav-item">
                  <Link to="/become-a-seller" style={{
                    textDecoration: "NONE",
                    color: "black",
                    fontWeight: "600",
                    fontStyle: "italic"
                  }}><img src={chat} width="30px" alt="loading..."></img> </Link>
                </li>
                <li class="nav-item">

                  <li className='li-head'>  <button onClick={() => setShowUser(!user)} className='get-started2'><img width="35px" src={logout} alt="loading..."></img></button>

                    {user &&
                      <ul onMouseLeave={() => setShowUser(false)} className='ul-drop-view'>
                        <li className='li-drop'> <Link className="linkDecor" to="/counselor-dashboard"> My Dashboard</Link></li>
                        <li className='li-drop'><Link className="linkDecor" to="/myorders">MY Orders</Link></li>
                        <li className='li-drop' onClick={handleLogout} style={{ cursor: 'pointer' }}>Logout</li>
                      </ul>
                    }
                  </li>
                </li>

              </ul>

            </div>


          </div>

        </nav>
      )
      }
      {show5 &&
        <div>
          <Signup />

        </div>
      }
      {show10 &&
        <Signin type={state !== null ? state.type : ''} />
      }
      <Otp />
      <Toaster />
    </div>
  )
}
export default Header