import React, { useEffect } from "react";
import Footer from "../footer/Footer";
import ServiceComponent from "./servicesComponent";
import "../../styles/userLanding.css"
import SellerServiceCards from "./seller-service-card";
import ServiceComponent2 from "./service-component2";
const no_profile = new URL("./images/21SDD 1.webp", import.meta.url)
const illus = new URL("./images/Ellipse 15.webp", import.meta.url)


function ServiceUniversity() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <div>

            <ServiceComponent />
            <ServiceComponent2 />
            <div className="user-banner">

                <div className="banner1">
                    <span className="banner-heading"> Get a curated list  </span><br></br><span className="abroad">of the best universities</span><br></br>


                    <span className="banner-get"> for your profile</span> <br></br>
                    <span className="banner-top-c"></span> <span className="banner-today"></span>
                </div>
                <div className="banner2">

                    <img src={no_profile} className="user-illus" width="40%" alt="loading..."></img>

                </div>
            </div>
            <img src={illus} width="550px" className="teda-meda" alt="loading..."></img>

            <h5 className="service-pages-head" style={{



                color: "#7D7EC7"
            }}>Explore  University Shortlisting Services</h5>
            <hr className="service-line" style={{

            }}></hr>
            <img src={illus} width="550px" className="teda-meda" alt="loading..."></img>
            <div className="row row2">


                <SellerServiceCards />
            </div>
            <Footer />
        </div>
    )
}
export default ServiceUniversity
